import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from 'components/Button/Button';

import { Funnel, SortDown, SortUp } from 'react-bootstrap-icons';

import LoansList from './LoansList';
import { LoansProvider } from 'contexts/LoansContext';

const LoansItem = () => {
  const [sort, setSort] = useState({ sortKey: 'nextReimbursementDate', sortWay: 1 });
  const [page, setPage] = useState(1);

  const sortButton = [
    { name: 'Projet', criteria: 'projectName' },
    { name: 'Date échéance', criteria: 'nextReimbursementDate' },
    { name: 'Montant échéance', criteria: 'nextReimbursementAmout' },
  ];

  const sortTable = (criteria) => {
    const newSort = {
      sortKey: criteria,
      sortWay: sort.sortKey === criteria ? -sort.sortWay : 1,
    };
    setSort(newSort);
  };

  const onPaging = (page) => {
    setPage(page);
  };

  return (
    <>
      <Row className="text-uppercase green-text pb-2">
        <Col md={12}>Mes prêts</Col>
      </Row>
      <div className="bloc-kiwai mb-3">
        <Row className="text-center">
          {sortButton.map((item, index) => {
            return (
              <Col md="4" className="text-center pt-3" key={`button_${index}`}>
                <Button
                  className="button button-green w-100 w-md-auto m-0"
                  onClick={() => {
                    sortTable(item.criteria);
                  }}
                >
                  {item.name} &nbsp;
                  {item.criteria === sort.sortKey ? (
                    sort.sortWay > 0 ? (
                      <SortUp size={14} />
                    ) : (
                      <SortDown size={14} />
                    )
                  ) : (
                    <Funnel size={14} />
                  )}
                </Button>
              </Col>
            );
          })}
        </Row>
      </div>
      <LoansProvider sort={`${sort.sortWay === -1 ? '-' : ''}${sort.sortKey}`} page={page}>
        <LoansList page={page} onClickPager={onPaging} />
      </LoansProvider>
    </>
  );
};

export default LoansItem;
