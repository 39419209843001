import React, { useEffect, useCallback, createContext, useState, useMemo, useContext } from 'react';

const MenuContext = createContext(undefined);

const MenuProvider = ({ children }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const setCurrentMenuOpen = useCallback((open) => {
    setMenuOpen(open);
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) setCurrentMenuOpen(false);
    return () => (isMounted = false);
  }, [setCurrentMenuOpen]);

  const data = useMemo(() => [menuOpen, setCurrentMenuOpen], [menuOpen, setCurrentMenuOpen]);

  return <MenuContext.Provider value={data}>{children}</MenuContext.Provider>;
};

const useMenu = () => {
  const context = useContext(MenuContext);
  if (context === undefined) {
    throw new Error('useUser can only be used inside UserProvider');
  }
  return context;
};

export { MenuProvider, useMenu };
