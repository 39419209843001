import React, { useState } from 'react';
import CardDeck from 'react-bootstrap/CardDeck';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';

import Button from 'components/Button/Button';
import Loading from 'components/Misc/Loading';
import Pager from 'components/Misc/Pager';
import { fromCents } from 'services/utils';
import { useLoans } from 'contexts/LoansContext';
import { ProjectStateEnum, LoanTimeTableTypeEnum } from 'services/utils';
import Progression from 'components/Misc/Project/Progression';
import { LoanTimeTableProvider } from 'contexts/LoanTimeTableContext';
import LoanTimeTable from 'components/Misc/LoanTimeTable';
import CustomModal from 'components/Misc/Modal';
import { Loan } from 'services/api';
import { Toast } from 'services/utils';
import { format } from 'helpers';
import { WarrantyLoanTimeTableProvider } from 'contexts/WarrantyLoanTimeTableContext';
import WarrantyLoanTimeTable from 'components/Misc/WarrantyLoanTimeTable';

const LoansList = (props) => {
  const { loans, loansLoading, numberLoans } = useLoans();

  const [showLoanTimeTableModal, setShowLoanTimeTableModal] = useState(false);
  const [modalTimeTableLoan, setModalTimeTableLoan] = useState(null);
  const [projectLoanTimeTablePage, setProjectLoanTimeTablePage] = useState(1);
  const [downloadContractLoadingId, setDownloadContractLoadingId] = useState(0);

  const history = useHistory();

  const onPaging = (page) => {
    setProjectLoanTimeTablePage(page);
  };

  const GetLoanStateText = function (projectStateID) {
    switch (projectStateID) {
      case ProjectStateEnum.LOANSTART:
      case ProjectStateEnum.UNPAID:
      case ProjectStateEnum.LOANINDEFAULT:
      case ProjectStateEnum.CONTRACTSIGNED:
        return 'Prêt en cours';
      case ProjectStateEnum.MANDATSIGNED:
        return 'Prêt à venir';
      case ProjectStateEnum.WARRANTYAPPLIED:
        return 'Garantie appliquée';
      case ProjectStateEnum.FINISHED:
        return 'Prêt terminé';
      default:
        return '';
    }
  };

  const GetLoanContract = function (id) {
    Loan.GetContract(id)
      .then(() => {
        setDownloadContractLoadingId(0);
      })
      .catch(() => {
        setDownloadContractLoadingId(0);
        Toast.error('Une erreur est survenue. Veuillez réessayer ultérieurement.');
      });
  };

  return (
    <>
      {loans !== null ? (
        <>
          {numberLoans === 0 && (
            <Row className="pb-5">
              <Col className="text-center font-italic">Aucun prêt en cours</Col>
            </Row>
          )}
          <CardDeck className="contributions-cardeck">
            {loans.map((item, index) => {
              return (
                <Col
                  md={6}
                  lg={4}
                  className="pr-0 pl-0 d-flex mb-0 mb-md-3 mx-auto"
                  key={`card_${index}`}
                >
                  <Card>
                    <Card.Img
                      variant="top"
                      src={`data:${item.projectCoverImage.documentType};base64, ${item.projectCoverImage.documentData}`}
                      height="220px"
                    />
                    <Card.Body className="flex-fill">
                      <div className={`contribution-card-state success text-upperscase`}>
                        {GetLoanStateText(item.projectStateID)}
                      </div>
                      <Card.Title className="mb-0 pt-4">
                        <small>{item.companyName}</small>
                        <div className="project-card-title project-card-title-hover">
                          <Link
                            to={`/project/${item.projectID}-${
                              item.projectName
                                ? format.removeDiacriticsAndSpecials(item.projectName)
                                : ''
                            }`}
                            alt={`Projet ${item.projectName ? item.projectName : ''}`}
                          >
                            {item.projectName}
                          </Link>
                        </div>
                      </Card.Title>
                      <Card.Text as="div">
                        <Row className="text-center">
                          <Col>
                            <strong style={{ fontSize: '2rem' }}>
                              {fromCents(item.contributionAmount).toLocaleString('fr-FR')}€
                            </strong>
                            &nbsp; prêtés
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Progression
                              pending={
                                item.nextReimbursementRank === null
                                  ? item.loanDuration
                                  : item.nextReimbursementRank - 1
                              }
                              target={item.loanDuration}
                              forLoan
                            />
                          </Col>
                        </Row>
                      </Card.Text>
                    </Card.Body>

                    {item.projectStateID === ProjectStateEnum.MANDATSIGNED ? (
                      <>
                        <Card.Footer className="project-card-footer-information text-center">
                          <Row>
                            <Col xs="12">
                              <small>
                                Prêt en cours de finalisation, votre échéancier et votre contrat
                                seront bientôt disponibles
                              </small>
                            </Col>
                          </Row>
                        </Card.Footer>
                        <Button
                          className="button button-green-secondary m-0 w-100 rounded-0"
                          onClick={() =>
                            history.push(
                              `/project/${item.projectID}-${format.removeDiacriticsAndSpecials(
                                item.projectName,
                              )}`,
                            )
                          }
                        >
                          Voir le projet
                        </Button>
                      </>
                    ) : (
                      <>
                        <Card.Footer className="project-card-footer-information text-center">
                          <Row>
                            <Col xs="12">
                              {item.projectStateID === ProjectStateEnum.LOANSTART ||
                              item.projectStateID === ProjectStateEnum.UNPAID ||
                              item.projectStateID === ProjectStateEnum.LOANINDEFAULT ? (
                                <>
                                  <small>prochaine échéance</small>
                                  <br />
                                  <strong>{fromCents(item.nextReimbursementAmout)}&nbsp;€</strong>
                                  &nbsp;le&nbsp;
                                  <strong>
                                    {moment(item.nextReimbursementDate).format('DD/MM/YYYY')}
                                  </strong>
                                  <br />
                                </>
                              ) : item.projectStateID === ProjectStateEnum.WARRANTYAPPLIED ? (
                                <small>La garantie a été appliquée, vous avez été remboursé</small>
                              ) : (
                                <small>Prêt terminé</small>
                              )}
                            </Col>
                          </Row>
                        </Card.Footer>
                        <Row className="m-0 p-0">
                          <Col className="m-0 p-0">
                            <Button
                              className="button button-green-secondary m-0 w-100 h-100 rounded-0"
                              onClick={() => {
                                setDownloadContractLoadingId(item.id);
                                GetLoanContract(item.id);
                              }}
                              loading={downloadContractLoadingId === item.id}
                            >
                              Voir le contrat
                            </Button>
                          </Col>
                          <Col className="m-0 p-0">
                            <Button
                              className="button button-green-secondary m-0 w-100 h-100 rounded-0"
                              onClick={() => {
                                setModalTimeTableLoan(item);
                                setShowLoanTimeTableModal(true);
                              }}
                            >
                              Voir le tableau d'amortissement
                            </Button>
                          </Col>
                        </Row>
                      </>
                    )}
                  </Card>
                </Col>
              );
            })}
          </CardDeck>
        </>
      ) : (
        <>
          <Loading small />
        </>
      )}
      {modalTimeTableLoan && (
        <CustomModal
          show={showLoanTimeTableModal}
          onClose={() => {
            setProjectLoanTimeTablePage(1);
            setShowLoanTimeTableModal(!showLoanTimeTableModal);
            setModalTimeTableLoan(null);
          }}
          size="xl"
          title={`Tableau d'amortissement du projet ${modalTimeTableLoan.projectName}`}
          className="xxl-modal"
        >
          <Row className="pb-3">
            <Col xs={12}>
              Prêt d'un montant de{' '}
              <strong>
                {fromCents(modalTimeTableLoan.contributionAmount).toLocaleString('fr-FR')} €
              </strong>{' '}
              sur <strong>{modalTimeTableLoan.loanDuration} mois</strong> au taux de{' '}
              <strong>{modalTimeTableLoan.interestRate} %</strong>
            </Col>
          </Row>
          <Row>
            <Col>
              {modalTimeTableLoan.projectStateID === ProjectStateEnum.WARRANTYAPPLIED ? (
                <WarrantyLoanTimeTableProvider id={modalTimeTableLoan && modalTimeTableLoan.id}>
                  <WarrantyLoanTimeTable page={1} />
                </WarrantyLoanTimeTableProvider>
              ) : (
                <LoanTimeTableProvider
                  page={projectLoanTimeTablePage}
                  id={modalTimeTableLoan && modalTimeTableLoan.id}
                  type={LoanTimeTableTypeEnum.LENDER}
                >
                  <LoanTimeTable page={projectLoanTimeTablePage} onClickPager={onPaging} />
                </LoanTimeTableProvider>
              )}
            </Col>
          </Row>
        </CustomModal>
      )}
      {numberLoans > 3 && (
        <Pager
          page={props.page}
          pageNumber={Math.ceil(numberLoans / 3)}
          onClick={props.onClickPager}
          loading={loansLoading}
        />
      )}
    </>
  );
};

export default LoansList;
