import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import TransactionsItem from './TransactionsItem';

const Transactions = () => {
  return (
    <>
      <Row className="text-uppercase green-text pb-2 pt-2">
        <Col md={12}>Historique de mes transactions</Col>
      </Row>
      <TransactionsItem />
    </>
  );
};

export default Transactions;
