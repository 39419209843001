import React from 'react';
import Pagination from 'react-bootstrap/Pagination';
//import { Flower3 } from 'react-bootstrap-icons';

const Pager = (props) => {
  const [pageArray, setPageArray] = React.useState([]);

  React.useEffect(() => {
    var totPages = parseInt(props.pageNumber);
    var currentPage = parseInt(props.page);
    var pageArr = [];
    if (totPages > 1) {
      if (totPages <= 9) {
        var i = 1;
        while (i <= totPages) {
          pageArr.push(i);
          i++;
        }
      } else {
        if (currentPage <= 5) pageArr = [1, 2, 3, 4, 5, 6, 7, 8, '', totPages];
        else if (totPages - currentPage <= 2)
          pageArr = [
            1,
            '',
            totPages - 5,
            totPages - 4,
            totPages - 3,
            totPages - 2,
            totPages - 1,
            totPages,
          ];
        else
          pageArr = [
            1,
            '',
            currentPage - 2,
            currentPage - 1,
            currentPage,
            currentPage + 1,
            currentPage + 2,
            '',
            totPages,
          ];
      }
    }
    setPageArray(pageArr);
  }, [props.currentPage, props.page, props.pageNumber, props.totPages]);

  return (
    <React.Fragment>
      {props.children}
      <div style={{ marginTop: '15px' }}>
        <Pagination style={{ justifyContent: 'center' }}>
          {pageArray.map((ele, ind) => {
            const toReturn = [];

            if (ind === 0) {
              toReturn.push(
                <Pagination.First
                  key={'firstpage'}
                  onClick={() => {
                    if (props.page !== 1) props.onClick(1);
                  }}
                  disabled={props.page === 1}
                />,
              );

              toReturn.push(
                <Pagination.Prev
                  key={'prevpage'}
                  onClick={() => {
                    if (props.page !== 1) props.onClick(props.page - 1);
                  }}
                  disabled={props.page === 1}
                />,
              );
            }

            if (ele === '')
              toReturn.push(<Pagination.Ellipsis key={ind} style={{ color: 'red' }} />);
            else
              toReturn.push(
                <Pagination.Item
                  key={ind}
                  active={props.page === ele ? true : false}
                  onClick={() => {
                    if (props.page !== ele) props.onClick(ele);
                  }}
                >
                  {ele}
                </Pagination.Item>,
              );

            if (ind === pageArray.length - 1) {
              toReturn.push(
                <Pagination.Next
                  key={'nextpage'}
                  onClick={() => {
                    if (props.page !== ele) props.onClick(props.page + 1);
                  }}
                  disabled={props.page === ele}
                />,
              );

              toReturn.push(
                <Pagination.Last
                  key={'lastpage'}
                  onClick={() => {
                    if (props.page !== ele) props.onClick(ele);
                  }}
                  disabled={props.page === ele}
                />,
              );
            }

            return toReturn;
          })}
        </Pagination>
      </div>
    </React.Fragment>
  );
};

export default Pager;
