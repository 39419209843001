import React from 'react';
import Modal from 'react-bootstrap/Modal';

const CustomModal = (props) => {
  return (
    <Modal
      show={props.show}
      onHide={() => props.onClose()}
      size={props.size}
      centered
      className={props.className}
    >
      {props.title && (
        <Modal.Header>
          <button className="modal-close-button" onClick={() => props.onClose()}>
            <svg
              version="1.1"
              id="Capa_1"
              x="0px"
              y="0px"
              height="15px"
              viewBox="0 0 612 612"
              width="15px"
            >
              <g id="cross">
                <g>
                  <polygon points="612,36.004 576.521,0.603 306,270.608 35.478,0.603 0,36.004 270.522,306.011 0,575.997 35.478,611.397      306,341.411 576.521,611.397 612,575.997 341.459,306.011    " />
                </g>
              </g>
            </svg>
          </button>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>{props.children}</Modal.Body>
    </Modal>
  );
};

export default CustomModal;
