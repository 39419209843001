import axios from 'axios';
import { Lender, ProjectOwner } from 'services/api';
import { RoleEnum } from 'services/utils';

export function register(lender) {
  return axios.post('Lenders', lender).then((response) => {
    return response.data;
  });
}

export function updateLenders(lender) {
  return axios.put(`Lenders/${lender.userId}`, lender).then((response) => {
    return response.data;
  });
}

export async function ChangePassword(values) {
  return axios.post('/FrontAuth/ChangeForgotPassword', values).then((response) => {
    return response.data;
  });
}

export async function ResetPassword(values) {
  return axios.post('/FrontAuth/ForgotPassword', values).then((response) => {
    return response.data;
  });
}

export async function SelfChangePassword(values) {
  return axios.post('/FrontAuth/SelfChangePassword', values).then((response) => {
    return response.data;
  });
}

export async function MigratePassword(values) {
  return axios.post('/FrontAuth/MigratePassword', values).then((response) => {
    return response.data;
  });
}

export async function PutKYC(role, values) {
  // préteur
  if (role === RoleEnum.LENDER) {
    return Lender.KYC(values);
  } else {
    // emprunteur
    if (role === RoleEnum.PROJECTOWNER) {
      return ProjectOwner.KYC(values);
    } else {
      return null;
    }
  }
}

export async function PutAddress(role, values) {
  // préteur
  if (role === RoleEnum.LENDER) {
    return Lender.Address(values);
  } else {
    // emprunteur
    if (role === RoleEnum.PROJECTOWNER) {
      return ProjectOwner.Address(values);
    } else {
      return null;
    }
  }
}

export async function PutIdentity(role, values) {
  // préteur
  if (role === RoleEnum.LENDER) {
    return Lender.Identity(values);
  } else {
    // emprunteur
    if (role === RoleEnum.PROJECTOWNER) {
      return ProjectOwner.Identity(values);
    } else {
      return null;
    }
  }
}

export async function GetProfile(role) {
  // préteur
  if (role === RoleEnum.LENDER) {
    return Lender.Profile();
  } else {
    // emprunteur
    if (role === RoleEnum.PROJECTOWNER) {
      return ProjectOwner.Profile();
    } else {
      return null;
    }
  }
}
