import axios from 'axios';
import * as Constants from 'config/constants';

export async function Post(lender) {
  return await axios.post('Lenders', lender).then((response) => {
    return response.data;
  });
}

export async function BankAccount(values) {
  const response = await axios.put('Lenders/BankAccount', values);
  return response;
}

export async function GetBankAccount() {
  const response = await axios.get('Lenders/BankAccount');
  return response.data;
}

export async function GetBankingAlias() {
  const response = await axios.get('Lenders/BankingAlias');
  return response.data;
}

export async function KYC(values) {
  const response = await axios.put('Lenders/KYC', values);
  return response;
}

export async function Identity(values) {
  return axios.put('Lenders/Identity', values).then((response) => {
    return response;
  });
}

export async function Address(values) {
  return axios.put('Lenders/Address', values).then((response) => {
    return response;
  });
}

export async function ResetEmail(values) {
  return axios.put('Lenders/SendModificationMail', values).then((response) => {
    return response;
  });
}

export async function Profile() {
  return axios.get('Lenders/Profile').then((response) => {
    return response.data;
  });
}

export async function Dashboard() {
  return await axios.get('Lenders/Dashboard').then((response) => {
    return response.data;
  });
}

export async function SetOptIn(values) {
  return axios.put('Lenders/OptIn', values).then((response) => {
    return response;
  });
}

export async function Transactions(filter = '?Sorts=-createDate&Page=1&PageSize=1') {
  return axios.get(`Lenders/Transactions${filter}`).then((response) => {
    return response;
  });
}

export async function IFUDocuments(filter = '?Page=1&PageSize=1') {
  return axios.get(`Lenders/IFUDocuments${filter}`).then((response) => {
    return response;
  });
}

export async function Contributions(filter = '?Sorts=-contributionCreateDate&Page=1&PageSize=3') {
  return axios.get(`Lenders/Contributions${filter}`).then((response) => {
    return response.data;
  });
}

export async function Loans(filter = '?Sorts=-contributionCreateDate&Page=1&PageSize=3') {
  return axios.get(`Lenders/Loans${filter}`).then((response) => {
    return response.data;
  });
}

export async function GetLoanTimeTable(id, filters) {
  return axios.get(`Lenders/Loans/${id}/loantimetable${filters}`).then((response) => {
    return response;
  });
}
export async function GetWarrantyLoanTimeTable(id) {
  return axios.get(`Lenders/Loans/${id}/WarrantyLoanTimeTable`).then((response) => {
    return response;
  });
}

export async function getProfileIsValid() {
  return await axios.get('Lenders/IsProfileValid').then((response) => {
    return response.data;
  });
}

export async function getWalletBalance() {
  return await axios.get('Lenders/WalletBalance').then((response) => {
    return response.data;
  });
}

export async function GetOptIn() {
  return await axios.get('Lenders/OptIn').then((response) => {
    return response.data;
  });
}

export async function verifyEmail(value) {
  return await axios.post('Lenders/VerifyEmail', value).then((response) => {
    return response.data;
  });
}

export async function verifyReset(value) {
  return await axios.put('Lenders/Email', value).then((response) => {
    return response.data;
  });
}

export async function CardPayIn(value) {
  var isEnr = Constants.isENR();
  return await axios
    .post(`Lenders/CreateCreditCardPayIn?amount=${value}&isEnr=${isEnr}`)
    .then((response) => {
      return response.data;
    });
}

export async function CancelLoan(value) {
  return await axios.post(`Loan/${value}/Cancel`).then((response) => {
    return response.data;
  });
}

export async function WithdrawWallet(value) {
  var isEnr = Constants.isENR();
  return await axios
    .put(`Lenders/WithdrawWallet?amount=${value}&isEnr=${isEnr}`)
    .then((response) => {
      return response;
    });
}

export async function RibReader(rib) {
  const response = await axios.post('Lenders/GetIBANFromPDF', rib);
  return response;
}
