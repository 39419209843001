import Loading from 'components/Misc/Loading';
import React, { useEffect, useCallback, createContext, useState, useMemo, useContext } from 'react';

import { Auth, Lender, ProjectOwner } from 'services/api';

import { RoleEnum } from 'services/utils';

const UserContext = createContext(undefined);

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userLoading, setUserLoading] = useState(true);

  const setCurrentUser = useCallback((data) => {
    if (data) {
      switch (data.role) {
        case RoleEnum.LENDER:
          Lender.getProfileIsValid().then((response) => {
            data.validProfile = response;
            setUser(data);
          });
          break;
        case RoleEnum.PROJECTOWNER:
          ProjectOwner.getProfileIsValid().then((response) => {
            data.validProfile = response;
            setUser(data);
          });
          break;
        default:
          setUser(data);
          break;
      }
    } else {
      setUser(null);
    }
  }, []);

  useEffect(() => {
    let isMounted = true;
    Auth.RefreshToken()
      .then((response) => {
        if (isMounted) {
          if (response.status === 200) {
            var user = response.data;
            switch (user.role) {
              case RoleEnum.LENDER:
                Lender.getProfileIsValid().then((response) => {
                  user.validProfile = response;
                  setUser(user);
                  setUserLoading(false);
                });
                break;
              case RoleEnum.PROJECTOWNER:
                ProjectOwner.getProfileIsValid().then((response) => {
                  user.validProfile = response;
                  setUser(user);
                  setUserLoading(false);
                });
                break;
              default:
                setUser(user);
                setUserLoading(false);
                break;
            }
          }
        }
      })
      .catch(() => {
        setUser(null);
        setUserLoading(false);
      });
    return () => (isMounted = false);
  }, []);

  const data = useMemo(
    () => [user, userLoading, setCurrentUser],
    [setCurrentUser, user, userLoading],
  );
  return userLoading ? (
    <Loading />
  ) : (
    <UserContext.Provider value={data}>{children}</UserContext.Provider>
  );
};

const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser can only be used inside UserProvider');
  }
  return context;
};

export { UserProvider, useUser };
