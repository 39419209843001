import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';

import { Country, User } from 'services/api';
import { Toast } from 'services/utils';
import { format, error } from 'helpers';
import { useProfile } from 'contexts/ProfileContext';
import { useUser } from 'contexts/UserContext';

import Button from 'components/Button/Button';
import AlertBanner from 'components/Misc/AlertBanner';
import Label from 'components/Misc/Label';
import { CheckCircleFill } from 'react-bootstrap-icons';
import { verifyAddress } from 'services/api/geo';
import { Autocomplete } from 'components/Input/Autocomplete';

const frenchAddressRGX =
  /^([0-9][0-9]*(?:-[1-9][0-9]*)*)[\s,-]+(?:(bis|ter|qua)[\s,-]+)?([\w]+[\-\w]*)[\s,]+([-\w].+)$/;
const validationSchema = Yup.object().shape({
  countryId: Yup.string().required('Ce champ est requis'),
  city: Yup.string()
    .required('Ce champ est requis')
    .matches(/^[a-zA-ZÀ-Ÿ-.' ]*$/, 'Veuillez renseigner une valeur alphanumérique'),
  postalCode: Yup.string()
    .required('Ce champ est requis')
    .matches(/^[0-9a-zA-ZÀ-Ÿ-. ]*$/, 'Veuillez renseigner une valeur alphanumérique'),
  address: Yup.string()
    .required('Ce champ est requis')
    .matches(/^[0-9a-zA-ZÀ-Ÿ-.\s']*$/, 'Veuillez renseigner une valeur alphanumérique'),
  phoneNumber: Yup.string()
    .matches(/^((\+)33|0)[1-9](\d{2}){4}$/, 'Veuillez renseigner un numéro de téléphone valide')
    .required('Ce champ est requis'),
});

function addressAutocompleteMap(item, index, select) {
  var val = item?.properties?.label;
  if (item?.properties?.housenumber) {
    val =
      item.properties.housenumber + ' ' + item.properties.street + ' ' + item.properties.postcode;
  }

  return (
    <div className="autocomplete-item" key={index} onMouseDown={() => select(item)}>
      {val}
    </div>
  );
}

const Address = () => {
  const [countries, setCountries] = useState([]);
  const { profile = [], profileLoading, setCurrentProfile } = useProfile();
  const [user] = useUser();
  const [addressList, setAddressList] = useState([]);
  const [loading, setLoading] = useState(false);

  const country = countries
    .sort(function (a, b) {
      if (a.libcog < b.libcog) {
        return -1;
      }
      if (a.libcog > b.libcog) {
        return 1;
      }
      return 0;
    })
    .map((item, index) => {
      return (
        <option value={item.id} key={`country_${index}`}>
          {item.libcog}
        </option>
      );
    });

  const complete = profile.address && profile.postalCode && profile.city && profile.phoneNumber;

  const initialValues = {
    address: profile.address ? profile.address : '',
    postalCode: profile.postalCode ? profile.postalCode : '',
    city: profile.city ? profile.city : '',
    countryId: profile.country ? profile.country.id : '',
    phoneNumber: profile.phoneNumber ? profile.phoneNumber : '',
  };

  async function searchAddress(e) {
    setAddressList([]);
    setLoading(true);
    if (e.currentTarget.value !== '' && e.currentTarget.value) {
      const { features } = await verifyAddress(e.currentTarget.value);
      setAddressList(features);
    }
    setLoading(false);
  }
  const onSubmit = (values, { resetForm, setSubmitting }) => {
    setSubmitting(true);
    const parsedString = values.address.replaceAll(' ', '').toLowerCase();
    const splittedAddress = { streetNumber: '', repetitionIndex: '', street: '' };
    splittedAddress.address = '';
    Object.assign(splittedAddress, values);
    var addressParts = values.address.split(frenchAddressRGX);
    // splitted address result will have the following shape
    // ["", "streetNumber", "repetitionIndex", "streetName", "streetName", ""]
    // verifying the result
    if (parsedString === addressParts.join('').replaceAll(' ', '').toLowerCase()) {
      splittedAddress.streetNumber = addressParts[1] ?? '';
      splittedAddress.repetitionIndex = addressParts[2] ?? '';
      splittedAddress.street = `${addressParts[3] ?? ''} ${addressParts[4] ?? ''}`;
      splittedAddress.address = values.address;
    }
    User.PutAddress(user.role, { ...splittedAddress, countryId: parseInt(values.countryId) })
      .then((response) => {
        setCurrentProfile(response.data);
        Toast.success('Enregistrement effectué avec succés !');
      })
      .catch((data) => {
        switch (data.response.status) {
          case 429:
            break;
          case 400:
            Toast.error(
              "Une erreur est survenue lors de l'envoi du formulaire. Veuillez vérifier votre saisie.",
            );
            var apiErrors = {};
            Object.keys(data.response.data.errors).forEach(function (e) {
              apiErrors[format.camelize(e)] = data.response.data.errors[e];
            });
            resetForm({ values, status: { apiErrors } });
            break;
          default:
            Toast.error(
              "Une erreur est survenue lors de l'envoi du formulaire. Veuillez réessayer ultérieurement.",
            );
            break;
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  useEffect(() => {
    async function loadCountry() {
      const dataCountry = await Country.getResidenceCountry();
      setCountries(dataCountry);
    }
    loadCountry();
  }, []);

  return (
    !profileLoading && (
      <>
        <Row className="text-uppercase green-text pb-2 pt-2">
          <Col md={12}>Mes coordonnées</Col>
        </Row>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          initialStatus={{ apiErrors: {} }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(props) => {
            const { errors, touched, status, isSubmitting } = props;
            const countryIdError = error.getError('countryId', { touched, errors, status });
            const addressError = error.getError('address', { touched, errors, status });
            const postalCodeError = error.getError('postalCode', { touched, errors, status });
            const phoneNumberError = error.getError('phoneNumber', { touched, errors, status });
            const cityError = error.getError('city', { touched, errors, status });
            return (
              <Form className="bloc-kiwai">
                <AlertBanner
                  variant={complete ? 'success' : 'warning'}
                  icon={complete ? <CheckCircleFill size={18} /> : null}
                >
                  {complete
                    ? 'Vos coordonnées ont été enregistrées avec succès !'
                    : 'Informations manquantes'}
                </AlertBanner>
                <Row className="form-row">
                  <Col md="6">
                    <Label htmlFor="address" text="Adresse" required />
                    <Autocomplete
                      type="text"
                      name="address"
                      id="address"
                      className={addressError ? 'error' : ''}
                      list={addressList}
                      onChange={searchAddress}
                      loading={loading}
                      filterItem={() => true}
                      itemToValue={(item) => {
                        props.setFieldValue('postalCode', item.properties.postcode);
                        props.setFieldValue('city', item.properties.city);
                        return item.properties.housenumber
                          ? item.properties.housenumber + ' ' + item.properties.street
                          : item.properties.name;
                      }}
                      itemToElement={addressAutocompleteMap}
                    />
                    {addressError && <div className="error-message">{addressError}</div>}
                  </Col>
                  <Col md="2" className="pt-4 pt-md-0">
                    <Label htmlFor="postalCode" text="Code postal" required />
                    <Field
                      type="text"
                      name="postalCode"
                      id="postalCode"
                      className={postalCodeError ? 'error' : ''}
                    />
                    {postalCodeError && <div className="error-message">{postalCodeError}</div>}
                  </Col>
                  <Col md="4" className="pt-4 pt-md-0">
                    <Label htmlFor="city" text="Ville" required />
                    <Field type="text" name="city" id="city" className={cityError ? 'error' : ''} />
                    {cityError && <div className="error-message">{cityError}</div>}
                  </Col>
                </Row>
                <Row className="form-row">
                  <Col md="6">
                    <Label htmlFor="countryId" text="Pays de résidence" required />
                    <div className={countryIdError ? 'select-container error' : 'select-container'}>
                      <Field
                        as="select"
                        name="countryId"
                        id="countryId"
                        className={countryIdError ? 'error' : ''}
                      >
                        <option value=""></option>
                        {country.length > 0 && country}
                      </Field>
                    </div>
                    {countryIdError ? <div className="error-message">{countryIdError}</div> : null}
                  </Col>
                  <Col md="6" className="pt-4 pt-md-0">
                    <Label htmlFor="phoneNumber" text="Numéro de téléphone" required />
                    <Field
                      type="text"
                      name="phoneNumber"
                      id="phoneNumber"
                      className={phoneNumberError ? 'error' : ''}
                    />
                    {phoneNumberError && <div className="error-message">{phoneNumberError}</div>}
                  </Col>
                </Row>
                <Row className="form-row v-center form-footer">
                  <Col className="text-right">
                    <Button
                      className="button button-green m-0 w-100 w-md-auto"
                      type="submit"
                      loading={isSubmitting}
                    >
                      Enregistrer
                    </Button>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </>
    )
  );
};

export default Address;
