import React from 'react';
import { InfoCircle } from 'react-bootstrap-icons';
import Alert from 'react-bootstrap/Alert';
import { Link } from 'react-router-dom';

const CompanyBanner = (props) => {
  const { companyId, userBannerDisplayed } = props;
  return (
    <div className="company-banner-container">
      <Alert variant="warning" className={userBannerDisplayed ? 'company-banner' : 'user-banner'}>
        <p className="d-flex align-items-center">
          <InfoCircle size={24} />
          <span className="d-none d-md-block">
            &nbsp;&nbsp;Des éléments dans votre profil entreprise sont manquants&nbsp;:
          </span>
          <Link to={`/user/companies/${companyId}`}>
            <strong>&nbsp;Compléter mon profil entreprise</strong>
          </Link>
        </p>
      </Alert>
    </div>
  );
};
export default CompanyBanner;
