import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from 'components/Misc/PrivateRoute';
import { useStaticPageList } from 'contexts/StaticPageListContext';
import { useProfileCompanies } from 'contexts/CompaniesProfileContext';

// routes config
import routes from 'routes';

const notFound = React.lazy(() => import('views/PublicArea/Error/404'));
const staticPage = React.lazy(() => import('views/PublicArea/static'));

const Content = (props) => {
  const { staticPageList } = useStaticPageList();
  const { companies } = useProfileCompanies();

  return (
    <main id="content" className={`content ${props.banner && 'pt-5'}`}>
      <Suspense>
        <Switch>
          {routes.map((route, index) => {
            return (
              route.component &&
              (!route.private ? (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  component={route.component}
                />
              ) : (
                <PrivateRoute
                  component={route.component}
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                />
              ))
            );
          })}
          {companies &&
            companies.length > 0 &&
            companies.map((e) => {
              return (
                <PrivateRoute key={e} path={`/user/companies/${e}`} exact={true} name="company" />
              );
            })}
          {staticPageList &&
            staticPageList.length > 0 &&
            staticPageList.map((page) => {
              return (
                <Route
                  key={page.id}
                  path={`/${page.addressName}`}
                  exact={true}
                  name={page.footerName}
                  component={staticPage}
                />
              );
            })}
          {staticPageList && staticPageList.length > 0 && <Route component={notFound} />}
        </Switch>
      </Suspense>
    </main>
  );
};

export default React.memo(Content);
