import React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import ScrollTop from 'components/Misc/ScrollTop';

import { browser } from './helpers';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import ScrollButton from 'react-scroll-button';
import { useUser } from 'contexts/UserContext';
import { WalletProvider } from 'contexts/WalletContext';

// Containers
import Layout from './containers/Layout';
import * as Constant from 'config/constants';

var assetsDir = Constant.getAssetsDir();
import(`./scss/${assetsDir}/mainStyle.scss`).then(null);

// Pages
const ObsoleteBrowser = React.lazy(() => import('./views/PublicArea/Error/ObsoleteBrowser'));

function App() {
  const [user, userLoading] = useUser();
  return (
    <WalletProvider user={user}>
      {!userLoading && (
        <BrowserRouter>
          <React.Suspense
            fallback={
              <Row className="loading-container">
                <Col className="text-center">
                  <img
                    src={`../../../icons/${assetsDir}/loader.svg`}
                    className="loading-logo"
                    alt=""
                  />
                </Col>
              </Row>
            }
          >
            <ScrollTop />
            <ScrollButton
              targetId={'root'}
              behavior={'smooth'}
              buttonBackgroundColor={Constant.PrimaryColor}
              iconType={'chevron-up'}
              style={{ fontSize: '24px' }}
            />
            <Switch>
              {browser.isObsolete() ? (
                <Route exact path="*" name="Home" component={ObsoleteBrowser} />
              ) : (
                <Route path="/" name="Home" component={Layout} />
              )}
            </Switch>
          </React.Suspense>
        </BrowserRouter>
      )}
    </WalletProvider>
  );
}

export default App;
