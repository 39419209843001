import React, { useEffect, useState, useMemo, createContext, useCallback } from 'react';

import useGenericContext from './useGenericContext';
import { User } from 'services/api';

import Loading from '../components/Misc/Loading';

const ProfileContext = createContext();

const ProfileProvider = ({ children, role }) => {
  const [profile, setProfile] = useState();
  const [profileLoading, setProfileLoading] = useState(true);

  const setCurrentProfile = useCallback((value) => {
    setProfile(value);
  }, []);

  useEffect(() => {
    let isMounted = true;
    async function LoadProfile() {
      setProfileLoading(true);
      await User.GetProfile(role).then((value) => {
        if (isMounted) setCurrentProfile(value);
      });
      setProfileLoading(false);
    }
    LoadProfile();
    return () => (isMounted = false);
  }, [role, setCurrentProfile]);

  const values = useMemo(
    () => ({
      profile,
      profileLoading,
      setCurrentProfile,
    }),
    [profile, profileLoading, setCurrentProfile],
  );

  return profileLoading ? (
    <Loading />
  ) : (
    <ProfileContext.Provider value={values}>{children}</ProfileContext.Provider>
  );
};

function useProfile() {
  return useGenericContext(
    ProfileContext,
    'useProfile cannot be used outside of a ProfileProvider component.',
  );
}

export { ProfileProvider, useProfile };
