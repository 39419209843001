import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';

import HeaderLeft from './HeaderLeft';
import HeaderRight from './HeaderRight';

import * as Constant from 'config/constants';

var logoDir = Constant.getAssetsDir();

const Header = () => {
  return (
    <>
      <Navbar collapseOnSelect expand="lg" fixed="top" className="kiwai-navbar">
        <Navbar.Brand as={Link} to="/">
          <img src={`../../../icons/${logoDir}/logo.png`} alt="Kiwai logo" />
        </Navbar.Brand>
        <HeaderLeft />
        <HeaderRight />
      </Navbar>
    </>
  );
};

export default React.memo(Header);
