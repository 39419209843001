import React, { useEffect, useMemo, createContext, useState, useContext } from 'react';
import { PlatformInformation } from 'services/api';

const PlatformMessageContext = createContext(undefined);

const PlatformMessageProvider = ({ children }) => {
  const [platformMessage, setPlatformMessage] = useState(null);

  useEffect(() => {
    let isMounted = true;
    PlatformInformation.GetPlatformMessage()
      .then((data) => {
        if (isMounted) setPlatformMessage(data);
      })
      .catch(() => {
        if (isMounted) setPlatformMessage(null);
      });
    return () => (isMounted = false);
  }, []);

  const data = useMemo(
    () => ({
      platformMessage,
      setPlatformMessage,
    }),
    [platformMessage, setPlatformMessage],
  );
  return <PlatformMessageContext.Provider value={data}>{children}</PlatformMessageContext.Provider>;
};

const usePlatformMessage = () => {
  const context = useContext(PlatformMessageContext);
  if (context === undefined) {
    throw new Error('usePlatformMessage can only be used inside PlatformMessageProvider');
  }
  return context;
};

export { PlatformMessageProvider, usePlatformMessage };
