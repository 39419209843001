import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { useProfile } from 'contexts/ProfileContext';
import { useUser } from 'contexts/UserContext';

import { RoleEnum } from 'services/utils';

const Summary = () => {
  const { profile = [], profileLoading } = useProfile();
  const [user] = useUser();

  const validate =
    profile.validProfil &&
    profile.kycState === 4 &&
    profile.fullfilProfil &&
    profile.emailConfirmed;

  return (
    !profileLoading && (
      <>
        <Row
          className={`form-alert-profile ${validate ? 'bloc-success' : 'bloc-warning'}`}
          style={{ margin: 0 }}
        >
          <Col className="pr-0 pl-0">
            <p>
              {user.role === RoleEnum.LENDER && (
                <>
                  Une fois votre compte validé, vous pourrez alimenter votre compte Kiwaï et
                  effectuer vos contributions. <br />
                </>
              )}
              Pour valider votre compte, merci de compléter vos informations de profil, de confirmer
              votre adresse email et d’envoyer vos justificatifs d'identité.
            </p>

            <p className="pt-4">
              Etat du compte : <strong>{validate ? ' Validé' : ' Non validé'}</strong>
            </p>
          </Col>
        </Row>
      </>
    )
  );
};

export default Summary;
