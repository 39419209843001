import React, { useState } from 'react';
import { Popper } from '@material-ui/core';
import { Field, useFormikContext } from 'formik';

/* ================================================= */
/*                       MISC                        */
/* ================================================= */

function defaultFilter(fieldValue, item) {
  return !fieldValue || item.toLowerCase().includes(fieldValue.toLowerCase());
}

function defaultItemToValue(item) {
  return item;
}

/* ================================================= */
/*                     COMPONENT                     */
/* ================================================= */

/**
 * Autocomplete input based on Formik {@link https://formik.org/docs/api/field Fields}, Material UI {@link https://v4.mui.com/components/popper/ Poppers} and {@link https://www.downshift-js.com/downshift Downshift} components
 * @component
 * @param {string[]} list The list of items in the autocomplete
 * @param {string} name The name of the Formik field
 * @param {string} id The id of the Formik field
 * @param {string} className The classes of the Formik field
 * @param {string} onChange The function to call when the field value changes
 * @param {(fieldValue:string, item:string) => boolean} [filterItem] The filter applied to the list items (by default, case-insensitive match between item and field value)
 * @param {function} [itemToValue] The function to process the selected item before setting the field value (by default, no transform)
 * @param {function} itemToElement The function to process the list items and return elements to show in the autocomplete popover
 * @param {boolean} [autocomplete] If the autocomplete should show
 *
 */
const Autocomplete = ({
  list,
  name,
  id,
  loading,
  className,
  onChange,
  filterItem = defaultFilter,
  itemToValue = defaultItemToValue,
  itemToElement,
  autocomplete = true,
}) => {
  const [autocompleteField, setFieldRef] = useState(null);

  const { setFieldValue, values } = useFormikContext();

  function select(item) {
    setFieldValue(name, itemToValue(item));
  }

  return (
    <>
      <Field
        type="text"
        name={name}
        id={id}
        className={className}
        onClick={(e) => setFieldRef(e.currentTarget)}
        onKeyUp={(e) => onChange(e)}
        autoComplete="never-autocomplete"
      />
      {autocomplete && !loading && (
        <Popper
          open={values[name] !== '' && document.activeElement === autocompleteField}
          anchorEl={autocompleteField}
        >
          <div className="autocomplete" style={{ width: autocompleteField?.offsetWidth }}>
            {list.filter((item) => filterItem(values[name], item)).length > 0 ? (
              list
                .filter((item) => filterItem(values[name], item))
                .map((item, index) => itemToElement(item, index, select))
            ) : (
              <div className="autocomplete-noresult">Pas de résultat</div>
            )}
          </div>
        </Popper>
      )}
    </>
  );
};

export { Autocomplete };
