import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';

import { useStaticPageList } from 'contexts/StaticPageListContext';
import { ContactUsProvider, useContactUs } from 'contexts/ContactUsContext';

import logoOrias from 'assets/icons/logoOrias.png';
import logoMangopay from 'assets/icons/logoMangopay.png';
import logoFpf from 'assets/icons/logoFpf.png';
import lblCroissanceVerte from 'assets/icons/lblCroissanceVerte.png';
import logoFrenchTechNormandy from 'assets/icons/normandy/french_tech_rouenN.png';
import logoFrenchTechFR from 'assets/icons/enr/french_tech_FR.png';

import { Facebook, Twitter, Linkedin, Youtube } from 'react-bootstrap-icons';

import { useMediaQuery } from 'react-responsive';

import * as Constant from 'config/constants';
var logoDir = Constant.getAssetsDirNegative();

// Footer category : 1 = Prêteur, 2 = Emprunteur, 3 = A propos
function FooterContent() {
  return (
    <ContactUsProvider>
      <Footer />
    </ContactUsProvider>
  );
}

const FooterLink = (props) => {
  return (
    <>
      {props.data ? (
        props.data
          .filter(function (page) {
            return page.category.id === parseInt(props.category);
          })
          .map((page) => {
            return (
              <Link className="greyTextHover" to={`/${page.addressName}`}>
                {page.footerName}
              </Link>
            );
          })
      ) : (
        <>...</>
      )}
    </>
  );
};

const Footer = () => {
  const { staticPageList } = useStaticPageList();
  const { contactUs } = useContactUs();
  const isMobile = useMediaQuery({ query: `(max-width: 560px)` });
  return (
    <Container className="kiwai-container">
      <div className="footer-content d-flex flex-column flex-md-row justify-content-around">
        <div className="d-flex flex-column text-center">
          <h3 className="footer-content-title">Prêter</h3>
          <Link className="greyTextHover" to="/become_lender">
            Devenir prêteur
          </Link>
          <FooterLink category="1" data={staticPageList} />
        </div>
        <div className="d-flex flex-column text-center">
          <h3 className="footer-content-title">Emprunter</h3>
          <Link className="greyTextHover" to="/become_borrower">
            Devenir emprunteur
          </Link>
          <FooterLink category="2" data={staticPageList} />
        </div>
        <div className="d-flex flex-column text-center">
          <h3 className="footer-content-title">A propos</h3>
          <Link className="greyTextHover" to="/how_to">
            A propos de Kiwaï
          </Link>
          <Link className="greyTextHover" to="/FAQ">
            FAQ
          </Link>
          <FooterLink category="3" data={staticPageList} />
          {/* <Link className="greyTextHover" to="/indicators_table">
            Indicateurs de performance
          </Link> */}
        </div>
        <div className="d-flex flex-column text-center">
          <h3 className="footer-content-title">Nous contacter</h3>
          {contactUs ? (
            <>
              <div className="d-flex flex-column justify-content-center">
                <span>{contactUs.address}</span>
                <span>{`${contactUs.postalCode} ${contactUs.city}`}</span>
                <span>{contactUs.country}</span>
              </div>
              <div className="py-1">
                <a
                  className="greyTextHover"
                  href={`mailto:${
                    Constant.isENR() ? 'contact@kiwai-enr.fr' : 'contact@kiwai-normandie.fr'
                  }`}
                  rel="noopener noreferrer"
                >
                  {Constant.isENR() ? 'contact@kiwai-enr.fr' : 'contact@kiwai-normandie.fr'}
                </a>
              </div>
              {contactUs.phone && <div className="address-item">{contactUs.phone}</div>}
              <div className="social-media-icon-container d-flex flex-row justify-content-center">
                <a
                  href={
                    Constant.isENR()
                      ? 'https://www.facebook.com/Kiwa%C3%AF-Enr-103350075338753'
                      : 'https://www.facebook.com/kiwainormandie/'
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Facebook size={32} />
                </a>
                <a
                  href={
                    Constant.isENR()
                      ? 'https://twitter.com/Kiwai_Enr'
                      : 'https://twitter.com/kiwai_normandie'
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Twitter size={32} />
                </a>
                <a
                  href={
                    Constant.isENR()
                      ? 'https://www.linkedin.com/company/74371769/'
                      : 'https://fr.linkedin.com/company/kiwa%C3%AF-normandie'
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Linkedin size={32} />
                </a>
                <a
                  href={
                    Constant.isENR()
                      ? 'https://www.youtube.com/channel/UCYgs04gsDi32HL790_RWmJA'
                      : 'https://www.youtube.com/channel/UCcsV4RZqlT_UIlvsm8ifTgQ'
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Youtube size={32} />
                </a>
              </div>
            </>
          ) : (
            <>...</>
          )}
        </div>
      </div>
      <Row className="footer-partner v-center">
        <Col xs="12" className="text-center">
          {!Constant.isENR() && (
            <a target="_blank" rel="noopener noreferrer" href={Constant.PlatformNegative}>
              <img
                className="logoFooter"
                src={`../../../icons/${logoDir}/logo.png`}
                alt="Kiwai logo"
                width="150"
              />
            </a>
          )}
          <a target="_blank" rel="noopener noreferrer" href="https://www.orias.fr/">
            <img className="logoFooter" src={logoOrias} alt="logo Orias" width="80" />
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.mangopay.com/">
            <img className="logoFooter" src={logoMangopay} alt="logo Mangopay" width="80" />
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://financeparticipative.org/">
            <img
              className="logoFooter"
              src={logoFpf}
              alt="logo Financement Participatif France"
              width="80"
            />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://financeparticipative.org/label-croissance-verte/"
          >
            <img
              className="logoFooter"
              src={lblCroissanceVerte}
              alt="Label croissance verte"
              width="80"
            />
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://lafrenchtech.com/fr/">
            <img
              className="logoFooter"
              src={!Constant.isENR() ? logoFrenchTechNormandy : logoFrenchTechFR}
              alt="Logo French Tech"
              width={!Constant.isENR() ? '80' : '50'}
            />
          </a>
        </Col>
      </Row>
      <Row className="footer-copyright-text">
        <Col xs="12" className="text-center">
          <p>
            La plateforme de financement participatif Kiwaï est inscrite à l'ORIAS sous le numéro
            19006205
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default FooterContent;
