import React, { useEffect, useState, useMemo, createContext, useContext, useCallback } from 'react';

import { Lender } from 'services/api';

const ContributionsContext = createContext();

const ContributionsProvider = ({ children, type, sort, page }) => {
  const [contributions, setContributions] = useState(null);
  const [contributionsLoading, setContributionsLoading] = useState(true);
  const [numberContributions, setNumberContributions] = useState(0);
  const [currentType, setCurrentType] = useState(null);
  const [currentSort, setCurrentSort] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);

  const refreshCurrentContributions = useCallback(() => {
    let filters = currentType !== '' ? `&Filters=projectStateID${currentType}` : '';

    Lender.Contributions(`?Sorts=${currentSort}&Page=${currentPage}&PageSize=3` + filters).then(
      (response) => {
        setContributions(response.contributions);
        setNumberContributions(response.contributionsCount);
      },
    );
  }, [currentPage, currentSort, currentType]);

  useEffect(() => {
    let isMounted = true;
    setContributionsLoading(true);

    setCurrentType(type);
    setCurrentSort(sort);
    setCurrentPage(page);

    let filters = type !== '' ? `&Filters=projectStateID${type}` : '';

    Lender.Contributions(`?Sorts=${sort}&Page=${page}&PageSize=3` + filters)
      .then((response) => {
        if (isMounted && response) {
          setContributions(response.contributions);
          setNumberContributions(response.contributionsCount);
          setContributionsLoading(false);
        }
      })
      .catch(() => {
        if (isMounted) setContributions(null);
        if (isMounted) setNumberContributions(null);
        setContributionsLoading(false);
      });

    return () => (isMounted = false);
  }, [type, sort, page]);

  const data = useMemo(
    () => ({
      contributions,
      contributionsLoading,
      numberContributions,
      refreshCurrentContributions,
    }),
    [contributions, contributionsLoading, numberContributions, refreshCurrentContributions],
  );

  return <ContributionsContext.Provider value={data}>{children}</ContributionsContext.Provider>;
};

const useContributions = () => {
  const context = useContext(ContributionsContext);
  if (context === undefined) {
    throw new Error('useContributions can only be used inside ContributionsProvider');
  }
  return context;
};

export { ContributionsProvider, useContributions };
