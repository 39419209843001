import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import CustomTable from 'components/Misc/CustomTable';
import Button from 'components/Button/Button';
import { useIFU, IFUsProvider } from 'contexts/IFUContext';

const Documents = () => {
  const [page, setPage] = useState(1);

  const onPaging = (page) => {
    setPage(page);
  };

  useEffect(() => {}, [page]);

  return (
    <IFUsProvider page={page}>
      <DocumentsTable page={page} onClickPager={onPaging} />
    </IFUsProvider>
  );
};

const DocumentsTable = (props) => {
  const { numberDocuments, documentsLoading, documents } = useIFU();

  const rows = [
    { label: 'Année', name: 'year' },
    { label: 'Type de documents', name: 'type' },
    { label: 'Voir le document', name: 'documentData' },
  ];

  const scopedSlots = {
    documentData: (data, className, key) => {
      return (
        <td className={className + '-td'} key={'data_' + key}>
          <a href={'data:application/pdf;base64,' + data} download={'IFU-pdf'}>
            <Button className="button button-green" type="button">
              Voir le document
            </Button>
          </a>
        </td>
      );
    },
    type: (data, className, key) => {
      return (
        <td className={className + '-td'} key={'data_' + key}>
          {data}
        </td>
      );
    },
  };

  return (
    <>
      <Row className="text-uppercase green-text pb-2 pt-2">
        <Col md={12}>Documents</Col>
      </Row>
      <CustomTable
        page={props.page}
        pageNumber={Math.ceil(numberDocuments / 10)}
        onClickPager={props.onClickPager}
        numberItem={numberDocuments}
        rows={rows}
        data={documents}
        loading={documentsLoading}
        className="documents"
        scopedSlots={scopedSlots}
      />
    </>
  );
};

export default Documents;
