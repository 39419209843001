import Loading from 'components/Misc/Loading';
import React, { useEffect, useState, useMemo, createContext, useContext } from 'react';

import { Lender, ProjectOwner } from 'services/api';

import { LoanTimeTableTypeEnum } from 'services/utils';

const LoanTimeTableContext = createContext();

const LoanTimeTableProvider = ({ children, page, id, type }) => {
  const [loanTimeTable, setLoanTimeTable] = useState(null);
  const [loanTimeTableLoading, setLoanTimeTableLoading] = useState(true);
  const [numberLoanTimeTable, setNumberLoanTimeTable] = useState(0);

  useEffect(() => {
    let isMounted = true;
    setLoanTimeTableLoading(true);

    if (type === LoanTimeTableTypeEnum.LENDER) {
      Lender.GetLoanTimeTable(id, `?Page=${page}&PageSize=10`)
        .then((response) => {
          if (isMounted && response) {
            setLoanTimeTable(response.data.loanTimeTable);
            setNumberLoanTimeTable(response.data.loanTimeTableCount);
            setLoanTimeTableLoading(false);
          }
        })
        .catch(() => {
          if (isMounted) setLoanTimeTable(null);
          if (isMounted) setNumberLoanTimeTable(null);
          setLoanTimeTableLoading(false);
        });
    }

    if (type === LoanTimeTableTypeEnum.PROJECTOWNER) {
      ProjectOwner.GetLoanTimeTable(id, `?Page=${page}&PageSize=10`)
        .then((response) => {
          if (isMounted && response) {
            setLoanTimeTable(response.data.loanTimeTable);
            setNumberLoanTimeTable(response.data.loanTimeTableCount);
            setLoanTimeTableLoading(false);
          }
        })
        .catch(() => {
          if (isMounted) setLoanTimeTable(null);
          if (isMounted) setNumberLoanTimeTable(null);
          setLoanTimeTableLoading(false);
        });
    }

    return () => (isMounted = false);
  }, [id, page, type]);

  const data = useMemo(
    () => ({
      loanTimeTable,
      loanTimeTableLoading,
      numberLoanTimeTable,
    }),
    [loanTimeTable, loanTimeTableLoading, numberLoanTimeTable],
  );

  return loanTimeTableLoading && page === 1 ? (
    <Loading small />
  ) : (
    <LoanTimeTableContext.Provider value={data}>{children}</LoanTimeTableContext.Provider>
  );
};

const useLoanTimeTable = () => {
  const context = useContext(LoanTimeTableContext);
  if (context === undefined) {
    throw new Error('useLoanTimeTable can only be used inside LoanTimeTableProvider');
  }
  return context;
};

export { LoanTimeTableProvider, useLoanTimeTable };
