import axios from 'axios';

export async function GetContactUs() {
  return await axios.get('PlatformInformation/Address').then((response) => {
    return response.data;
  });
}

export async function GetPlatformMessage() {
  return await axios.get('PlatformInformation/Message').then((response) => {
    return response.data;
  });
}

export async function GetPlatformMedia() {
  return await axios.get('PlatformInformation/Media').then((response) => {
    return response.data;
  });
}
