import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from 'components/Button/Button';
import CustomSwitch from 'components/Input/Switch';
import Loading from 'components/Misc/Loading';
import { Toast } from 'services/utils';

import { OptInProvider } from 'contexts/OptInContext';
import { useOptIn } from 'contexts/OptInContext';
import { Lender } from 'services/api';

const OptIn = () => {
  return (
    <OptInProvider>
      <OptInItem />
    </OptInProvider>
  );
};

const OptInItem = () => {
  const [loading, setLoading] = useState(false);
  const [newsLetter, setNewsletter] = useState(null);
  const [newsletterENR, setNewsletterENR] = useState(null);
  const [project, setProject] = useState(null);
  const [projectENR, setProjectENR] = useState(null);
  const { optIn, optInLoading } = useOptIn();

  useEffect(() => {
    if (!optInLoading) {
      setNewsletter(optIn.optInNewsletter);
      setProject(optIn.optInProject);
      setNewsletterENR(optIn.optInNewsletterENR);
      setProjectENR(optIn.optInProjectENR);
    }
  }, [
    optIn.optInNewsletter,
    optIn.optInNewsletterENR,
    optIn.optInProject,
    optIn.optInProjectENR,
    optInLoading,
  ]);

  const onSubmit = () => {
    setLoading(true);
    Lender.SetOptIn({
      optInNewsletter: newsLetter,
      optInProject: project,
      optInNewsletterENR: newsletterENR,
      optInProjectENR: projectENR,
    })
      .then(() => {
        Toast.success('Enregistrement effectué avec succés !');
        setLoading(false);
      })
      .catch(() => {
        Toast.error('Une erreur est survenue. Veuillez réessayer ultérieurement.');
        setLoading(false);
      });
  };

  return (
    <>
      <Row className="text-uppercase green-text pb-2 pt-2">
        <Col md={12}>Paramètres de notifications</Col>
      </Row>
      {!optInLoading ? (
        <div className="bloc-kiwai">
          <Row className="form-row">
            <Col className="form-check">
              <CustomSwitch
                className="switch"
                label="Je souhaite recevoir la Newsletter Kiwaï Normandie"
                onChange={() => setNewsletter(!newsLetter)}
                value={newsLetter}
              />
            </Col>
          </Row>
          <Row className="form-row">
            <Col className="form-check">
              <CustomSwitch
                className="switch"
                label="Je souhaite recevoir la Newsletter Kiwaï ENR"
                onChange={() => setNewsletterENR(!newsletterENR)}
                value={newsletterENR}
              />
            </Col>
          </Row>
          <Row className="form-row">
            <Col className="form-check" xs={12} lg={10}>
              <CustomSwitch
                className="switch"
                label="Je souhaite être informé de la mise en ligne des nouveaux projets normands"
                onChange={() => setProject(!project)}
                value={project}
              />
            </Col>
          </Row>
          <Row className="form-row v-center">
            <Col className="form-check" xs={12} lg={10}>
              <CustomSwitch
                className="switch"
                label="Je souhaite être informé de la mise en ligne des nouveaux projets ENR"
                onChange={() => setProjectENR(!projectENR)}
                value={projectENR}
              />
            </Col>
            <Col className="text-right pt-3 pt-lg-0" xs={12} lg={2}>
              <Button className="button button-green" onClick={onSubmit} loading={loading}>
                Enregistrer
              </Button>
            </Col>
          </Row>
        </div>
      ) : (
        <>
          <Loading />
        </>
      )}
    </>
  );
};

export default OptIn;
