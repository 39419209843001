import React from 'react';
import { InfoCircle } from 'react-bootstrap-icons';
import Alert from 'react-bootstrap/Alert';
import { Link } from 'react-router-dom';

const UserBanner = () => {
  return (
    <div className="user-banner-container">
      <Alert variant="warning" className="user-banner">
        <p className="d-flex align-items-center">
          <InfoCircle size={24} />
          <span className="d-none d-md-block">
            &nbsp;&nbsp;Des éléments de votre profil sont manquants&nbsp;:
          </span>
          <Link to="/user/profile">
            <strong>&nbsp;Compléter mon profil</strong>
          </Link>
        </p>
      </Alert>
    </div>
  );
};

export default UserBanner;
