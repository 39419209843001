import React, { useState } from 'react';

import CardDeck from 'react-bootstrap/CardDeck';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from 'components/Button/Button';
import Pager from 'components/Misc/Pager';

import Loading from 'components/Misc/Loading';
import { Toast, ProjectStateEnum, LoanTimeTableTypeEnum } from 'services/utils';
import { Link, useHistory } from 'react-router-dom';

import TimeRemaining from 'components/Misc/Project/TimeRemaining';
import Progression from 'components/Misc/Project/Progression';

import { useProjectsPO } from 'contexts/ProjectsPOContext';

import { ProjectOwner } from 'services/api';
import { Project } from 'services/api';

import CustomModal from 'components/Misc/Modal';
import LoanTimeTable from 'components/Misc/LoanTimeTable';

import moment from 'moment';
import { LoanTimeTableProvider } from 'contexts/LoanTimeTableContext';
import { useUser } from 'contexts/UserContext';
import { ContractStateEnum } from 'services/utils/ContractStateEnum';
import { Formik, Field, Form } from 'formik';
import Label from 'components/Misc/Label';
import * as Yup from 'yup';
import { error } from 'helpers';
import { format } from 'helpers';
import { fromCents } from 'services/utils';
import { usePlatforConfig } from 'contexts/PlatformConfigContext';

const validationSchema = Yup.object().shape({
  code: Yup.string().required('Ce champ est requis'),
  contractsReaded: Yup.bool().oneOf([true], 'Vous devez lire les contrats'),
});

const ProjectsPOList = (props) => {
  const { projects, projectsLoading, numberProjects } = useProjectsPO();
  const [mandateProjectId, setGetMandateProjectId] = useState(0);
  const [contractProjectId, setGetContractProjectId] = useState(0);
  const [showLoanTimeTableModal, setShowLoanTimeTableModal] = useState(false);
  const [showContractProjectModal, setShowContractProjectModal] = useState(false);
  const [showContractOTPProjectModal, setShowContractOTPProjectModal] = useState(false);
  const [modalTimeTableLoan, setModalTimeTableLoan] = useState(null);
  const [projectLoanTimeTablePage, setProjectLoanTimeTablePage] = useState(1);
  const [loadingContract, setLoadingContract] = React.useState(false);
  const [loadingLoanTimeTable, setLoadingLoanTimeTable] = React.useState(false);
  const mangoPayServicestatus = usePlatforConfig();
  const [user] = useUser();
  const history = useHistory();

  const initialValues = {
    code: '',
    contractsReaded: false,
  };

  const GetMandate = function (id) {
    setGetMandateProjectId(id);
    ProjectOwner.GetMandate(id)
      .then((response) => {
        window.location.replace(response.data);
      })
      .catch((response) => {
        if (!response.response.status === 429) {
          Toast.error('Une erreur est survenue');
          if (response.data) {
            Toast.error(response.data);
          }
        }
        setGetMandateProjectId(0);
      });
  };

  const GetMandateFile = function (id) {
    setLoadingContract(true);
    Project.GetMandateFile(id)
      .then((response) => {
        setLoadingContract(false);
      })
      .catch((response) => {
        setLoadingContract(false);
        Toast.error('Une erreur est survenue');
        if (response.data) {
          Toast.error(response.data);
        }
      });
  };

  const GetLoanTimeTable = function (id, campaignID) {
    setLoadingLoanTimeTable(true);
    Project.GetLoanTimeTableFile(id, campaignID)
      .then(() => {
        setLoadingLoanTimeTable(false);
      })
      .catch((response) => {
        setLoadingLoanTimeTable(false);
        Toast.error('Une erreur est survenue');
        if (response.data) {
          Toast.error(response.data);
        }
      });
  };

  const GetContract = function (id) {
    setGetContractProjectId(id);
    setShowContractProjectModal(true);
  };

  const GetContractFile = function (id) {
    setLoadingContract(true);
    Project.GetContractFile(id)
      .then((response) => {
        setLoadingContract(false);
      })
      .catch((response) => {
        setLoadingContract(false);
        Toast.error(
          'Une erreur est survenue lors du téléchargement. Veuillez réessayer ultérieurement.',
        );
        if (response.data) {
          Toast.error(response.data);
        }
      });
  };

  const GetFinalContractFile = function (id) {
    setLoadingContract(true);
    Project.GetContractFinalFile(id)
      .then((response) => {
        setLoadingContract(false);
      })
      .catch((response) => {
        setLoadingContract(false);
        Toast.error(
          'Une erreur est survenue lors du téléchargement. Veuillez réessayer ultérieurement.',
        );
        if (response.data) {
          Toast.error(response.data);
        }
      });
  };

  const enableSigningContract = function (id) {
    Project.GetContractDownloadStatus(id)
      .then((response) => {
        if (response.data) {
          GetContractOTP(id);
        } else {
          Toast.error('Veuillez télécharger le contrat.');
        }
      })
      .catch((response) => {
        if (response.data) {
          Toast.error(response.data);
        }
      });
  };

  const GetContractOTP = function (id) {
    Project.GetContractOTP(id)
      .then((response) => {
        setShowContractProjectModal(false);
        setShowContractOTPProjectModal(true);
      })
      .catch((response) => {
        Toast.error(response.response.data);
      });
  };

  const onPaging = (page) => {
    setProjectLoanTimeTablePage(page);
  };

  const showAsPrivateSale = (project) => {
    if (project.privateSale) {
      if (
        moment(project.privateSaleEndDate).format('YYYY-MM-DD') >=
          moment(new Date()).format('YYYY-MM-DD') &&
        project.collectedAmount < project.privateSaleAmount
      ) {
        return true;
      } else return false;
    } else return false;
  };

  const submit = (values) => {
    Project.SendContractOTP(contractProjectId, values.code, values.contractsReaded)
      .then((response) => {
        setShowContractOTPProjectModal(false);
        window.location.reload();
      })
      .catch((data) => {
        Toast.error(data.response.data);
      });
  };

  return (
    <>
      {projects !== null ? (
        <>
          {numberProjects === 0 && (
            <Row>
              <Col className="text-center font-italic">Aucun projet</Col>
            </Row>
          )}
          <CardDeck>
            {projects.map((item, index) => {
              return (
                <Col
                  md={6}
                  lg={4}
                  className="pr-0 pl-0 d-flex mb-0 mb-md-3 mx-auto"
                  key={`card_${index}`}
                >
                  <Card>
                    <div class="ribbon-holder">
                      {showAsPrivateSale(item) ? (
                        <div class="ribbon ribbon-holder">Vente privée</div>
                      ) : (
                        ''
                      )}
                      <Card.Img
                        variant="top"
                        src={`data:${item.coverImage.documentType};base64, ${item.coverImage.documentData}`}
                        height="220px"
                      />
                    </div>
                    <Card.Body className="flex-fill">
                      <TimeRemaining
                        projectStateID={item.projectStateID}
                        campaignEndDate={item.endDate}
                      />
                      <div
                        className={`contribution-card-state ${
                          item.projectStateID === ProjectStateEnum.FAILED ? 'failed' : 'success'
                        } text-upperscase`}
                      >
                        {item.state}
                      </div>
                      <Card.Title className="mb-0 pt-4">
                        <small>{item.companyName}</small>
                        <div className="project-card-title project-card-title-hover">
                          <Link
                            to={
                              showAsPrivateSale(item)
                                ? user
                                  ? `/privateSaleValidate/${item.id}-${
                                      item.name ? format.removeDiacriticsAndSpecials(item.name) : ''
                                    }`
                                  : '/projects'
                                : `/project/${item.id}-${
                                    item.name ? format.removeDiacriticsAndSpecials(item.name) : ''
                                  }`
                            }
                            alt={
                              showAsPrivateSale(item)
                                ? user
                                  ? `privateSaleValidate ${item.name ? item.name : ''}`
                                  : '/projects'
                                : `Projet ${item.name ? item.name : ''}`
                            }
                          >
                            {item.name}
                          </Link>
                        </div>
                      </Card.Title>
                      <Card.Text as="div">
                        {item.projectStateID === ProjectStateEnum.LOANSTART ? (
                          <Row className="text-center">
                            <Col>
                              <strong style={{ fontSize: '2rem' }}>
                                {fromCents(item.collectedAmount).toLocaleString('fr-FR')}€
                              </strong>
                              &nbsp; collectés
                            </Col>
                          </Row>
                        ) : (
                          <Row className="text-center">
                            <Col>
                              <strong style={{ fontSize: '2rem' }}>
                                {fromCents(item.collectedAmount).toLocaleString('fr-FR')}€
                              </strong>
                              &nbsp;/&nbsp;{fromCents(item.amount).toLocaleString('fr-FR')}€
                            </Col>
                          </Row>
                        )}
                        <Row>
                          <Col>
                            <Progression
                              pending={
                                item.projectStateID >= ProjectStateEnum.LOANSTART
                                  ? 0
                                  : item.collectedAmount
                              }
                              target={item.amount}
                              forLoan={item.projectStateID >= ProjectStateEnum.LOANSTART}
                            />
                          </Col>
                        </Row>
                      </Card.Text>
                    </Card.Body>
                    <Card.Footer className="project-card-footer-information text-center">
                      {item.projectStateID === ProjectStateEnum.LOANSTART ? (
                        <>
                          <Row>
                            <Col xs="12">
                              <strong>{fromCents(item.nextReimbursementAmout)}&nbsp;€</strong>
                              &nbsp;le&nbsp;
                              <strong>
                                {moment(item.nextReimbursementDate).format('DD/MM/YYYY')}
                              </strong>
                              <br />
                              <small>prochaine échéance</small>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <>
                          <Row>
                            <Col xs="6">
                              <strong>{`${item.interestRate} %`}</strong>
                              <br />
                              <small>taux du prêt</small>
                            </Col>
                            <Col xs="6">
                              <strong>{`${item.loanDuration} mois`}</strong>
                              <br />
                              <small>durée du prêt</small>
                            </Col>
                          </Row>
                        </>
                      )}
                    </Card.Footer>
                    <div
                      md={12}
                      className="pt-2 text-danger"
                      hidden={(mangoPayServicestatus ?? 0) === 0}
                    >
                      La signature du mandat de prélèvement est actuellement indisponible. Nous vous
                      invitons à réitérer l'opération ultérieurement.
                    </div>
                    {item.projectStateID === ProjectStateEnum.SUCCESS ? (
                      item.signatureState === ContractStateEnum.PROCEDURE_CREATED ? (
                        <Button
                          type="button"
                          className="button button-green m-0 w-100 rounded-0"
                          onClick={() => GetContract(item.id)}
                          loading={contractProjectId === item.id}
                        >
                          Signer le contrat
                        </Button>
                      ) : null
                    ) : null}
                    {item.projectStateID >= ProjectStateEnum.CONTRACTSIGNED ? (
                      item.signatureState === ContractStateEnum.SIGNED ? (
                        <Button
                          type="button"
                          className="button button-green m-0 w-100 rounded-0"
                          onClick={() => GetFinalContractFile(item.id)}
                          loading={contractProjectId === item.id}
                        >
                          Télécharger mes contrats
                        </Button>
                      ) : null
                    ) : null}
                    {item.projectStateID >= ProjectStateEnum.MANDATSIGNED ? (
                      <Button
                        className="button button-green-secondary m-0 w-100 rounded-0"
                        onClick={() => GetMandateFile(item.id)}
                        disabled={loadingContract}
                      >
                        {loadingContract ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          ''
                        )}{' '}
                        Voir mon mandat de prélèvement
                      </Button>
                    ) : null}
                    {item.projectStateID >= ProjectStateEnum.LOANSTART ? (
                      <Button
                        className="button button-green m-0 w-100 rounded-0"
                        onClick={() => GetLoanTimeTable(item.id, item.campaignID)}
                        disabled={loadingLoanTimeTable}
                      >
                        {loadingLoanTimeTable ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          ''
                        )}{' '}
                        Télécharger le tableau d'amortissement
                      </Button>
                    ) : null}
                    {item.projectStateID === ProjectStateEnum.CONTRACTSIGNED ? (
                      <Button
                        type="button"
                        className="button button-green m-0 w-100 rounded-0"
                        onClick={() => GetMandate(item.id)}
                        loading={mandateProjectId === item.id}
                        disabled={mangoPayServicestatus !== 0}
                      >
                        Signer le mandat
                      </Button>
                    ) : (
                      <>
                        {item.projectStateID === ProjectStateEnum.LOANSTART ? (
                          <>
                            <Button
                              className="button button-green-secondary m-0 w-100 rounded-0"
                              onClick={() => {
                                setProjectLoanTimeTablePage(1);
                                setModalTimeTableLoan(item);
                                setShowLoanTimeTableModal(true);
                              }}
                            >
                              Voir le tableau d'amortissement
                            </Button>
                          </>
                        ) : (
                          <Button
                            className="button button-green-secondary m-0 w-100 rounded-0"
                            onClick={() =>
                              history.push(
                                showAsPrivateSale(item)
                                  ? user
                                    ? `/privateSaleValidate/${item.id}-${
                                        item.name
                                          ? item.name
                                              .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>]/gi, '')
                                              .replace(/ /g, '-')
                                          : ''
                                      }`
                                    : '/projects'
                                  : `/project/${item.id}-${
                                      item.name
                                        ? item.name
                                            .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>]/gi, '')
                                            .replace(/ /g, '-')
                                        : ''
                                    }`,
                              )
                            }
                          >
                            Voir le projet
                          </Button>
                        )}
                      </>
                    )}
                  </Card>
                </Col>
              );
            })}
          </CardDeck>
        </>
      ) : (
        <>
          <Loading />
        </>
      )}
      {modalTimeTableLoan && (
        <CustomModal
          show={showLoanTimeTableModal}
          onClose={() => setShowLoanTimeTableModal(!showLoanTimeTableModal)}
          size="xl"
          title={`Tableau d'amortissement du projet ${modalTimeTableLoan.name}`}
          className="xxl-modal"
        >
          <Row className="pb-3">
            <Col xs={12}>
              Prêt d'un montant de{' '}
              <strong>{fromCents(modalTimeTableLoan.amount).toLocaleString('fr-FR')}€</strong> sur{' '}
              <strong>{modalTimeTableLoan.loanDuration} mois</strong> au taux de{' '}
              <strong>{modalTimeTableLoan.interestRate} %</strong>
            </Col>
          </Row>
          <Row>
            <Col>
              <LoanTimeTableProvider
                page={projectLoanTimeTablePage}
                id={modalTimeTableLoan.id}
                type={LoanTimeTableTypeEnum.PROJECTOWNER}
              >
                <LoanTimeTable page={projectLoanTimeTablePage} onClickPager={onPaging} po />
              </LoanTimeTableProvider>
            </Col>
          </Row>
        </CustomModal>
      )}
      <CustomModal
        show={showContractProjectModal}
        onClose={() => setShowContractProjectModal(!showContractProjectModal)}
        size="xl"
        title="Signature de vos contrats de prêts"
        className="xxl-modal"
      >
        <Row>
          <Col>
            <div className="number-description">
              Félicitations, la campagne de collecte de votre projet à atteint son objectif. <br />
              <br />
              <strong>
                Pour clore votre campagne, nous vous invitons à prendre connaissance de vos contrats
                de prêts et à procéder à leur signature.
              </strong>
            </div>
            <div />
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <Button
              className="button button-green"
              type="submit"
              onClick={() => GetContractFile(contractProjectId)}
            >
              Télécharger les contrats
            </Button>
          </Col>
          <Col className="text-center">
            <Button
              className="button button-green"
              type="submit"
              onClick={() => enableSigningContract(contractProjectId)}
            >
              Signer le contrat
            </Button>
          </Col>
        </Row>
      </CustomModal>
      <CustomModal
        show={showContractOTPProjectModal}
        onClose={() => setShowContractOTPProjectModal(!showContractOTPProjectModal)}
        title="Contrat du projet"
        size="xl"
        className="xxl-modal"
      >
        <Row>
          <Col>
            <h1 className="page-title pb-5">Signature électronique</h1>
            <Formik
              initialValues={initialValues}
              initialStatus={{ apiErrors: {} }}
              validationSchema={validationSchema}
              onSubmit={submit}
            >
              {(props) => {
                const { touched, errors, status } = props;
                // Errors
                const codeError = error.getError('code', { touched, errors, status });
                const contractsReadedError = error.getError('contractsReaded', {
                  touched,
                  errors,
                  status,
                });
                return (
                  <Form className="bloc-kiwai">
                    <Row>
                      <Col className="text-center">
                        <Row className="form-row">
                          <Col>
                            <Label htmlFor="code" text="Code d'authentification" required />
                            <Field
                              type="text"
                              name="code"
                              id="code"
                              className={codeError && 'error'}
                            />
                            {codeError && <div className="error-message">{codeError}</div>}
                          </Col>
                        </Row>
                        <Row className="form-row">
                          <Col className="form-check">
                            <label className="form-label">
                              <Field type="checkbox" name="contractsReaded" />
                              J'ai lu les contrats<abbr title="Champ obligatoire">*</abbr>
                            </label>
                            {contractsReadedError && (
                              <div className="fixed-error-message"> {contractsReadedError}</div>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col className="text-center">
                            <Button className="button button-green" type="submit">
                              Valider
                            </Button>
                          </Col>
                          <Col className="text-center">
                            <Button
                              className="button button-red"
                              type="button"
                              onClick={() => GetContractOTP(contractProjectId)}
                            >
                              Renvoyer le code
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form>
                );
              }}
            </Formik>
          </Col>
        </Row>
      </CustomModal>
      {numberProjects > 3 && (
        <Pager
          page={props.page}
          pageNumber={Math.ceil(numberProjects / 3)}
          onClick={props.onClickPager}
          loading={projectsLoading}
        />
      )}
    </>
  );
};

export default ProjectsPOList;
