import React from 'react';

const PrimaryCase = (props) => {
  return (
    <>
      <div
        className={`synthese-bloc ${props.primaryClassName ?? ''}`}
        style={{ backgroundColor: props.primaryColor, borderRadius: '5px 5px 0px 0px' }}
      >
        <small>{props.primaryTitle}</small>
        <div className="data">{props.primaryData}</div>
      </div>
      <div
        className={`synthese-bloc ${props.secondaryClassName ?? ''}`}
        style={{
          backgroundColor: props.secondaryColor,
          borderRadius: '0px 0px 5px 5px',
          textAlign: 'right',
        }}
      >
        {props.secondaryTitle && <small>{props.secondaryTitle}</small>}
        {props.secondaryData !== undefined && <div className="data">{props.secondaryData}</div>}
        {props.children ?? ''}
      </div>
    </>
  );
};

export default PrimaryCase;
