import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Identity, Address, BankDetails, ProofIdentity, Credentials, Summary } from './';

import { ProfileProvider } from 'contexts/ProfileContext';
import { useUser } from 'contexts/UserContext';
import { RoleEnum } from 'services/utils';

const Profile = () => {
  const [user] = useUser();

  return (
    <>
      <ProfileProvider role={user.role}>
        <Summary />
        <Credentials />
        <Identity />
        <Address />
        {user && user.role === RoleEnum.LENDER && <BankDetails />}
        <ProofIdentity />
      </ProfileProvider>
      <Row className="form-row v-center form-footer">
        <Col xs="12" className="text-center text-md-left pb-4 pb-md-0">
          <small>(*) champ obligatoire</small>
        </Col>
      </Row>
    </>
  );
};

export default Profile;
