import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from 'components/Button/Button';
import Select from 'components/Input/Select';
import ContributionsList from './ContributionsList';
import { fromCents } from 'services/utils';
import { Funnel, SortDown, SortUp } from 'react-bootstrap-icons';

import { ContributionsProvider } from 'contexts/ContributionsContext';

const ContributionsItem = () => {
  const [filter, setFilter] = useState('');
  const [sort, setSort] = useState({ sortKey: 'contributionCreateDate', sortWay: -1 });
  const [page, setPage] = useState(1);

  const projectState = [
    { name: 'Toutes les campagnes de collecte', value: '' },
    { name: 'Campagnes de collecte en cours', value: '==6' },
    { name: 'Campagnes de collecte terminées', value: '>=7' },
  ];

  const sortButton = [
    { name: 'Projet', criteria: 'projectName' },
    { name: 'Date', criteria: 'contributionCreateDate' },
    { name: 'Montant', criteria: 'contributionAmount' },
  ];

  const sortTable = (criteria) => {
    const newSort = {
      sortKey: criteria,
      sortWay: sort.sortKey === criteria ? -sort.sortWay : 1,
    };

    setSort(newSort);
  };

  const onPaging = (page) => {
    setPage(page);
  };

  return (
    <>
      <Row className="text-uppercase green-text pb-2 pt-2">
        <Col md={12}>Mes contributions</Col>
      </Row>
      <div className="bloc-kiwai mb-3">
        <Row className="form-row">
          <Col md="12">
            <Row className="v-center">
              <Col xs="12" lg="5" className="text-center">
                <Select
                  values={projectState}
                  value={filter}
                  name="projectState"
                  onChange={(e) => setFilter(e.target.value)}
                />
              </Col>
              <Col xs="12" lg="7" className="d-none d-md-block pt-md-3">
                <Row className="text-center">
                  {sortButton.map((item, index) => {
                    return (
                      <Col
                        xs="6"
                        md="4"
                        className="text-center pt-2 pt-md-0"
                        key={`button_${index}`}
                      >
                        <Button
                          className="button button-green"
                          onClick={() => {
                            sortTable(item.criteria);
                          }}
                        >
                          {item.name} &nbsp;
                          {item.criteria === sort.sortKey ? (
                            sort.sortWay > 0 ? (
                              <SortUp size={14} />
                            ) : (
                              <SortDown size={14} />
                            )
                          ) : (
                            <Funnel size={14} />
                          )}
                        </Button>
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <ContributionsProvider
        sort={`${sort.sortWay === -1 ? '-' : ''}${sort.sortKey}`}
        type={filter}
        page={page}
      >
        <ContributionsList page={page} onClickPager={onPaging} />
      </ContributionsProvider>
    </>
  );
};

export default ContributionsItem;
