import axios from 'axios';

let authenticationData;
window.addEventListener('storage', syncLogout);

export const AuthenticationStatus = Object.freeze({
  AUTHENTICATED: 'AUTHENTICATED',
  NOT_AUTHENTICATED: 'NOT_AUTHENTICATED',
});

export async function Login(email, password, tokenType, token) {
  const response = await axios.post('FrontAuth/connect', {
    email,
    password,
    tokenType,
    token,
  });
  if (response.status === 200) {
    authenticationData = response.data;
  }
  return response;
}

export async function RefreshToken() {
  const response = await axios.get('FrontAuth/refreshToken');
  if (response.status === 200) {
    authenticationData = response.data;
  }
  return response;
}

export async function Logout() {
  await axios.get('FrontAuth/logout');
  window.localStorage.setItem('kiwai_logout', Date.now().toString());
  window.location.reload();
}

function syncLogout(event) {
  if (event.key === 'kiwai_logout') {
    authenticationData = null;
    window.localStorage.removeItem('kiwai_logout');
    window.location.reload();
  }
}

export async function GetCurrentUser() {
  if (authenticationData) {
    const { firstname, lastname, mail, role } = authenticationData;
    return { firstname, lastname, mail, role };
  }
  return null;
}

export function GetAuthToken() {
  return authenticationData ? authenticationData.token : null;
}

export async function GetUserAuthenticationStatus() {
  if (GetAuthToken()) {
    return AuthenticationStatus.AUTHENTICATED;
  }
  try {
    const refreshTokenResponse = await RefreshToken();

    if (refreshTokenResponse.status === 200) {
      return AuthenticationStatus.AUTHENTICATED;
    }
  } catch {
    return AuthenticationStatus.NOT_AUTHENTICATED;
  }

  return AuthenticationStatus.NOT_AUTHENTICATED;
}
