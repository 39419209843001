import React from 'react';
import { InfoCircle } from 'react-bootstrap-icons';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

const Label = (props) => {
  const popover = (
    <Popover id="popover-basic">
      <Popover.Content>{props.tooltip}</Popover.Content>
    </Popover>
  );

  return (
    <>
      <label htmlFor={props.htmlFor} className={`form-label ${props.className ?? ''}`}>
        {props.text}
        {props.required ? <abbr title="Champ obligatoire">*</abbr> : ''}&nbsp;&nbsp;
        {props.tooltip !== undefined && props.tooltip !== null && (
          <OverlayTrigger key="top" placement="top" overlay={popover}>
            <InfoCircle size={14} />
          </OverlayTrigger>
        )}
      </label>
    </>
  );
};

export default Label;
