import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import CompaniesTable from './CompaniesTable';

import { CompaniesProvider } from 'contexts/CompaniesContext';

const Companies = () => {
  const [page, setPage] = useState(1);

  const onPaging = (page) => {
    setPage(page);
  };

  useEffect(() => {}, [page]);

  return (
    <>
      <Row className="text-uppercase green-text pb-2 pt-2">
        <Col md={12}>Mes entreprises</Col>
      </Row>
      <Row className="form-row">
        <Col>
          <CompaniesProvider page={page}>
            <CompaniesTable page={page} onClickPager={onPaging} />
          </CompaniesProvider>
        </Col>
      </Row>
    </>
  );
};

export default Companies;
