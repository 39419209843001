import React, { useEffect, createContext, useState, useMemo, useContext } from 'react';

import { Lender } from 'services/api';

import Loading from './../components/Misc/Loading';

const FundContext = createContext(undefined);

const FundProvider = ({ children }) => {
  const [fund, setFund] = useState({ bankAccount: null, bankingAlias: null });
  const [fundLoading, setFundLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;
    setFundLoading(true);
    let fund = { bankAccount: null, bankingAlias: null };
    Promise.all([Lender.GetBankAccount(), Lender.GetBankingAlias()])
      .then((response) => {
        if (isMounted) {
          fund.bankAccount = response[0];
          fund.bankingAlias = response[1];
          setFund(fund);
        }
        setFundLoading(false);
      })
      .catch(() => {
        if (isMounted) setFund({ bankAccount: null, bankingAlias: null });
        setFundLoading(false);
      });

    return () => (isMounted = false);
  }, []);

  const data = useMemo(() => [fund, fundLoading], [fund, fundLoading]);

  return fundLoading ? (
    <Loading />
  ) : (
    <FundContext.Provider value={data}>{children}</FundContext.Provider>
  );
};

const useFund = () => {
  const context = useContext(FundContext);
  if (context === undefined) {
    throw new Error('useFund can only be used inside FundProvider');
  }
  return context;
};

export { FundProvider, useFund };
