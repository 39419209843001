import React, { useState } from 'react';

import CardDeck from 'react-bootstrap/CardDeck';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from 'components/Button/Button';
import Pager from 'components/Misc/Pager';

import moment from 'moment';
import { fromCents } from 'services/utils';
import Loading from 'components/Misc/Loading';
import CustomModal from 'components/Misc/Modal';
import { Toast, ContributionStateEnum, ProjectStateEnum } from 'services/utils';

import { useContributions } from 'contexts/ContributionsContext';
import { useWallet } from 'contexts/WalletContext';

import { Lender } from 'services/api';
import TimeRemaining from 'components/Misc/Project/TimeRemaining';
import { Link } from 'react-router-dom';
import { format } from 'helpers';

const ContributionsList = (props) => {
  const { contributions, contributionsLoading, numberContributions, refreshCurrentContributions } =
    useContributions();
  const { wallet, refreshWallet } = useWallet();
  const [cancellingLoan, setCancellingLoan] = useState(null);
  const [cancellingLoanLoading, setCancellingLoanLoading] = useState(false);
  const [confirmModalShow, setConfirmModalShow] = useState(false);

  const ConfirmCancelLoan = function (loan) {
    setCancellingLoan(loan);
    setConfirmModalShow(true);
  };

  const CancelLoan = function () {
    setCancellingLoanLoading(true);
    Lender.CancelLoan(cancellingLoan.id)
      .then(() => {
        setCancellingLoan(null);
        refreshCurrentContributions();
        refreshWallet(wallet);
        setConfirmModalShow(false);
        Toast.success(
          'Contribution annulée avec succès. Le montant a été remboursé sur votre compte Kiwaï',
        );
        setCancellingLoanLoading(false);
      })
      .catch((response) => {
        if (response.response.status !== 429) {
          Toast.error(response.data);
        }
        setCancellingLoanLoading(false);
        setConfirmModalShow(false);
      });
  };

  const GetExtraWording = function (projectState, contributionStateID) {
    if (
      contributionStateID === ContributionStateEnum.CANCELED ||
      contributionStateID === ContributionStateEnum.REFUSED ||
      contributionStateID === ContributionStateEnum.REFUND
    ) {
      switch (contributionStateID) {
        case ContributionStateEnum.CANCELED:
        case ContributionStateEnum.REFUSED:
          return 'Contribution annulée';
        case ContributionStateEnum.REFUND:
          return 'Contribution remboursée';
        default:
          return '';
      }
    } else {
      switch (projectState) {
        case ProjectStateEnum.PUBLISHED:
          return 'Campagne de collecte en cours';
        case ProjectStateEnum.MANDATSIGNED:
        case ProjectStateEnum.CONTRACTSIGNED:
        case ProjectStateEnum.SUCCESS:
          return 'Projet en cours de finalisation';
        case ProjectStateEnum.CLOSED:
          return 'Projet en cours de finalisation';
        default:
          return '';
      }
    }
  };

  return (
    <>
      {contributions !== null ? (
        <>
          {numberContributions === 0 && (
            <Row>
              <Col className="text-center font-italic">Aucune contribution en cours</Col>
            </Row>
          )}
          <CardDeck className="contributions-cardeck">
            {contributions.map((item, index) => {
              return (
                <Col
                  md={6}
                  lg={4}
                  className="pr-0 pl-0 d-flex mb-0 mb-md-3 mx-auto"
                  key={`card_${index}`}
                >
                  <Card className="follow-card w-100">
                    <Card.Img
                      variant="top"
                      height="220px"
                      src={`data:${item.projectCoverImage.documentType};base64, ${item.projectCoverImage.documentData}`}
                    />
                    <Card.Body className="pb-0">
                      <TimeRemaining
                        projectStateID={item.projectStateID}
                        campaignEndDate={item.projectEndDate}
                      />
                      <div
                        className={`contribution-card-state ${
                          item.contributionStateID === ContributionStateEnum.PAYED
                            ? 'success'
                            : 'pending'
                        } text-upperscase`}
                      >
                        {item.contributionState}
                      </div>
                      <br />
                      {item.companyName}
                      <Card.Title className="project-card-title project-card-title-hover">
                        <Link
                          to={`/project/${item.projectID}-${
                            item.projectName
                              ? format.removeDiacriticsAndSpecials(item.projectName)
                              : ''
                          }`}
                          alt={`Projet ${item.projectName ? item.projectName : ''}`}
                        >
                          {item.projectName}
                        </Link>
                      </Card.Title>
                      <small>le {moment(item.contributionCreateDate).format('DD/MM/YYYY')}</small>
                      <Card.Text as="div">
                        <Row className="pt-3 pb-2">
                          <Col xs="4" className="text-center">
                            <strong>
                              {fromCents(item.contributionAmount).toLocaleString('fr-FR')}€
                            </strong>
                            <br />
                            <small>montant</small>
                          </Col>
                          <Col xs="4" className="text-center">
                            <strong>{item.loanDuration} mois</strong>
                            <br />
                            <small>durée du prêt</small>
                          </Col>
                          <Col xs="4" className="text-center">
                            <strong>{item.interestRate}%</strong>
                            <br />
                            <small>taux du prêt</small>
                          </Col>
                        </Row>
                      </Card.Text>
                    </Card.Body>
                    {!item.passMarkReached &&
                      item.projectStateID === ProjectStateEnum.PUBLISHED &&
                      item.contributionStateID === ContributionStateEnum.PAYED &&
                      moment(item.projectEndDate).diff(moment(), 'days') > 7 && (
                        <>
                          <Button
                            className="button button-red m-0 w-100 rounded-0"
                            onClick={() => ConfirmCancelLoan(item)}
                          >
                            Annuler
                          </Button>
                          <CustomModal
                            show={confirmModalShow}
                            onClose={() => setConfirmModalShow(false)}
                            size="md"
                            title="Annuler ma contribution"
                          >
                            <Row>
                              <Col>
                                <p>Êtes-vous sur de vouloir annuler votre contribution ?</p>
                              </Col>
                            </Row>
                            <Row className="pt-3 text-center">
                              <Col>
                                <Button
                                  className="button button-green w-100 m-0"
                                  onClick={() => {
                                    setCancellingLoan(null);
                                    setConfirmModalShow(false);
                                  }}
                                >
                                  Non
                                </Button>
                              </Col>
                              <Col>
                                <Button
                                  className="button button-red w-100 m-0"
                                  onClick={() => {
                                    CancelLoan();
                                  }}
                                  loading={cancellingLoanLoading}
                                >
                                  Oui
                                </Button>
                              </Col>
                            </Row>
                          </CustomModal>
                        </>
                      )}
                    <Card.Footer className="d-flex align-items-center text-center font-italic">
                      <small className="w-100">
                        {GetExtraWording(item.projectStateID, item.contributionStateID)}
                      </small>
                    </Card.Footer>
                  </Card>
                </Col>
              );
            })}
          </CardDeck>
        </>
      ) : (
        <>
          <Loading small />
        </>
      )}
      {numberContributions > 3 && (
        <Pager
          page={props.page}
          pageNumber={Math.ceil(numberContributions / 3)}
          onClick={props.onClickPager}
          loading={contributionsLoading}
        />
      )}
    </>
  );
};

export default ContributionsList;
