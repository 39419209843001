export const ProjectStateEnum = {
  PUBLISHED: 6,
  CLOSED: 7,
  FAILED: 8,
  SUCCESS: 9,
  CONTRACTSIGNED: 10,
  MANDATSIGNED: 11,
  LOANSTART: 12,
  UNPAID: 13,
  LOANINDEFAULT: 14,
  WARRANTYAPPLIED: 15,
  FINISHED: 16,
};
