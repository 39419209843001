import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { CheckCircleFill } from 'react-bootstrap-icons';
import CustomModal from 'components/Misc/Modal';
import { useUser } from 'contexts/UserContext';
import { Toast } from 'services/utils';
import Button from 'components/Button/Button';
import { format, error } from 'helpers';
import { Lender } from 'services/api';
import { ProjectOwner } from 'services/api';
import { useProfile } from 'contexts/ProfileContext';
import AlertBanner from 'components/Misc/AlertBanner';
import Label from 'components/Misc/Label';
import { RoleEnum } from 'services/utils';

import ReCAPTCHA from 'react-google-recaptcha';
import { GoogleReCaptchaTypeEnum } from 'services/utils';

import { User } from 'services/api';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Veuillez renseigner une adresse email valide')
    .required('Veuillez renseigner votre adresse email'),
});

const changePasswordvalidationSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .required('Veuillez renseigner votre mot de passe')
    .matches(
      /^((?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])|(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[^a-zA-Z0-9])|(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[^a-zA-Z0-9])|(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^a-zA-Z0-9])).{8,}$/,
      'Votre mot de passe doit contenir au moins 8 charactères, une majuscule, une miniscule, un chiffre et un charactère spécial',
    ),
  password: Yup.string()
    .required('Veuillez renseigner votre mot de passe')
    .matches(
      /^((?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])|(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[^a-zA-Z0-9])|(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[^a-zA-Z0-9])|(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^a-zA-Z0-9])).{8,}$/,
      'Votre mot de passe doit contenir au moins 8 charactères, une majuscule, une miniscule, un chiffre et un charactère spécial',
    ),
  passwordConfirmation: Yup.string()
    .required('Veuillez confirmer votre mot de passe')
    .oneOf([Yup.ref('password'), null], 'Vous devez confirmer correctement voter mot de passe'),
});

const recaptchaRef = React.createRef();

const Credentials = () => {
  const [captchaError, setCaptchaError] = useState(false);
  const [googleToken, setGoogleToken] = useState(null);
  const [googleTokenType, setGoogleTokenType] = useState(GoogleReCaptchaTypeEnum.INVISIBLE);
  const [user, userLoading] = useUser();
  const { profile = [], profileLoading } = useProfile();
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [changePasswordSubmitting, setChangePasswordSubmitting] = useState(false);
  const [underModification, setUnderModification] = useState(false);

  const changePasswordInitialValues = {
    oldPassword: '',
    password: '',
    passwordConfirmation: '',
  };

  const initialValues = {
    email: profile.email,
  };

  const onTokenChange = (token, type = null) => {
    setGoogleToken(token);
    if (type) {
      setGoogleTokenType(type);
    }
  };

  const submit = (values, reCaptchaTokenType, reCaptchaToken, { resetForm, setSubmitting }) => {
    const usr = user.role === RoleEnum.PROJECTOWNER ? ProjectOwner : Lender;
    usr
      .ResetEmail({
        ...values,
        token: reCaptchaToken,
        tokenType: reCaptchaTokenType,
      })
      .then(() => {
        Toast.success(
          "Un email de confirmation a été envoyé. Suivez les instructions pour confirmer votre changement d'adresse",
        );
        resetForm();
        setUnderModification(false);
      })
      .catch((data) => {
        switch (data.response.status) {
          case 429:
            break;
          case 400:
            Toast.error(
              "Une erreur est survenue lors de l'envoi du formulaire. Veuillez vérifier votre saisie.",
            );
            var apiErrors = {};
            data.response.data.errors
              ? Object.keys(data.response.data.errors).forEach(function (e) {
                  apiErrors[format.camelize(e)] = data.response.data.errors[e];
                })
              : data.response.data.attributes.forEach((e) => {
                  apiErrors[format.camelize(e)] = data.response.data.message;
                });
            resetForm({ values, status: { apiErrors } });
            break;
          default:
            Toast.error(
              "Une erreur est survenue lors de l'envoi du formulaire. Veuillez réessayer ultérieurement.",
            );
            break;
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  const onSubmit = async (values, { resetForm, setSubmitting }) => {
    setSubmitting(true);
    if (!captchaError) {
      await recaptchaRef.current
        .executeAsync()
        .then((reCaptchaToken) => {
          submit(values, googleTokenType, reCaptchaToken, {
            resetForm,
            setSubmitting,
          });
        })
        .catch(() => {
          setCaptchaError(true);
          setSubmitting(false);
          Toast.warning('Veuillez valider le captcha');
        });
    } else {
      submit(values, googleTokenType, googleToken, {
        resetForm,
        setSubmitting,
      });
    }
  };

  const onChangePasswordSubmit = async (values) => {
    setChangePasswordSubmitting(true);

    if (!recaptchaRef.current && !captchaError) {
      setCaptchaError(true);
      setChangePasswordSubmitting(false);
      Toast.warning('Veuillez valider le captcha');
    } else {
      if (!captchaError) {
        await recaptchaRef.current
          .executeAsync()
          .then((reCaptchaToken) => {
            submitChangePassword(values, googleTokenType, reCaptchaToken);
          })
          .catch(() => {
            setCaptchaError(true);
            setChangePasswordSubmitting(false);
            Toast.warning('Veuillez valider le captcha');
          });
      } else {
        submitChangePassword(values, googleTokenType, googleToken);
      }
    }
  };

  const submitChangePassword = (values, reCaptchaTokenType, reCaptchaToken) => {
    User.SelfChangePassword({
      oldPassword: values.oldPassword,
      password: values.password,
      passwordConfirmation: values.passwordConfirmation,
      token: reCaptchaToken,
      tokenType: reCaptchaTokenType,
    })
      .then(() => {
        Toast.success('Mot de passe modifié avec succès !');
        setShowChangePasswordModal(false);
      })
      .catch((data) => {
        if (!data.response.data && data.response.data === 'captcha') {
          recaptchaRef.current.reset();
          setCaptchaError(true);
          Toast.warning('Veuillez valider le captcha');
        } else {
          Toast.error('Une erreur est survenue. Veuillez réessayer ultérieurement.');
        }
      })
      .finally(() => {
        setChangePasswordSubmitting(false);
      });
  };

  return (
    !profileLoading && (
      <>
        <Row className="text-uppercase green-text pb-2 pt-2">
          <Col md={12}>Mes identifiants de connexion</Col>
        </Row>
        <div className="bloc-kiwai">
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(props) => {
              const { errors, touched, isSubmitting, status, resetForm } = props;
              const emailError = error.getError('email', {
                touched,
                errors,
                status,
              });
              return (
                <Form>
                  {!underModification && (
                    <AlertBanner
                      variant={profile.emailConfirmed ? 'success' : 'warning'}
                      icon={profile.emailConfirmed ? <CheckCircleFill size={18} /> : null}
                      className="d-flex align-items-center"
                    >
                      {profile.emailConfirmed
                        ? 'Adresse email confirmée avec succès !'
                        : 'Adresse email non confirmée'}
                    </AlertBanner>
                  )}
                  <Row className="form-row">
                    <Col md={8}>
                      <Label htmlFor="email" text="Adresse Email" />
                      <Field
                        type="text"
                        name="email"
                        id="email"
                        className={emailError ? 'error' : ''}
                        disabled={profile.emailConfirmed && !underModification}
                      />
                      {emailError ? (
                        <div className="error-message position-relative">{emailError}</div>
                      ) : null}
                    </Col>

                    {profile.emailConfirmed && !underModification ? (
                      <Col className="pt-2 pt-md-4">
                        <input
                          className="button button-green-secondary mt-md-1 mr-0 ml-0 w-100"
                          type="button"
                          onClick={() => setUnderModification(true)}
                          value="Modifier mon email"
                        ></input>
                      </Col>
                    ) : (
                      <>
                        {underModification && (
                          <Col className="pt-2 pt-md-4">
                            <Button
                              className="button button-red mt-md-1 mr-0 ml-0 w-100"
                              type="submit"
                              onClick={() => {
                                setUnderModification(false);
                                resetForm();
                              }}
                            >
                              Annuler
                            </Button>
                          </Col>
                        )}
                        <Col className="pt-2 pt-md-4">
                          <Button
                            className="button button-green mt-md-1 mr-0 ml-0 w-100"
                            type="submit"
                            loading={isSubmitting}
                          >
                            {underModification ? 'Modifier' : 'Renvoyer le mail de confirmation'}
                          </Button>
                        </Col>
                      </>
                    )}
                  </Row>
                  <Row>
                    <Col>
                      <small>
                        <p>
                          Si vous changez cette adresse, un email de confirmation sera envoyé sur
                          votre nouvelle adresse. <br />
                          Votre changement d’adresse email sera effectif une fois votre nouvelle
                          adresse confirmée.
                        </p>
                      </small>
                    </Col>
                  </Row>
                  {!captchaError && (
                    <>
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={process.env.REACT_APP_INVISIBLE_RECAPTCHA_KEY}
                        size="invisible"
                      />
                    </>
                  )}
                </Form>
              );
            }}
          </Formik>
          <Row className="form-row v-center">
            <Col md={8}>
              <Label text="Mot de passe" />
              <br />
              Modifier votre mot de passe
            </Col>
            <Col className="pt-2 pt-md-0">
              <Button
                className="button button-green mr-0 ml-0 w-100"
                onClick={() => setShowChangePasswordModal(true)}
              >
                Modifier mon mot de passe
              </Button>
              <CustomModal
                show={showChangePasswordModal}
                onClose={() => setShowChangePasswordModal(!showChangePasswordModal)}
                size="xs"
                title="Modifier mon mot de passe"
              >
                <Row>
                  <Col>
                    <Formik
                      initialValues={changePasswordInitialValues}
                      initialStatus={{ apiErrors: {} }}
                      validationSchema={changePasswordvalidationSchema}
                      onSubmit={onChangePasswordSubmit}
                    >
                      {(props) => {
                        const { touched, errors, status } = props;
                        // Errors
                        const oldPasswordError = error.getError('oldPassword', {
                          touched,
                          errors,
                          status,
                        });
                        const passwordError = error.getError('password', {
                          touched,
                          errors,
                          status,
                        });
                        const passwordConfirmationError = error.getError('passwordConfirmation', {
                          touched,
                          errors,
                          status,
                        });
                        return (
                          <Form>
                            <Row className="form-row">
                              <Col xs="12">
                                <Label htmlFor="oldPassword" text="Mot de passe actuel" required />
                                <Field
                                  type="password"
                                  name="oldPassword"
                                  id="oldPassword"
                                  className={oldPasswordError && 'error'}
                                />
                                {oldPasswordError && (
                                  <div className="error-message position-relative">
                                    {oldPasswordError}
                                  </div>
                                )}
                              </Col>
                            </Row>
                            <Row className="form-row">
                              <Col xs="12">
                                <Label htmlFor="password" text="Nouveau mot de passe" required />
                                <Field
                                  type="password"
                                  name="password"
                                  id="password"
                                  className={passwordError && 'error'}
                                />
                                {passwordError && (
                                  <div className="error-message position-relative">
                                    {passwordError}
                                  </div>
                                )}
                              </Col>
                            </Row>
                            <Row className="form-row">
                              <Col xs="12">
                                <Label
                                  htmlFor="passwordConfirmation"
                                  text="Confirmation du nouveau mot de passe"
                                  required
                                />
                                <Field
                                  type="password"
                                  name="passwordConfirmation"
                                  id="passwordConfirmation"
                                  className={passwordConfirmationError && 'error'}
                                />
                                {passwordConfirmationError && (
                                  <div className="error-message position-relative">
                                    {passwordConfirmationError}
                                  </div>
                                )}
                              </Col>
                            </Row>
                            {captchaError && (
                              <Row>
                                <Col className="checkbox-recaptcha">
                                  <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey={process.env.REACT_APP_CHECKBOX_RECAPTCHA_KEY}
                                    size="normal"
                                    onChange={(token) => {
                                      onTokenChange(token, GoogleReCaptchaTypeEnum.CHECKBOX);
                                    }}
                                  />
                                </Col>
                              </Row>
                            )}
                            <Row>
                              <Col xs={12} md={6} className="text-right">
                                <Button
                                  className="button button-red w-100 mr-0 ml-0"
                                  type="button"
                                  onClick={() => {
                                    setShowChangePasswordModal(false);
                                    setChangePasswordSubmitting(false);
                                  }}
                                >
                                  Annuler
                                </Button>
                              </Col>
                              <Col xs={12} md={6} className="pt-2 pt-md-0">
                                <Button
                                  className="button button-green w-100 mr-0 ml-0"
                                  loading={changePasswordSubmitting}
                                  type="submit"
                                >
                                  Enregistrer
                                </Button>
                              </Col>
                            </Row>
                            {!captchaError && (
                              <>
                                <ReCAPTCHA
                                  ref={recaptchaRef}
                                  sitekey={process.env.REACT_APP_INVISIBLE_RECAPTCHA_KEY}
                                  size="invisible"
                                />
                              </>
                            )}
                          </Form>
                        );
                      }}
                    </Formik>
                  </Col>
                </Row>
              </CustomModal>
            </Col>
          </Row>
        </div>
      </>
    )
  );
};

export default Credentials;
