import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import moment from 'moment';

import PrimaryCase from './PrimaryCase';
import { fromCents } from 'services/utils';
import { Wallet2 } from 'react-bootstrap-icons';
import { DashboardProvider, useDashboard } from 'contexts/DashboardContext';
import { RoleEnum } from 'services/utils';
import { ProjectsPOProvider } from 'contexts/ProjectsPOContext';
import ProjectsPOList from '../Projects/ProjectsPOList';
import SecondaryCase from './SecondaryCase';
import * as Constant from 'config/constants';
import { PlatforConfigProvider } from 'contexts/PlatformConfigContext';

const ProjectOwnerDashboard = () => {
  return (
    <DashboardProvider type={RoleEnum.PROJECTOWNER}>
      <ProjectOwnerDashboardItems />
    </DashboardProvider>
  );
};

const ProjectOwnerDashboardItems = () => {
  const { dashboard = [] } = useDashboard();
  const [page, setPage] = useState(1);

  const onPaging = (page) => {
    setPage(page);
  };

  return (
    <>
      <Row className="text-uppercase green-text pb-2 pt-2">
        <Col md={12}>Synthèse de mon compte</Col>
      </Row>
      <div className="bloc-kiwai">
        <Row>
          <Col xs={12} md={4}>
            <PrimaryCase
              primaryColor={Constant.PrimaryColor}
              primaryTitle="nombre de projets en cours"
              primaryData={dashboard.pendingProjectsCount}
              secondaryColor={Constant.SecondaryColor}
              secondaryTitle="nombre total de projets"
              secondaryData={dashboard.projectsCount}
            />
          </Col>
          <Col xs={12} md={4} className="pt-3 pt-md-0">
            <PrimaryCase
              primaryColor="#F8D518"
              primaryTitle="montant total des contributions"
              primaryData={`${fromCents(dashboard.contributionsAmount).toLocaleString('fr-FR')}€`}
              secondaryColor="#bda313"
              secondaryTitle="nombre total de contributions"
              secondaryData={dashboard.contributionsCount}
            />
          </Col>
          <Col xs={12} md={4} className="pt-3 pt-md-0">
            <PrimaryCase
              primaryColor="#ffa500"
              primaryTitle="montant restant à rembourser"
              primaryData={`${fromCents(dashboard.loanAmountToReimburse).toLocaleString('fr-FR')}€`}
              secondaryColor="#ce8703"
              secondaryTitle="montant remboursé"
              secondaryData={`${fromCents(dashboard.loanAmountReimbursed).toLocaleString(
                'fr-FR',
              )}€`}
            />
          </Col>
        </Row>
      </div>
      <Row className="text-uppercase green-text pb-2 pt-2">
        <Col md={12}>Mon prochain remboursement</Col>
      </Row>
      <div className="bloc-kiwai">
        <Row>
          <Col xs={12}>
            {dashboard.nextReimbursementProjectName !== null &&
            dashboard.nextReimbursementDate !== null ? (
              <SecondaryCase
                icon={<Wallet2 size={50} />}
                title={`Projet ${dashboard.nextReimbursementProjectName}`}
                subTitle={`le ${moment(dashboard.nextReimbursementDate).format('DD/MM/YYYY')}`}
                data={`${fromCents(dashboard.nextReimbursementAmount).toLocaleString('fr-FR')}€`}
              />
            ) : (
              <em>Aucun remboursement prévu pour le moment</em>
            )}
          </Col>
        </Row>
      </div>
      <Row className="text-uppercase green-text pb-2 pt-2">
        <Col md={12}>Mes Projets</Col>
      </Row>
      <ProjectsPOProvider page={page}>
        <PlatforConfigProvider>
          <ProjectsPOList page={page} onClickPager={onPaging} />
        </PlatforConfigProvider>
      </ProjectsPOProvider>
    </>
  );
};

export default ProjectOwnerDashboard;
