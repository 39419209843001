import axios from 'axios';
import * as Constant from 'config/constants';
import { FrontScopeEnum, PartnerTypeEnum } from 'services/utils';

export async function GetGlobalsPartners() {
  let scope = Constant.isENR()
    ? FrontScopeEnum.EverythingAboutENR
    : FrontScopeEnum.EverythingAboutNormandy;
  return await axios
    .get(`Partner/Partners/?scope=${scope}&partnerType=${PartnerTypeEnum.Global}`)
    .then((response) => {
      return response.data;
    });
}

export async function GetProjectGarantors(projectId) {
  return await axios.get(`Project/${projectId}/Garantors`).then((response) => {
    return response.data;
  });
}
