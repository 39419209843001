import Loading from 'components/Misc/Loading';
import React, { useEffect, useState, useMemo, createContext, useContext } from 'react';

import { Lender } from 'services/api';

const WarrantyLoanTimeTableContext = createContext(null);

const WarrantyLoanTimeTableProvider = ({ children, page, id, type }) => {
  const [warrantyLoanTimeTable, setWarrantyLoanTimeTable] = useState(null);
  const [warrantyLoanTimeTableLoading, setWarrantyLoanTimeTableLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;
    setWarrantyLoanTimeTableLoading(true);
    Lender.GetWarrantyLoanTimeTable(id)
      .then((response) => {
        if (isMounted && response) {
          setWarrantyLoanTimeTable([response.data]);
          setWarrantyLoanTimeTableLoading(false);
        }
      })
      .catch(() => {
        if (isMounted) setWarrantyLoanTimeTable(null);
        setWarrantyLoanTimeTableLoading(false);
      });

    return () => (isMounted = false);
  }, [id, page, type]);

  const data = useMemo(
    () => ({
      warrantyLoanTimeTable,
      warrantyLoanTimeTableLoading,
    }),
    [warrantyLoanTimeTable, warrantyLoanTimeTableLoading],
  );

  return warrantyLoanTimeTableLoading === true ? (
    <Loading small />
  ) : (
    <WarrantyLoanTimeTableContext.Provider value={data}>
      {children}
    </WarrantyLoanTimeTableContext.Provider>
  );
};

const useWarrantyLoanTimeTable = () => {
  const context = useContext(WarrantyLoanTimeTableContext);
  if (context === undefined) {
    throw new Error(
      'useWarrantyLoanTimeTable can only be used inside WarrantyLoanTimeTableProvider',
    );
  }
  return context;
};

export { WarrantyLoanTimeTableProvider, useWarrantyLoanTimeTable };
