import axios from 'axios';

export async function GetByScope(filter, projectScopeId) {
  return await axios.get(`Project/list/${projectScopeId}${filter}`).then((response) => {
    return response;
  });
}

export async function Post(data) {
  return await axios.post('Project/Public', data).then((response) => {
    return response;
  });
}

export async function Contribute(data) {
  return await axios.post(`Loan`, data).then((response) => {
    return response;
  });
}

export async function LenderPossibleInvestAmount(id) {
  return await axios.get(`Loan/LenderPossibleInvestAmount/${id}`).then((response) => {
    return response;
  });
}

export async function Commentary(id, data) {
  return await axios.post(`Project/Public/${id}/Commentary`, data).then((response) => {
    return response;
  });
}

export async function GetProjectContractDemo(id) {
  var urlAPI = `Project/${id}/FrontProjectContractDemo`;

  return await axios.get(urlAPI).then(({ data }) => {
    return data;
  });
}

export async function GetMandateFile(id) {
  const response = await axios
    .get(`Project/Public/${id}/mandate`, {
      responseType: 'blob',
    })
    .then((response) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `mandat-project-${id}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  return response;
}

export async function GetLoanTimeTableFile(id, campaignID) {
  const response = await axios
    .get(`Project/public/${id}/ExportLoanTimeTable/${campaignID}`, {
      responseType: 'blob',
    })
    .then((response) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `tableau-echeances-project-${id}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  return response;
}

export async function GetContractFile(id) {
  const response = await axios
    .get(`YouSignControlleur/${id}/GetLendersContractsArchive`, {
      responseType: 'blob',
    })
    .then((response) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `contract-project-${id}.zip`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  return response;
}

export async function GetContractFinalFile(id) {
  const response = await axios
    .get(`YouSignControlleur/${id}/GetFinaleContractsArchive`, {
      responseType: 'blob',
    })
    .then((response) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `final-contract-project-${id}.zip`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  return response;
}

export async function GetContractOTP(id) {
  return await axios.get(`YouSignControlleur/${id}/GetContractOTP`).then((response) => {
    return response;
  });
}

export async function GetContractDownloadStatus(id) {
  return await axios.get(`YouSignControlleur/${id}/GetContractDownloadStatus`).then((response) => {
    return response;
  });
}

export async function PrivateSalePwd(data) {
  const response = await axios.post('Project/VerifyPasswordPrivateSale', data);
  return response;
}

export async function SendContractOTP(id, code, contractsReaded) {
  const response = await axios.put(`YouSignControlleur/${id}/SendContractOTP`, {
    code: code,
    contractsReaded: contractsReaded,
  });
  return response;
}
