import axios from 'axios';

export async function Get(id) {
  return await axios.get(`Company/${id}/Profile`).then((response) => {
    return response.data;
  });
}

export async function PutAddress(id, values) {
  return await axios.put(`Company/${id}/Address`, values).then((response) => {
    return response.data;
  });
}

export async function PutIdentity(id, values) {
  return await axios.put(`Company/${id}/Identity`, values).then((response) => {
    return response.data;
  });
}

export async function PutKYC(id, values) {
  return await axios.put(`Company/${id}/KYC`, values).then((response) => {
    return response.data;
  });
}

export async function PutArticlesOfAssocation(id, values) {
  return await axios.put(`Company/${id}/ArticlesOfAssocation`, values).then((response) => {
    return response.data;
  });
}

export async function PutRegistrationProof(id, values) {
  return await axios.put(`Company/${id}/RegistrationProof`, values).then((response) => {
    return response.data;
  });
}

export async function PutShareholderDeclaration(id, values) {
  return await axios.put(`Company/${id}/ShareholderDeclaration`, values).then((response) => {
    return response.data;
  });
}

export async function RibReader(file) {
  const response = await axios.post('Company/GetIBANFromPDF', file);
  return response;
}

export async function PutBankAccount(id, values) {
  return await axios.put(`Company/${id}/BankAccount`, values).then((response) => {
    return response.data;
  });
}
