import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

import { Country, User } from 'services/api';
import { Toast } from 'services/utils';
import { format, error } from 'helpers';
import { useProfile } from 'contexts/ProfileContext';
import { useUser } from 'contexts/UserContext';

import Button from 'components/Button/Button';
import AlertBanner from 'components/Misc/AlertBanner';
import Label from 'components/Misc/Label';
import { CheckCircleFill } from 'react-bootstrap-icons';

const validationSchema = Yup.object().shape({
  civility: Yup.string().required('Ce champ est requis'),
  birthCity: Yup.string()
    .required('Ce champ est requis')
    .matches(/^[a-zA-ZÀ-Ÿ-.' ]*$/, 'Veuillez renseigner une valeur alphanumérique'),
  birthCountryId: Yup.string().required('Ce champ est requis'),
  birthDepartmentId: Yup.string().required('Ce champ est requis'),
  birthPostalCode: Yup.string()
    .required('Ce champ est requis')
    .matches(/^[0-9a-zA-ZÀ-Ÿ-. ]*$/, 'Veuillez renseigner une valeur alphanumérique'),
});

const Identity = () => {
  const [countries, setCountries] = useState([]);
  const [departments, setDepartment] = useState([]);

  const { profile = {}, profileLoading, setCurrentProfile } = useProfile();
  const [user] = useUser();

  const country = countries
    .sort(function (a, b) {
      if (a.libcog < b.libcog) {
        return -1;
      }
      if (a.libcog > b.libcog) {
        return 1;
      }
      return 0;
    })
    .map((item, index) => {
      return (
        <option value={item.id} key={`country_${index}`}>
          {item.libcog}
        </option>
      );
    });

  const department = departments.map(({ id, libelle, dep }) => {
    return (
      <option key={`department_${id}`} value={id}>
        {dep} - {libelle}
      </option>
    );
  });

  const complete =
    profile.birthCity && profile.birthDepartment && profile.birthCountry && profile.birthPostalCode;

  const initialValues = {
    civility: profile.civility ? profile.civility.toLowerCase() : '',
    birthCity: profile.birthCity ? profile.birthCity : '',
    birthDepartmentId: profile.birthDepartment ? profile.birthDepartment.id : '',
    birthCountryId: profile.birthCountry ? profile.birthCountry.id : '',
    birthPostalCode: profile.birthPostalCode ? profile.birthPostalCode : '',
    firstName: profile.firstName ? profile.firstName : '',
    useName: profile.useName ? profile.useName : '',
    birthName: profile.birthName ? profile.birthName : '',
    birthDate: profile.birthDate ? moment(profile.birthDate).format('DD/MM/YYYY') : '',
    nationality: profile.nationality ? profile.nationality.libcog : '',
  };

  const onSubmit = (values, { resetForm, setSubmitting }) => {
    setSubmitting(true);
    User.PutIdentity(user.role, {
      ...values,
      birthCountryId: parseInt(values.birthCountryId),
      birthDepartmentId: parseInt(values.birthDepartmentId),
    })
      .then((response) => {
        setCurrentProfile(response.data);
        Toast.success('Enregistrement effectué avec succés !');
      })
      .catch((data) => {
        switch (data.response.status) {
          case 429:
            break;
          case 400:
            Toast.error(
              "Une erreur est survenue lors de l'envoi du formulaire. Veuillez vérifier votre saisie.",
            );
            var apiErrors = {};
            Object.keys(data.response.data.errors).forEach(function (e) {
              apiErrors[format.camelize(e)] = data.response.data.errors[e];
            });
            resetForm({ values, status: { apiErrors } });
            break;
          default:
            Toast.error(
              "Une erreur est survenue lors de l'envoi du formulaire. Veuillez réessayer ultérieurement.",
            );
            break;
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  useEffect(() => {
    async function loadCountry() {
      const dataCountries = await Country.getCountry();
      const dataDepartments = await Country.getDept();
      setCountries(dataCountries);
      setDepartment(dataDepartments);
    }
    loadCountry();
  }, []);

  return (
    !profileLoading && (
      <>
        <Row className="text-uppercase green-text pb-2 pt-2">
          <Col md={12}>Mes informations personnelles</Col>
        </Row>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          initialStatus={{ apiErrors: {} }}
        >
          {(props) => {
            const { errors, touched, status, isSubmitting } = props;
            const civilityError = error.getError('civility', { touched, errors, status });
            const birthCityError = error.getError('birthCity', { touched, errors, status });
            const birthDepartmentIdError = error.getError('birthDepartmentId', {
              touched,
              errors,
              status,
            });
            const birthPostalCodeError = error.getError('birthPostalCode', {
              touched,
              errors,
              status,
            });
            const birthCountryIdError = error.getError('birthCountryId', {
              touched,
              errors,
              status,
            });
            return (
              <Form className="bloc-kiwai">
                <AlertBanner
                  variant={complete ? 'success' : 'warning'}
                  icon={complete ? <CheckCircleFill size={18} /> : null}
                >
                  {complete
                    ? 'Vos informations personnelles ont été enregistrées avec succès !'
                    : 'Informations manquantes'}
                </AlertBanner>
                <Row className="form-row">
                  <Col>
                    <Label text="Civilité" />
                    <div className="text-center text-md-left">
                      <label className="radio-label ml-0">
                        <Field type="radio" name="civility" value="madame" />
                        Madame
                      </label>
                      <label className="radio-label">
                        <Field type="radio" name="civility" value="monsieur" />
                        Monsieur
                      </label>
                    </div>
                    {civilityError && <div className="error-message">{civilityError}</div>}
                  </Col>
                </Row>
                <Row className="form-row">
                  <Col md="4">
                    <Label htmlFor="birthName" text="Nom de naissance" />
                    <Field type="text" name="birthName" id="birthName" disabled />
                  </Col>
                  <Col md="4" className="pt-4 pt-md-0">
                    <Label htmlFor="useName" text="Nom d'usage" />
                    <Field type="text" name="useName" id="useName" disabled />
                  </Col>
                  <Col md="4" className="pt-4 pt-md-0">
                    <Label htmlFor="firstName" text="Prénom" />
                    <Field type="text" name="firstName" id="firstName" disabled />
                  </Col>
                </Row>
                <Row className="form-row">
                  <Col md="4">
                    <Label htmlFor="date" text="Date de naissance" />
                    <Field type="text" name="birthDate" id="birthDate" disabled />
                  </Col>
                  <Col md="4" className="pt-4 pt-md-0">
                    <Label htmlFor="nationality" text="Nationalité" />
                    <Field type="text" name="nationality" id="nationality" disabled />
                  </Col>
                </Row>
                <Row className="form-row">
                  <Col>
                    <small>
                      Pour modifier vos noms, prénom, date de naissance ou nationalité, merci
                      d'adresser une requête par email à l'adresse&nbsp;
                      <a href="mailto:contact@kiwai-normandie.fr">contact@kiwai-normandie.fr</a>.
                    </small>
                  </Col>
                </Row>
                <Row className="form-row">
                  <Col md="4">
                    <Label htmlFor="birthCountryId" text="Pays de naissance" required />
                    <div
                      className={
                        birthCountryIdError ? 'select-container error' : 'select-container'
                      }
                    >
                      <Field
                        as="select"
                        name="birthCountryId"
                        id="birthCountryId"
                        className={birthCountryIdError ? 'error' : ''}
                      >
                        <option value=""></option>
                        {country.length > 0 && country}
                      </Field>
                    </div>

                    {birthCountryIdError && (
                      <div className="error-message">{birthCountryIdError}</div>
                    )}
                  </Col>
                  <Col md="4" className="pt-4 pt-md-0">
                    <Label htmlFor="birthDepartmentId" text="Département de naissance" required />
                    <div
                      className={
                        birthDepartmentIdError ? 'select-container error' : 'select-container'
                      }
                    >
                      <Field
                        name="birthDepartmentId"
                        as="select"
                        id="birthDepartmentId"
                        className={birthDepartmentIdError ? 'error' : ''}
                      >
                        <option value=""></option>
                        {department.length > 0 && department}
                      </Field>
                    </div>
                    {birthDepartmentIdError && (
                      <div className="error-message">{birthDepartmentIdError}</div>
                    )}
                  </Col>
                  <Col md="4" className="pt-4 pt-md-0">
                    <Label htmlFor="birthPostalCode" text="Code postal de naissance" required />
                    <Field
                      type="text"
                      name="birthPostalCode"
                      id="birthPostalCode"
                      className={birthPostalCodeError ? 'error' : ''}
                    />
                    {birthPostalCodeError ? (
                      <div className="error-message">{birthPostalCodeError}</div>
                    ) : null}
                  </Col>
                </Row>
                <Row className="form-row">
                  <Col md="4">
                    <Label htmlFor="birthCity" text="Ville de naissance" required />
                    <Field
                      type="text"
                      name="birthCity"
                      id="birthCity"
                      className={birthCityError ? 'error' : ''}
                    />
                    {birthCityError && <div className="error-message">{birthCityError}</div>}
                  </Col>
                </Row>
                <Row className="v-center form-footer">
                  <Col className="text-right">
                    <Button
                      className="button button-green m-0 w-100 w-md-auto"
                      type="submit"
                      loading={isSubmitting}
                    >
                      Enregistrer
                    </Button>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </>
    )
  );
};

export default Identity;
