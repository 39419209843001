import React from 'react';

const Home = React.lazy(() => import('./views/index'));
const Sign_in = React.lazy(() => import('./views/PublicArea/Authentification/sign_in'));
const Sign_up = React.lazy(() => import('./views/PublicArea/Authentification/sign_up'));
const Faq = React.lazy(() => import('./views/PublicArea/Faq'));
const User = React.lazy(() => import('./views/SecuredArea/User'));
const PreProject = React.lazy(() => import('./views/PublicArea/preProject'));
const Project = React.lazy(() => import('./views/PublicArea/Project/Project'));
const Projects = React.lazy(() => import('./views/PublicArea/projects'));
const EmailValidation = React.lazy(() => import('./views/PublicArea/EmailValidation'));
const EmailPasswordValidation = React.lazy(() =>
  import('./views/PublicArea/EmailPasswordValidation'),
);
const PasswordReset = React.lazy(() => import('./views/PublicArea/PasswordReset'));
const PasswordMigrate = React.lazy(() => import('./views/PublicArea/PasswordMigrate'));
const PasswordForgot = React.lazy(() => import('./views/PublicArea/PasswordForgot'));
const ResetEmailValidation = React.lazy(() => import('./views/PublicArea/ResetEmailValidation'));
const ResetEmailValidationPO = React.lazy(() =>
  import('./views/PublicArea/ResetEmailValidationPO'),
);
const HowTo = React.lazy(() => import('./views/PublicArea/HowTo'));
const PrivateSale = React.lazy(() => import('./views/PublicArea/Project/PrivateSale'));
const BecomeLender = React.lazy(() => import('./views/PublicArea/BecomeLender'));
const BecomeBorrower = React.lazy(() => import('./views/PublicArea/BecomeBorrower'));
const IndicatorsTable = React.lazy(() => import('./views/PublicArea/IndicatorsTablePage'));
const routes = [
  { path: '/', exact: true, component: Home, private: false },
  {
    path: '/indicators_table',
    exact: true,
    component: IndicatorsTable,
    private: false,
  },

  { path: '/sign_in', exact: true, component: Sign_in, private: false },
  { path: '/sign_up', exact: true, component: Sign_up, private: false },
  { path: '/pre_project', exact: true, component: PreProject, private: false },
  {
    path: '/project/:project_name?/:privateSaleToken?',
    exact: true,
    component: Project,
    private: false,
  },
  { path: '/projects', exact: true, component: Projects, private: false },

  { path: '/faq', exact: true, component: Faq, private: false },
  { path: '/how_to', exact: true, component: HowTo, private: false },
  {
    path: '/become_lender',
    exact: true,
    component: BecomeLender,
    private: false,
  },
  {
    path: '/become_borrower',
    exact: true,
    component: BecomeBorrower,
    private: false,
  },

  { path: '/user/companies/:id', exact: true, component: User, private: true },
  {
    path: '/user/contributions/:state?',
    exact: true,
    component: User,
    private: true,
  },
  { path: '/user/:id?', exact: true, component: User, private: true },

  {
    path: '/lender/validate-account/:token?',
    exact: true,
    component: EmailValidation,
    private: false,
  },
  {
    path: '/lender/change-mail/:token?',
    exact: true,
    component: ResetEmailValidation,
    private: false,
  },
  {
    path: '/project-owner/change-mail/:token?',
    exact: true,
    component: ResetEmailValidationPO,
    private: false,
  },
  {
    path: '/project-owner/validate-account/:token?',
    exact: true,
    component: EmailPasswordValidation,
    private: false,
  },
  {
    path: '/password/reset/:token?',
    exact: true,
    component: PasswordReset,
    private: false,
  },
  {
    path: '/password/migrate/:token?',
    exact: true,
    component: PasswordMigrate,
    private: false,
  },
  {
    path: '/password/forgot',
    exact: true,
    component: PasswordForgot,
    private: false,
  },
  {
    path: '/privateSaleValidate/:project_name?',
    exact: true,
    component: PrivateSale,
    private: true,
  },
];

export default routes;
