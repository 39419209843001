import React, { useEffect, useState } from 'react';
import ContributionsItem from './ContributionsItem';
import LoansItem from './LoansItem';
import { useParams } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Toast } from 'services/utils';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import CustomModal from 'components/Misc/Modal';
import Button from 'components/Button/Button';

const Contributions = () => {
  let { state } = useParams();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    switch (state) {
      case 'refused':
        Toast.warning('Nous avons bien pris en compte votre refus de signer le contrat');
        break;
      case 'failed':
        Toast.error('Un problème est survenu durant la signature du contrat');
        break;
      case 'success':
        Toast.success('Votre demande de contribution a bien été prise en compte');
        setShowModal(true);
        break;
      default:
        break;
    }
  }, [state]);

  const reloadPage = function () {
    window.location.href = window.location.origin + '/user/contributions';
  };

  return (
    <>
      <ContributionsItem />
      <LoansItem />
      <br />
      <CustomModal
        show={showModal}
        onClose={reloadPage}
        size="lg"
        title="Votre contribution est en cours de validation !"
      >
        <Row>
          <Col>
            <p>
              Merci pour votre implication en faveur de la transition écologique du territoire !
              <br />
              <br />
              <strong>
                Votre contribution est en cours de validation par nos équipes, vous recevrez d'ici
                quelques minutes un mail de confirmation.
              </strong>
            </p>
          </Col>
        </Row>
        <Row className="pt-3">
          <Col>
            <Button className="button button-green-secondary w-100 ml-0 mr-0" onClick={reloadPage}>
              Fermer
            </Button>
          </Col>
        </Row>
      </CustomModal>
    </>
  );
};

export default withRouter(Contributions);
