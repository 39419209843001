import React, { useEffect, useMemo, createContext, useState, useContext } from 'react';
import { PlatformInformation } from 'services/api';

const ContactUsContext = createContext(undefined);

const ContactUsProvider = ({ children }) => {
  const [contactUs, setContactUs] = useState();

  useEffect(() => {
    let isMounted = true;
    PlatformInformation.GetContactUs()
      .then((data) => {
        if (isMounted) setContactUs(data);
      })
      .catch(() => {
        if (isMounted) setContactUs(null);
      });
    return () => (isMounted = false);
  }, []);

  const data = useMemo(
    () => ({
      contactUs,
    }),
    [contactUs],
  );

  return <ContactUsContext.Provider value={data}>{children}</ContactUsContext.Provider>;
};

const useContactUs = () => {
  const context = useContext(ContactUsContext);
  if (context === undefined) {
    throw new Error('useContactUs can only be used inside ContactUsProvider');
  }
  return context;
};

export { ContactUsProvider, useContactUs };
