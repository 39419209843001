import React from 'react';
import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';

import Button from 'components/Button/Button';

import { useUser } from 'contexts/UserContext';
import { useMenu } from 'contexts/MenuContext';

import { RoleEnum } from 'services/utils';

import Wallet from './Wallet';
import * as Constant from 'config/constants';

const HeaderRight = () => {
  const [user] = useUser();
  const [, setCurrentMenuOpen] = useMenu();
  return (
    <>
      {user ? (
        <>
          <div className="d-flex">
            {user.role === RoleEnum.LENDER && <Wallet />}
            <Nav.Item
              className="pr-4 pl-md-4 user-name"
              onClick={() => {
                setCurrentMenuOpen(true);
              }}
            >
              <svg viewBox="0 0 100 80" width="40" height="40" fill={Constant.PrimaryColor}>
                <rect y="10" width="100" height="12"></rect>
                <rect y="35" width="100" height="12"></rect>
                <rect y="60" width="100" height="12"></rect>
              </svg>
              <span className="pl-md-2 d-none d-md-inline">{user.firstname}</span>
            </Nav.Item>
          </div>
        </>
      ) : (
        <Nav className="text-center">
          <Nav.Link as={Link} href="#" to="/sign_in" alt="Connexion" className="d-none d-lg-flex">
            <Button className="button button-green">Connexion</Button>
          </Nav.Link>
          <Nav.Link as={Link} href="#" to="/sign_up" alt="Inscription" className="d-none d-lg-flex">
            <Button className="button button-inscription">Inscription</Button>
          </Nav.Link>
          <Nav.Item
            className="pr-4 user-name  d-flex align-items-center d-lg-none"
            onClick={() => {
              setCurrentMenuOpen(true);
            }}
          >
            <svg viewBox="0 0 100 80" width="40" height="40" fill={Constant.PrimaryColor}>
              <rect y="10" width="100" height="12"></rect>
              <rect y="35" width="100" height="12"></rect>
              <rect y="60" width="100" height="12"></rect>
            </svg>
          </Nav.Item>
        </Nav>
      )}
    </>
  );
};

export default HeaderRight;
