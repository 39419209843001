import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from '../Button/Button';
import { fileType, bytes } from '../../helpers';

const File = (props) => {
  const [file, setFile] = useState(undefined);

  return (
    <div className="file">
      <input
        id={props.name}
        name={props.name}
        className={'input-hidden' + props.className}
        type="file"
        onClick={(e) => (e.target.value = null)}
        onChange={(evt) => {
          const fileEvent = evt.target.files[0];
          setFile(fileEvent);
          props.onChange(fileEvent);
        }}
        disabled={props.disabled}
      />
      {file ? (
        <Row className="v-center">
          <Col md={1}>
            <p>{fileType.determineFile(file.type)}</p>
          </Col>
          <Col md={6}>
            <p style={{ overflow: 'hidden', textAlign: 'unset' }}>
              <b>Nom : </b>
              {file.name}
            </p>
            <p>
              <b>Taille : </b>
              {bytes.format(file.size)}
            </p>
          </Col>

          {!props.loading && (
            <Col md={5}>
              <Button
                className="button button-red"
                type="button"
                onClick={() => {
                  setFile(undefined);
                  props.onChange(undefined);
                }}
                disabled={props.disabled}
              >
                Supprimer
              </Button>
            </Col>
          )}
        </Row>
      ) : (
        <Row>
          <Col xs={12} md={8}>
            <Button
              className="button button-green-secondary w-100 m-md-0 m-0"
              type="button"
              onClick={() => {
                document.getElementById(props.name).click();
              }}
              disabled={props.disabled}
            >
              Sélectionner un fichier...
            </Button>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default File;
