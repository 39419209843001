import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import moment from 'moment';

// import { HorizontalBar } from 'react-chartjs-2';

import { useHistory } from 'react-router-dom';

import Button from 'components/Button/Button';
import PrimaryCase from './PrimaryCase';
import SecondaryCase from './SecondaryCase';
import { fromCents } from 'services/utils';
import { Award, CreditCard, PatchExclamationFill, Percent, Wallet2 } from 'react-bootstrap-icons';
import { DashboardProvider, useDashboard } from 'contexts/DashboardContext';
import { RoleEnum } from 'services/utils';
import * as Constant from 'config/constants';

const LenderDashboard = () => {
  return (
    <DashboardProvider type={RoleEnum.LENDER}>
      <LenderDashboardItems />
    </DashboardProvider>
  );
};
const LenderDashboardItems = () => {
  const { dashboard = [] } = useDashboard();
  const history = useHistory();

  return (
    <>
      <Row className="text-uppercase green-text pb-2 pt-2">
        <Col md={12}>Synthèse de mon compte</Col>
      </Row>
      <div className="bloc-kiwai">
        <Row>
          <Col xs={12} md={4}>
            <PrimaryCase
              primaryColor={Constant.PrimaryColor}
              primaryTitle="solde disponible"
              primaryData={`${fromCents(dashboard.availableBalance).toLocaleString('fr-FR')}€`}
              secondaryColor={Constant.SecondaryDashboardColor}
              secondaryClassName="d-flex align-items-center justify-content-center"
            >
              <Button
                className="button button-green-secondary"
                onClick={() => {
                  history.push('/user/fund');
                }}
              >
                Alimenter mon compte
              </Button>
            </PrimaryCase>
          </Col>
          <Col xs={12} md={4} className="pt-3 pt-md-0">
            <PrimaryCase
              primaryColor="#F8D518"
              primaryTitle="capital restant à percevoir"
              primaryData={`${fromCents(dashboard.capitalRemainingReceived).toLocaleString(
                'fr-FR',
              )}€`}
              secondaryColor="#bda313"
              secondaryTitle="capital reçu"
              secondaryData={`${fromCents(dashboard.capitalReimbursed).toLocaleString('fr-FR')}€`}
            />
          </Col>
          <Col xs={12} md={4} className="pt-3 pt-md-0">
            <PrimaryCase
              primaryColor="#ffa500"
              primaryTitle="intêrets restants à percevoir"
              primaryData={`${fromCents(dashboard.interestRemainingReceived).toLocaleString(
                'fr-FR',
              )}€`}
              secondaryColor="#ce8703"
              secondaryTitle="intêrets reçus"
              secondaryData={`${fromCents(dashboard.grossInterestReceived).toLocaleString(
                'fr-FR',
              )}€`}
            />
          </Col>
        </Row>
      </div>
      <Row className="text-uppercase green-text pb-2 pt-2">
        <Col md={12}>Mes investissements</Col>
      </Row>
      <div className="bloc-kiwai">
        <Row>
          <Col>
            <Row>
              <Col xs={12} md={6}>
                <SecondaryCase
                  icon={<Award size={50} />}
                  title="nombre de projets investis"
                  data={dashboard.numberProjectsInvested}
                />
              </Col>
              <Col xs={12} md={6} className="pt-3 pt-md-0">
                <SecondaryCase
                  icon={<CreditCard size={50} />}
                  title="montant des contributions"
                  data={`${fromCents(dashboard.totalAmountContributions).toLocaleString('fr-FR')}€`}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} className="pt-3">
                <SecondaryCase
                  icon={<PatchExclamationFill size={50} />}
                  title="capital en défaut"
                  data={`${fromCents(dashboard.capitalInDefault).toLocaleString('fr-FR')}€`}
                  tooltip="Le capital et les intérêts échus des prêts réalisés sur Kiwaï sont garantis par la Caisse d'Epargne Normandie"
                />
              </Col>
              <Col xs={12} md={6} className="pt-3">
                <SecondaryCase
                  icon={<Percent size={50} />}
                  title="taux de rendement moyen"
                  data={`${dashboard.averageRateLoans.toLocaleString('fr-FR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}%`}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <Row className="text-uppercase green-text pb-2 pt-2">
        <Col md={12}>Mon prochain remboursement</Col>
      </Row>
      <div className="bloc-kiwai">
        <Row className="align-items-center">
          <Col xs={12} md={6}>
            {dashboard.nextReimbursementProjectName !== null &&
            dashboard.nextReimbursementDate !== null ? (
              <SecondaryCase
                icon={<Wallet2 size={50} />}
                title={`Projet ${dashboard.nextReimbursementProjectName}`}
                subTitle={`le ${moment(dashboard.nextReimbursementDate).format('DD/MM/YYYY')}`}
                data={`${fromCents(dashboard.nextReimbursementAmount).toLocaleString('fr-FR')}€`}
              />
            ) : (
              <em>Aucun remboursement prévu pour le moment</em>
            )}
          </Col>
          <Col
            className="pt-3 pt-md-0"
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            xs={12}
            md={6}
          >
            <Button
              className="button button-green-secondary w-100 h-100 m-0"
              onClick={() => {
                history.push('/user/contributions');
              }}
            >
              Voir toutes mes contributions
            </Button>
          </Col>
        </Row>
      </div>
      {/* <Row className="text-uppercase green-text pb-2 pt-2">
        <Col md="12">Répartition sectorielle</Col>
      </Row>
      <div className="bloc-kiwai">
        <Row>
          <Col>
            {dashboard.numberProjectsInvested ? (
              <HorizontalBar
                options={{
                  maintainAspectRatio: false,
                  animation: true,
                  legend: {
                    display: false,
                  },

                  scales: {
                    xAxes: [
                      {
                        scaleLabel: {
                          display: true,
                          labelString: "Nombre d'investissement",
                        },
                        ticks: {
                          beginAtZero: true,
                          max:
                            Math.ceil(
                              Math.max.apply(
                                Math,
                                dashboard.projectCategoryDistributions.map(function (o) {
                                  return o.numberProjectsInvested;
                                }),
                              ) / 100,
                            ) * 10,
                          stepSize: 1,
                          lineHeight: 5,
                        },
                      },
                    ],
                  },
                }}
                data={{
                  labels: dashboard.projectCategoryDistributions.map(function (l) {
                    return l.projectCategoryName;
                  }),
                  datasets: [
                    {
                      data: dashboard.projectCategoryDistributions.map(function (l) {
                        return l.numberProjectsInvested;
                      }),
                      backgroundColor: dashboard.projectCategoryDistributions.map(function (l) {
                        return l.projectCategoryColor;
                      }),
                    },
                  ],
                }}
                height={200}
              />
            ) : (
              <em>Aucune donnée disponible pour le moment</em>
            )}
          </Col>
        </Row>
      </div> */}
      <br />
    </>
  );
};

export default LenderDashboard;
