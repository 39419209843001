import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { Auth } from 'services/api';
import { AuthenticationStatus } from 'services/api/auth';

const Layout = React.lazy(() => import('containers/Layout'));

export default ({ children, ...rest }) => {
  const [userAuthenticationStatus, setUserAuthenticationStatus] = useState(null);
  const routes = {
    [AuthenticationStatus.AUTHENTICATED]: (
      <Route {...rest} render={(props) => <Layout {...props} />} />
    ),
    [AuthenticationStatus.NOT_AUTHENTICATED]: <Redirect to={'/sign_in'} />,
  };

  useEffect(() => {
    (async function getUserAuthenticationStatus() {
      const status = await Auth.GetUserAuthenticationStatus();
      setUserAuthenticationStatus(status);
    })();
  }, []);

  return routes[userAuthenticationStatus] ?? null;
};
