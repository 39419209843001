import React from 'react';

const Select = (props) => {
  return (
    <div className="select-container">
      <select onChange={props.onChange} value={props.value}>
        {props.firstOption && (
          <option value="" key={props.name + '_' + 0} defaultValue={props.value === ''}>
            {props.firstOption}
          </option>
        )}
        {props.values.map((item, index) => {
          return (
            <option
              value={item.value}
              key={props.name + '_' + index + 1}
              defaultValue={props.value === item.name}
            >
              {item.name}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default Select;
