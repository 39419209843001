import React, { useEffect, Fragment, useState } from 'react';
import { Content, Footer, Header } from './index';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';

import FloatingFooter from './Footer/FloatingFooter';

import { useUser } from 'contexts/UserContext';
import { PlatformMessageProvider, usePlatformMessage } from 'contexts/PlatformMessageContext';
import { CompaniesProfileProvider, useProfileCompanies } from 'contexts/CompaniesProfileContext';
import { StaticPageListProvider } from 'contexts/StaticPageListContext';
import { MenuProvider, useMenu } from 'contexts/MenuContext';

import { Auth } from 'services/api';
import { User, RoleEnum } from 'services/utils';

import { slide as Menu } from 'react-burger-menu';
import { Power } from 'react-bootstrap-icons';
import Button from 'components/Button/Button';
import UserBanner from 'components/Misc/UserBanner';
import CompanyBanner from 'components/Misc/CompanyBanner';

import * as Constant from 'config/constants';
var logoDir = Constant.getAssetsDir();

function Layout(props) {
  return (
    <CompaniesProfileProvider>
      <PlatformMessageProvider>
        <StaticPageListProvider>
          <MenuProvider>
            <LayoutContent location={props.location} />
          </MenuProvider>
        </StaticPageListProvider>
      </PlatformMessageProvider>
    </CompaniesProfileProvider>
  );
}

const LayoutContent = (props) => {
  const { platformMessage } = usePlatformMessage();
  const [user, userLoading] = useUser();
  const [menuOpen, setCurrentMenuOpen] = useMenu();
  const [menu, setMenu] = useState([]);
  const { companies } = useProfileCompanies();
  const [companiesList, setCompaniesList] = useState();

  useEffect(() => {
    if (user) {
      setMenu(user.role === RoleEnum.LENDER ? User.menu.lender : User.menu.projectOwner);
    }
    if (companies) {
      setCompaniesList(companies);
    }
  }, [companies, user]);

  const displayUserBanner =
    user && user.validProfile !== undefined && !user.validProfile && props.location.pathname !== '/'
      ? props.location.pathname === '/' && (!platformMessage || !platformMessage.visible)
        ? true
        : props.location.pathname !== '/user/profile'
      : false;

  const displayCompanyBanner =
    user && companiesList && user.role === RoleEnum.PROJECTOWNER && props.location.pathname !== '/'
      ? props.location.pathname === '/' && (!platformMessage || !platformMessage.visible)
        ? true
        : props.location.pathname !== '/user/profile'
      : false;

  return (
    <Fragment>
      {!userLoading && (
        <>
          <Menu
            right
            pageWrapId={'content'}
            customBurgerIcon={false}
            customCrossIcon={
              <>
                <svg
                  version="1.1"
                  id="Capa_1"
                  x="0px"
                  y="0px"
                  height="25px"
                  viewBox="0 0 612 612"
                  width="25px"
                >
                  <g id="cross">
                    <g>
                      <polygon points="612,36.004 576.521,0.603 306,270.608 35.478,0.603 0,36.004 270.522,306.011 0,575.997 35.478,611.397      306,341.411 576.521,611.397 612,575.997 341.459,306.011    " />
                    </g>
                  </g>
                </svg>
              </>
            }
            isOpen={menuOpen}
            onClose={() => {
              setCurrentMenuOpen(false);
            }}
          >
            <Nav className="flex-column">
              <Nav.Link
                as={Link}
                to="/projects"
                className="nav-menu-item d-lg-none"
                onClick={() => {
                  setCurrentMenuOpen(false);
                }}
              >
                Prêter
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/pre_project"
                className="nav-menu-item d-lg-none"
                onClick={() => {
                  setCurrentMenuOpen(false);
                }}
              >
                Emprunter
              </Nav.Link>
              {user && (user.role === RoleEnum.LENDER || user.role === RoleEnum.PROJECTOWNER) && (
                <>
                  <NavDropdown.Divider className="d-lg-none" />
                  {menu.map((item, index) => (
                    <Nav.Link
                      key={item.key}
                      as={Link}
                      to={`/user/${item.key}`}
                      className="nav-user-menu-item"
                      href={`#${index}`}
                      onClick={() => {
                        setCurrentMenuOpen(false);
                      }}
                    >
                      {item.icon}&nbsp;&nbsp;{item.wording}
                    </Nav.Link>
                  ))}
                </>
              )}
              {user ? (
                <>
                  {(user.role === RoleEnum.LENDER || user.role === RoleEnum.PROJECTOWNER) && (
                    <NavDropdown.Divider />
                  )}
                  <Button className="button-disconnect nav-user-menu-item" onClick={Auth.Logout}>
                    <Power size={24} />
                    &nbsp;&nbsp;Se déconnecter
                  </Button>
                </>
              ) : (
                <>
                  <Nav.Link as={Link} to="/sign_in" alt="Connexion">
                    <Button
                      className="button button-green w-100 mr-0 ml-0"
                      onClick={() => {
                        setCurrentMenuOpen(false);
                      }}
                    >
                      Connexion
                    </Button>
                  </Nav.Link>
                  <Nav.Link as={Link} to="/sign_up" alt="Inscription">
                    <Button
                      className="button button-inscription w-100 mr-0 ml-0"
                      onClick={() => {
                        setCurrentMenuOpen(false);
                      }}
                    >
                      Inscription
                    </Button>
                  </Nav.Link>
                </>
              )}
            </Nav>
            <Nav.Item className="nav-menu-logo">
              <img src={`../../../icons/${logoDir}/KiwaiLogo.png`} alt="logo kiwaï" />
            </Nav.Item>
          </Menu>
          <Header />
          {displayUserBanner && <UserBanner />}
          {displayCompanyBanner &&
            companiesList.map((e) => (
              <CompanyBanner companyId={e} userBannerDisplayed={displayUserBanner} />
            ))}
          <Content banner={displayUserBanner} />
          {!Constant.isENR() && <FloatingFooter />}
          <Footer />
        </>
      )}
    </Fragment>
  );
};

export default Layout;
