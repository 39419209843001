import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { InfoCircle } from 'react-bootstrap-icons';

const SecondaryCase = (props) => {
  const popover = (
    <Popover id="popover-basic">
      <Popover.Content>{props.tooltip}</Popover.Content>
    </Popover>
  );

  return (
    <>
      <div
        className="synthese-bloc"
        style={{ backgroundColor: '#fff', color: '#000', borderRadius: '5px' }}
      >
        <Row>
          <Col xs={2} className="d-flex align-items-center justify-content-center">
            <>{props.icon}</>
          </Col>
          <Col>
            <small className="d-flex align-items-center">
              {props.title}
              {props.tooltip !== undefined && props.tooltip !== null && (
                <>
                  &nbsp;&nbsp;
                  <OverlayTrigger key="top" placement="top" overlay={popover}>
                    <InfoCircle size={12} />
                  </OverlayTrigger>
                </>
              )}
            </small>
            <div className="data">{props.data}</div>
            <small>{props.subTitle}</small>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SecondaryCase;
