import React from 'react';

import { FundProvider } from 'contexts/FundContext';

import PayIn from './PayIn';
import PayOut from './PayOut';
import { PlatforConfigProvider } from 'contexts/PlatformConfigContext';

const Fund = () => {
  return (
    <FundProvider>
      <PlatforConfigProvider>
        <PayIn />
        <PayOut />
      </PlatforConfigProvider>
    </FundProvider>
  );
};

export default Fund;
