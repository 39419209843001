import React from 'react';

import { useLoanTimeTable } from 'contexts/LoanTimeTableContext';

import CustomTable from 'components/Misc/CustomTable';
import Badge from 'components/Misc/Badge';

const LoanTimeTable = (props) => {
  const { loanTimeTable, loanTimeTableLoading, numberLoanTimeTable } = useLoanTimeTable();

  const rows = [
    { label: 'Echéance', name: 'rank' },
    { label: 'Date', name: 'dueDate', type: 'date' },
    { label: "Montant de l'échéance", name: 'dueAmount', type: 'amount' },
    { label: 'Part du capital', name: 'amortization', type: 'amount' },
    { label: 'Part des intêrets', name: 'interest', type: 'amount' },
    { label: 'Capital restant', name: 'newRemainingCapital', type: 'amount' },
    { label: 'Intêrets remboursés', name: 'interestReimbursed', type: 'amount' },
    { label: 'Capital remboursé', name: 'capitalReimbursed', type: 'amount' },
    { label: 'Etat', name: 'state' },
  ];

  const POrows = [
    { label: 'Echéance', name: 'rank' },
    { label: 'Date', name: 'dueDate', type: 'date' },
    { label: 'Montant à payer', name: 'dueAmount', type: 'amount' },
    { label: 'Part du capital', name: 'amortization', type: 'amount' },
    { label: 'Part des intêrets', name: 'interest', type: 'amount' },
    { label: 'Capital restant', name: 'newRemainingCapital', type: 'amount' },
    { label: 'Intêrets remboursés', name: 'interestReimbursed', type: 'amount' },
    { label: 'Capital remboursé', name: 'capitalReimbursed', type: 'amount' },
    { label: 'Frais de gestion sur CRD', name: 'managementFees', type: 'amount' },
    { label: 'Frais de garantie', name: 'warantyFees', type: 'amount' },
    { label: 'Etat', name: 'state' },
  ];

  const scopedSlots = {
    state: (data, key) => {
      return (
        <td style={{ width: '100px' }} key={'data_' + key}>
          <Badge color={data ? 'success' : 'secondary'}>{data ? 'Payée' : 'A venir'}</Badge>
        </td>
      );
    },
  };
  return (
    <>
      <CustomTable
        page={props.page}
        pageNumber={Math.ceil(numberLoanTimeTable / 10)}
        onClickPager={props.onClickPager}
        rows={props.po ? POrows : rows}
        data={loanTimeTable}
        numberItem={numberLoanTimeTable}
        loading={loanTimeTableLoading}
        scopedSlots={scopedSlots}
      />
    </>
  );
};

export default LoanTimeTable;
