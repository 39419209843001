import React, { useEffect, useState, useMemo, createContext, useContext } from 'react';

import { Lender } from 'services/api';

const TransactionsContext = createContext(undefined);

const TransactionsProvider = ({ children, transactionType, transactionState, page }) => {
  const [transactions, setTransactions] = useState(null);
  const [transactionsLoading, setTransactionsLoading] = useState(true);
  const [numberTransactions, setNumberTransactions] = useState(0);

  useEffect(() => {
    let isMounted = true;
    setTransactionsLoading(true);
    let filters = '';

    if (transactionState || transactionType) {
      filters =
        `&Filters=` +
        (transactionType && `transactionType@=*${transactionType},`) +
        (transactionState && `transactionState@=*${transactionState}`);
    }

    Lender.Transactions(`?Sorts=-createDate&Page=${page}&PageSize=10` + filters)
      .then((response) => {
        if (isMounted && response) {
          setTransactions(response.data.transactions);
          setNumberTransactions(response.data.transactionsCount);
          setTransactionsLoading(false);
        }
      })
      .catch(() => {
        if (isMounted) setTransactions(null);
        if (isMounted) setNumberTransactions(null);
        setTransactionsLoading(false);
      });

    return () => (isMounted = false);
  }, [transactionState, transactionType, page]);

  const data = useMemo(
    () => ({
      transactions,
      transactionsLoading,
      numberTransactions,
    }),
    [transactions, transactionsLoading, numberTransactions],
  );

  return <TransactionsContext.Provider value={data}>{children}</TransactionsContext.Provider>;
};

const useTransactions = () => {
  const context = useContext(TransactionsContext);
  if (context === undefined) {
    throw new Error('useTransaction can only be used inside TransactionsProvider');
  }
  return context;
};

export { TransactionsProvider, useTransactions };
