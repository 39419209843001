import React from 'react';
import { Link } from 'react-router-dom';

import { Flower3 } from 'react-bootstrap-icons';

import { useWallet } from 'contexts/WalletContext';
import Nav from 'react-bootstrap/Nav';
import { fromCents } from 'services/utils';

const Wallet = () => {
  const { wallet, walletLoading } = useWallet();

  return (
    <Nav.Link as={Link} href="#" to="/user/fund" className="header-amount">
      Solde&nbsp;:&nbsp;
      {walletLoading ? (
        <Flower3 size={24} className="spin" />
      ) : (
        <>{`${fromCents(wallet).toLocaleString('fr-FR')}€`}</>
      )}
    </Nav.Link>
  );
};

export default React.memo(Wallet);
