function readEnv(name, required = false, fallback = null) {
  if (process.env[name]) {
    return process.env[name];
  }

  if (required) {
    throw new Error(`Environment variable "${name}" is required.`);
  }

  return fallback;
}

const env = {
  /**
   * Base URL of the API
   */
  apiBaseUrl: readEnv('REACT_APP_API_BASE_URL', true),
  enrDomainName: readEnv('REACT_APP_FO_ENR_DOMAIN_NAME', true),
  normandyDomainName: readEnv('REACT_APP_FO_NORMANDY_DOMAIN_NAME', true),
  serverProtocol: readEnv('REACT_APP_SERVER_PROTOCOL', true),
};

export default env;
