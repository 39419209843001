import axios from 'axios';

export async function getCountry() {
  return await axios.get('Country').then((response) => {
    return response.data;
  });
}

export async function getResidenceCountry() {
  return await axios.get('Country/ResidenceCountries').then((response) => {
    return response.data;
  });
}

export async function getDept() {
  return await axios.get('Department').then((response) => {
    return response.data;
  });
}
