import axios from 'axios';

export async function Indicators() {
  return await axios.get('Indicators').then((response) => {
    return response.data;
  });
}
export async function RatesIndicators() {
  return await axios.get('Indicators/rates').then((response) => {
    return response.data;
  });
}
export async function LegalIndicators() {
  return await axios.get('Indicators/legal').then((response) => {
    return response.data;
  });
}
