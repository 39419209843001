import React from 'react';

import { useTransactions } from 'contexts/TransactionsContext';

import CustomTable from 'components/Misc/CustomTable';
import Badge from 'components/Misc/Badge';

const TransactionsTable = (props) => {
  const { numberTransactions, transactionsLoading, transactions } = useTransactions();

  const scopedSlots = {
    transactionState: (data, className, key) => {
      return (
        <td className={className + '-td'} key={'data_' + key}>
          <Badge
            color={data === 'En attente' ? 'secondary' : data === 'Réussi' ? 'success' : 'danger'}
          >
            {data}
          </Badge>
        </td>
      );
    },
  };

  return (
    <>
      <CustomTable
        page={props.page}
        pageNumber={Math.ceil(numberTransactions / 10)}
        onClickPager={props.onClickPager}
        rows={props.rows}
        data={transactions}
        numberItem={numberTransactions}
        loading={transactionsLoading}
        className="history"
        scopedSlots={scopedSlots}
      />
    </>
  );
};

export default TransactionsTable;
