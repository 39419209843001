export function AlertType(value) {
  if (value === 0 || value === 1 || value === 2) {
    return 'warning';
  } else if (value === 4) {
    return 'success';
  } else if (value === 8 || value === 16) {
    return 'danger';
  }
}

export function ValidationPendingOrSuccess(value) {
  return value === 2 || value === 4;
}
