import React, { useEffect, useState, useMemo, createContext, useContext } from 'react';

import { ProjectOwner } from 'services/api';

const CompaniesContext = createContext();

const CompaniesProvider = ({ children, page }) => {
  const [companies, setCompanies] = useState(null);
  const [companiesLoading, setCompaniesLoading] = useState(true);
  const [numberCompanies, setNumberCompanies] = useState(0);

  useEffect(() => {
    let isMounted = true;
    setCompaniesLoading(true);

    ProjectOwner.Companies(`?Page=${page}&PageSize=10`)
      .then((response) => {
        if (isMounted && response) {
          setCompanies(response.data.companies);
          setNumberCompanies(response.data.companiesCount);
          setCompaniesLoading(false);
        }
      })
      .catch(() => {
        if (isMounted) setCompanies(null);
        if (isMounted) setNumberCompanies(null);
        setCompaniesLoading(false);
      });

    return () => (isMounted = false);
  }, [page]);

  const data = useMemo(
    () => ({
      companies,
      companiesLoading,
      numberCompanies,
    }),
    [companies, companiesLoading, numberCompanies],
  );

  return <CompaniesContext.Provider value={data}>{children}</CompaniesContext.Provider>;
};

const useCompanies = () => {
  const context = useContext(CompaniesContext);
  if (context === undefined) {
    throw new Error('useCompanies can only be used inside CompaniesProvider');
  }
  return context;
};

export { CompaniesProvider, useCompanies };
