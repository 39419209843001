import React from 'react';
import Button from 'components/Button/Button';
import { useHistory } from 'react-router-dom';

import { useCompanies } from 'contexts/CompaniesContext';

import CustomTable from 'components/Misc/CustomTable';
import Badge from 'components/Misc/Badge';

const ContributionsHistoryTable = (props) => {
  const { companies, companiesLoading, numberCompanies } = useCompanies();
  const history = useHistory();

  const rows = [
    { label: 'Nom', name: 'companyName' },
    { label: 'Etat du compte entreprise', name: 'validProfile' },
    { label: '', name: 'id' },
  ];

  const scopedSlots = {
    validProfile: (data, className, key) => {
      return (
        <td className={className + '-td'} key={'data_' + key}>
          <Badge color={data ? 'success' : 'warning'}>{data ? 'Validé' : 'Non validé'}</Badge>
        </td>
      );
    },
    id: (data, className, key) => {
      return (
        <td className={className + '-td'} key={'data_' + key}>
          <Button
            onClick={() => history.push(`/user/companies/${data}`)}
            alt="Modifier"
            className="text-center button button-green"
          >
            Modifier
          </Button>
        </td>
      );
    },
  };

  return (
    <>
      <CustomTable
        page={props.page}
        pageNumber={Math.ceil(numberCompanies / 10)}
        onClickPager={props.onClickPager}
        rows={rows}
        data={companies}
        numberItem={numberCompanies}
        loading={companiesLoading}
        className="history"
        scopedSlots={scopedSlots}
      />
    </>
  );
};

export default ContributionsHistoryTable;
