export const str2bool = (value) => {
  if (value && typeof value === 'string') {
    if (value.toLowerCase() === 'true') return true;
    if (value.toLowerCase() === 'false') return false;
  }
  return value;
};

export const camelize = (str) => {
  if (str == str.match(/(\b[A-Z][A-Z]+|\b[A-Z]\b)/g)) {
    return str.toLowerCase();
  }

  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
};

export const percentageTo360 = (percentage, total) => {
  return (percentage * 360) / total;
};

export const removeDiacriticsAndSpecials = (str) => {
  str = slugify(str);
  str = str.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<> ]/gi, '-');
  return str;
};

const slugify = (str) => {
  const accentsMap = {
    a: 'á|à|ã|â|ä|À|Á|Ã|Â|Ä',
    e: 'é|è|ê|ë|É|È|Ê|Ë',
    i: 'í|ì|î|ï|Í|Ì|Î|Ï',
    o: 'ó|ò|ô|õ|ö|Ó|Ò|Ô|Õ|Ö',
    u: 'ú|ù|û|ü|Ú|Ù|Û|Ü',
    c: 'ç|Ç',
    n: 'ñ|Ñ',
  };

  return Object.keys(accentsMap).reduce(
    (acc, cur) => acc.replace(new RegExp(accentsMap[cur], 'g'), cur),
    str,
  );
};
