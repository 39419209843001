import React, { useEffect, useState, useMemo, createContext, useContext } from 'react';

import { ProjectOwner } from 'services/api';

const ProjectsPOContext = createContext();

const ProjectsPOProvider = ({ children, page }) => {
  const [projects, setProjects] = useState(null);
  const [projectsLoading, setProjectsLoading] = useState(true);
  const [numberProjects, setNumberProjects] = useState(0);

  useEffect(() => {
    let isMounted = true;
    setProjectsLoading(true);

    ProjectOwner.PublishedProjects(`?Page=${page}&PageSize=3`)
      .then((response) => {
        if (isMounted) {
          setProjects(response.data.projects);
          setNumberProjects(response.data.projectsCount);
          setProjectsLoading(false);
        }
      })
      .catch(() => {
        if (isMounted) setProjects(null);
        if (isMounted) setNumberProjects(null);
        setProjectsLoading(false);
      });

    return () => (isMounted = false);
  }, [page]);

  const data = useMemo(
    () => ({
      projects,
      projectsLoading,
      numberProjects,
    }),
    [projects, projectsLoading, numberProjects],
  );

  return <ProjectsPOContext.Provider value={data}>{children}</ProjectsPOContext.Provider>;
};

const useProjectsPO = () => {
  const context = useContext(ProjectsPOContext);
  if (context === undefined) {
    throw new Error('useProjectsPO can only be used inside ProjectsPOProvider');
  }
  return context;
};

export { ProjectsPOProvider, useProjectsPO };
