import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

const Progression = (props) => {
  const popover = (
    <Popover id="popover-basic">
      <Popover.Content>
        {props.forLoan ? 'Avancement du remboursement du prêt' : 'Avancement de la collecte'}
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger key="top" placement="top" overlay={popover}>
      <div className="containerProgressbar m-0">
        <div
          className="overflow-progression"
          style={{
            width: `${100 - (props.pending / props.target) * 100}%`,
          }}
        ></div>
        <div className="progressBar-image"></div>
        <div className="progressBar-empty"></div>
      </div>
    </OverlayTrigger>
  );
};

export default Progression;
