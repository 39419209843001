import { getIn } from 'formik';

export const getError = (name, { touched, errors, status }) => {
  const fieldTouched = getIn(touched, name);
  const backendError = getIn(status, ['apiErrors', name]);
  const clientError = getIn(errors, name);

  if (clientError && fieldTouched) {
    return clientError;
  }

  if (backendError && !fieldTouched) {
    return backendError;
  }

  return undefined;
};
