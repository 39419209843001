import React from 'react';
import CustomTable from 'components/Misc/CustomTable';
import Badge from 'components/Misc/Badge';
import { useWarrantyLoanTimeTable } from 'contexts/WarrantyLoanTimeTableContext';

const WarrantyLoanTimeTable = (props) => {
  const { warrantyLoanTimeTable, warrantyLoanTimeTableLoading, numberWarrantyLoanTimeTable } =
    useWarrantyLoanTimeTable();
  const proRataStartDateLabel =
    parseInt(warrantyLoanTimeTable[0]?.rank) === 1
      ? 'Date de déblocage des fonds'
      : 'Date de la dernière échéance';
  const rows = [
    { label: 'Echéance', name: 'rank' },
    { label: proRataStartDateLabel, name: 'proRataStartDate', type: 'date' },
    { label: 'Date de la procédure de sauvegarde', name: 'proRataEndDate', type: 'date' },
    { label: 'Montant des intérêts échus', name: 'grossInterests', type: 'amount' },
    { label: 'Capital à rembourser', name: 'remainingCapital', type: 'amount' },
  ];

  return (
    <>
      <CustomTable
        page={props.page}
        pageNumber={1}
        onClickPager={props.onClickPager}
        rows={rows}
        data={warrantyLoanTimeTable}
        numberItem={1}
        loading={warrantyLoanTimeTableLoading}
      />
    </>
  );
};

export default WarrantyLoanTimeTable;
