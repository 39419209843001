import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import * as Constant from 'config/constants';

var assetsDir = Constant.getAssetsDir();

const Loading = (props) => {
  return (
    <>
      <Row className={`loading-container ${props.small && 'small'}`}>
        <Col className="text-center">
          <img src={`../../../icons/${assetsDir}/loader.svg`} className="loading-logo" alt="" />
        </Col>
      </Row>
    </>
  );
};

export default Loading;
