import React from 'react';
import { Auth } from 'services/api';
import { Profile } from 'views/SecuredArea/User/Profile';
import LenderDashboard from 'views/SecuredArea/User/Dashboard/LenderDashboard';
import ProjectOwnerDashboard from 'views/SecuredArea/User/Dashboard/ProjectOwnerDashboard';
import Fund from 'views/SecuredArea/User/Fund/Fund';
import OptIn from 'views/SecuredArea/User/OptIn';
import Transactions from 'views/SecuredArea/User/Transactions';
import Documents from 'views/SecuredArea/User/Documents';
import Contributions from 'views/SecuredArea/User/Contributions/Contributions';
import ContributionsHistory from 'views/SecuredArea/User/ContributionsHistory/ContributionsHistory';
import Companies from 'views/SecuredArea/User/Companies/Companies';
import ProjectsPOItem from 'views/SecuredArea/User/Projects/ProjectsPOItem';

import {
  ClipboardData,
  PersonCircle,
  GearWideConnected,
  CardChecklist,
  Files,
  CreditCard,
  BagCheck,
  Building,
  JournalRichtext,
} from 'react-bootstrap-icons';

export const ROLES = {
  ADMIN: 'ROLE_ADMIN',
  PROJECTOWNER: 'ROLE_PO',
  LENDER: 'ROLE_LENDER',
};

const iconSize = 24;

export const menu = {
  lender: [
    {
      key: 'dashboard',
      icon: <ClipboardData size={iconSize} />,
      wording: 'Tableau de bord',
      component: LenderDashboard,
    },
    {
      key: 'profile',
      icon: <PersonCircle size={iconSize} />,
      wording: 'Profil',
      component: Profile,
    },
    {
      key: 'contributions',
      icon: <BagCheck size={iconSize} />,
      wording: 'Contributions',
      component: Contributions,
    },
    {
      key: 'setting',
      icon: <GearWideConnected size={iconSize} />,
      wording: 'Paramètres',
      component: OptIn,
    },
    {
      key: 'history',
      icon: <CardChecklist size={iconSize} />,
      wording: 'Historique des transactions',
      component: Transactions,
    },
    {
      key: 'documents',
      icon: <Files size={iconSize} />,
      wording: 'Documents',
      component: Documents,
    },
    {
      key: 'fund',
      icon: <CreditCard size={iconSize} />,
      wording: 'Alimenter/débiter mon compte',
      component: Fund,
    },
  ],
  projectOwner: [
    {
      key: 'dashboard',
      icon: <ClipboardData size={iconSize} />,
      wording: 'Tableau de bord',
      component: ProjectOwnerDashboard,
    },
    {
      key: 'profile',
      icon: <PersonCircle size={iconSize} />,
      wording: 'Profil',
      component: Profile,
    },
    {
      key: 'projects',
      icon: <JournalRichtext size={iconSize} />,
      wording: 'Projets',
      component: ProjectsPOItem,
    },
    {
      key: 'contributionsHistory',
      icon: <CardChecklist size={iconSize} />,
      wording: 'Historique des contributions',
      component: ContributionsHistory,
    },
    {
      key: 'companies',
      icon: <Building size={iconSize} />,
      wording: 'Entreprises',
      component: Companies,
    },
  ],
};

export function isRole(user) {
  return user.role;
}

export function isAdmin(user) {
  return user.role === ROLES.ADMIN;
}

export function isLogin() {
  const user = Auth.GetCurrentUser();
  if (user) return true;
  else return false;
}
