import React, { useEffect, useState, useMemo, createContext, useContext } from 'react';

import { ProjectOwner } from 'services/api';

const ContributionsHistoryContext = createContext();

const ContributionsHistoryProvider = ({ children, page }) => {
  const [contributionsHistory, setContributionsHistory] = useState(null);
  const [contributionsHistoryLoading, setContributionsHistoryLoading] = useState(true);
  const [numberContributionsHistory, setNumberContributionsHistory] = useState(0);

  useEffect(() => {
    let isMounted = true;
    setContributionsHistoryLoading(true);

    ProjectOwner.ContributionsHistory(`?Page=${page}&PageSize=10`)
      .then((response) => {
        if (isMounted && response) {
          setContributionsHistory(response.data.contributions);
          setNumberContributionsHistory(response.data.contributionCount);
          setContributionsHistoryLoading(false);
        }
      })
      .catch(() => {
        if (isMounted) setContributionsHistory(null);
        if (isMounted) setNumberContributionsHistory(null);
        setContributionsHistoryLoading(false);
      });

    return () => (isMounted = false);
  }, [page]);

  const data = useMemo(
    () => ({
      contributionsHistory,
      contributionsHistoryLoading,
      numberContributionsHistory,
    }),
    [contributionsHistory, contributionsHistoryLoading, numberContributionsHistory],
  );

  return (
    <ContributionsHistoryContext.Provider value={data}>
      {children}
    </ContributionsHistoryContext.Provider>
  );
};

const useConstributionsHistory = () => {
  const context = useContext(ContributionsHistoryContext);
  if (context === undefined) {
    throw new Error(
      'useConstributionsHistory can only be used inside ContributionsHistoryProvider',
    );
  }
  return context;
};

export { ContributionsHistoryProvider, useConstributionsHistory };
