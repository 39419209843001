import React, { useEffect, useState, useMemo, createContext, useContext } from 'react';

import { Lender } from 'services/api';

const IFUContext = createContext(undefined);

const IFUsProvider = ({ children, page }) => {
  const [documents, setDocuments] = useState(null);
  const [documentsLoading, setDocumentsLoading] = useState(true);
  const [numberDocuments, setNumberDocuments] = useState(0);

  useEffect(() => {
    let isMounted = true;
    setDocumentsLoading(true);

    Lender.IFUDocuments(`?Page=${page}&PageSize=10`)
      .then((response) => {
        if (isMounted && response) {
          setDocuments(response.data.documents);
          setNumberDocuments(response.data.count);
          setDocumentsLoading(false);
        }
      })
      .catch(() => {
        if (isMounted) setDocuments(null);
        if (isMounted) setNumberDocuments(null);
        setDocumentsLoading(false);
      });

    return () => (isMounted = false);
  }, [page]);

  const data = useMemo(
    () => ({
      documents,
      documentsLoading,
      numberDocuments,
    }),
    [documents, documentsLoading, numberDocuments],
  );

  return <IFUContext.Provider value={data}>{children}</IFUContext.Provider>;
};

const useIFU = () => {
  const context = useContext(IFUContext);
  if (context === undefined) {
    throw new Error('useIFU can only be used inside IFUsProvider');
  }
  return context;
};

export { IFUsProvider, useIFU };
