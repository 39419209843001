import React from 'react';
import { FileEarmarkImage, FileEarmark } from 'react-bootstrap-icons';

export function check(fileType) {
  if (!fileType) {
    return false;
  }
  var result = false;
  const allowedFiles = ['pdf', 'jpeg', 'png', 'jpg'];
  for (var i = 0; i < allowedFiles.length; i++) {
    if (fileType.includes(allowedFiles[i])) {
      result = true;
      break;
    } else {
      result = false;
    }
  }
  return result;
}

export function determineFile(fileType) {
  const str = fileType.split('/');
  if (str[1] === 'png' || str[1] === 'jpeg' || str[1] === 'jpg') {
    return <FileEarmarkImage size={24} />;
  } else {
    return <FileEarmark size={24} />;
  }
}
