import axios from 'axios';
import * as fileSaver from 'file-saver';
import * as base64ToBlob from 'b64-to-blob';

export async function GetContract(id) {
  await axios.get(`Loan/${id}/Contract`).then((response) => {
    var base64 = response.data;
    // generate a blob, then a file and then save the file.
    const blob = base64ToBlob(base64, 'application/pdf');
    const file = new File([blob], 'contrat.pdf', { type: 'data:application/pdf;base64' });
    fileSaver.saveAs(file);
  });
}
