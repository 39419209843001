import axios from 'axios';

export async function GetCategory() {
  return await axios.get('FAQCategory/List').then((response) => {
    return response.data;
  });
}

export async function GetAll() {
  return await axios.get('FAQ').then((response) => {
    return response.data;
  });
}
