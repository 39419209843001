import React, { useEffect, useMemo, createContext, useState, useContext } from 'react';
import { StaticPage } from 'services/api';
import { ScopeEnum } from 'services/utils';
import * as Constant from 'config/constants';

const StaticPageListContext = createContext(undefined);

const StaticPageListProvider = ({ children }) => {
  const [staticPageList, setStaticPageList] = useState();
  useEffect(() => {
    let isMounted = true;
    StaticPage.GetScope(Constant.isENR() ? ScopeEnum.ENR : ScopeEnum.Normandy)
      .then((data) => {
        if (isMounted) setStaticPageList(data);
      })
      .catch(() => {
        if (isMounted) setStaticPageList([]);
      });
    return () => (isMounted = false);
  }, []);

  const data = useMemo(
    () => ({
      staticPageList,
    }),
    [staticPageList],
  );
  return <StaticPageListContext.Provider value={data}>{children}</StaticPageListContext.Provider>;
};

const useStaticPageList = () => {
  const context = useContext(StaticPageListContext);
  if (context === undefined) {
    throw new Error('useStaticPageList can only be used inside StaticPageListProvider');
  }
  return context;
};

export { StaticPageListProvider, useStaticPageList };
