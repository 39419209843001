import { ScopeEnum } from 'services/utils';
import env from 'config';

export const isENR = () => {
  var baseUrl = window.location.host;
  return env.enrDomainName === baseUrl;
};

export const GetScope = () => {
  return isENR() ? ScopeEnum.ENR : ScopeEnum.Normandy;
};

export const getAssetsDir = () => {
  return `${isENR() ? 'enr' : 'normandy'}`;
};

export const getAssetsDirNegative = () => {
  return `${!isENR() ? 'enr' : 'normandy'}`;
};

export const PrimaryColor = isENR() ? '#09b2af' : '#81c640';
export const SecondaryColor = isENR() ? '#252C51' : '#5b8f2b';
export const SecondaryDashboardColor = isENR() ? '#0A7A8C' : '#5b8f2b';
export const Platform =
  env.serverProtocol + '://' + (isENR() ? env.enrDomainName : env.normandyDomainName);
export const PlatformNegative =
  env.serverProtocol + '://' + (isENR() ? env.normandyDomainName : env.enrDomainName);
