import { fileType } from '../helpers';
import IBAN from 'iban';

export function File(value, required) {
  if (!value && required) {
    return 'Ce champ est requis';
  } else if (value && value.size >= 14000000) {
    return 'La taille du fichier ne doit pas excéder 14 MO';
  } else if (value && !fileType.check(value.type)) {
    return "Ce format de fichier n'est pas autorisé (les formats autorisés sont jpg, jpeg, png, pdf)";
  }
  return false;
}

export function Iban(value) {
  return IBAN.isValid(value);
}

export function Bic(value) {
  return !(value.length < 5 || value.length > 12);
}
