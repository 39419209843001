import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import ProjectsPOList from './ProjectsPOList';

import { ProjectsPOProvider } from 'contexts/ProjectsPOContext';
import { PlatforConfigProvider } from 'contexts/PlatformConfigContext';

const ContributionsItem = () => {
  const [page, setPage] = useState(1);

  const onPaging = (page) => {
    setPage(page);
  };

  return (
    <>
      <Row className="text-uppercase green-text pb-2 pt-2">
        <Col md={12}>Mes Projets</Col>
      </Row>
      <ProjectsPOProvider page={page}>
        <PlatforConfigProvider>
          <ProjectsPOList page={page} onClickPager={onPaging} />
        </PlatforConfigProvider>
      </ProjectsPOProvider>
    </>
  );
};

export default ContributionsItem;
