import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { fileType, kyc } from 'helpers';
import { Toast } from 'services/utils';
import { User } from 'services/api';
import { useProfile } from 'contexts/ProfileContext';
import { useUser } from 'contexts/UserContext';
import { error } from 'helpers';

import Button from 'components/Button/Button';
import File from 'components/Input/File';
import AlertBanner from 'components/Misc/AlertBanner';
import Label from 'components/Misc/Label';
import { CheckCircleFill } from 'react-bootstrap-icons';

const ProofIdentity = () => {
  const [errorGlobal, setErrorGlobal] = useState(false);

  const { profile = [], profileLoading, setCurrentProfile } = useProfile();
  const [user] = useUser();

  const kycState = (value) => {
    switch (value) {
      case 2:
        return 'Vos documents sont en cours de validation';
      case 4:
        return 'Vos documents ont été validés avec succès !';
      case 8:
      case 16:
        return 'Vos documents ont été refusés';
      default:
        return 'Aucun document envoyé';
    }
  };

  const onSubmit = (values, { resetForm, setSubmitting }) => {
    setErrorGlobal(null);
    setSubmitting(true);
    var formData = new FormData();
    formData.append('Recto', values.Recto, values.Recto.name);
    if (values.Verso && values.Verso.name && values.Verso.name !== '' && values.Verso.size > 0) {
      if (values.Recto.size + values.Verso.size >= 14000000) {
        setSubmitting(false);
        setErrorGlobal("L'ensemble des fichiers doit être inférieur à 14Mo.");
        Toast.error(
          "Une erreur est survenue lors de l'envoi du formulaire. Veuillez vérifier votre saisie.",
        );
      } else {
        formData.append('Verso', values.Verso, values.Verso.name);
      }
    }
    User.PutKYC(user.role, formData)
      .then((response) => {
        setCurrentProfile(response.data);
        Toast.success('Enregistrement effectué avec succés !');
      })
      .catch((data) => {
        switch (data.response.status) {
          case 429:
            break;
          case 400:
            Toast.error("Une erreur est survenue lors de l'envoi du formulaire");
            setErrorGlobal(data.response.data.message);
            break;
          default:
            Toast.error(
              "Une erreur est survenue lors de l'envoi du formulaire. Veuillez réessayer ultérieurement.",
            );
            break;
        }
        setSubmitting(false);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const initialValues = {
    Recto: null,
    Verso: null,
  };

  const validationSchema = Yup.object().shape({
    Recto: Yup.mixed()
      .test(
        'fileRequired',
        'Vous devez insérer au moins un document dans le champ recto',
        (value) => {
          return value !== null && value !== undefined;
        },
      )
      .test(
        'fileType',
        "Ce format de fichier n'est pas autorisé (les formats autorisés sont jpg, jpeg, png, pdf)",
        (value) => {
          return value && fileType.check(value.type);
        },
      )
      .test('fileSize', 'La taille du fichier ne doit pas excéder 14mo', (value) => {
        return value && value.size <= 14000000;
      }),
    Verso: Yup.mixed()
      .test(
        'fileType',
        "Ce format de fichier n'est pas autorisé (les formats autorisés sont jpg, jpeg, png, pdf)",
        (value) => {
          if (!value) return true;
          return value && fileType.check(value.type);
        },
      )
      .test('fileSize', 'La taille du fichier ne doit pas excéder 14mo', (value) => {
        if (!value) return true;
        return value && value.size <= 14000000;
      }),
  });

  return (
    !profileLoading && (
      <>
        <Row className="text-uppercase green-text pb-2 pt-2">
          <Col md={12}>Mon justificatif d'identité</Col>
        </Row>
        {kyc.ValidationPendingOrSuccess(profile.kycState) ? (
          <div className="bloc-kiwai" style={{ paddingBottom: '0' }}>
            <AlertBanner
              variant={kyc.AlertType(profile.kycState)}
              icon={profile.kycState === 4 ? <CheckCircleFill size={18} /> : null}
            >
              {kycState(profile.kycState)}
            </AlertBanner>
          </div>
        ) : (
          <Formik
            enableReinitialize
            onSubmit={onSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
          >
            {(props) => {
              const { setFieldValue, errors, touched, status, isSubmitting } = props;
              const errorsRecto = error.getError('Recto', { touched, errors, status });
              const errorsVerso = error.getError('Verso', { touched, errors, status });
              return (
                (profile.kycState === 0 ||
                  profile.kycState === 1 ||
                  profile.kycState === 8 ||
                  profile.kycState === 16) && (
                  <Form className="bloc-kiwai">
                    <AlertBanner variant={kyc.AlertType(profile.kycState)}>
                      {kycState(profile.kycState)}
                    </AlertBanner>
                    <Row className="pt-3">
                      <Col>
                        <small>
                          Insérer ici l'un des documents en cours de validité suivants : carte
                          nationale d'identité, passeport ou permis de conduire
                        </small>
                      </Col>
                    </Row>
                    <Row className="form-row">
                      <Col md={6}>
                        <Label
                          htmlFor="Recto"
                          text="Recto"
                          tooltip={'Format de document autorisé : jpg, jpeg, png ou pdf'}
                        />
                        <File
                          type="file"
                          name="Recto"
                          id="Recto"
                          className={errorsRecto ? 'error' : ''}
                          loading={isSubmitting}
                          onChange={(e) => {
                            if (e != null && e !== undefined) {
                              setFieldValue('Recto', e);
                            } else {
                              setFieldValue('Recto', null);
                            }
                          }}
                        />
                      </Col>
                      <Col md={6}>
                        <Label
                          htmlFor="Verso"
                          text="Verso"
                          tooltip={'Format de document autorisé : jpg, jpeg, png ou pdf'}
                        />
                        <File
                          type="file"
                          name="Verso"
                          id="Verso"
                          className={errorsVerso ? 'error' : ''}
                          loading={isSubmitting}
                          onChange={(e) => {
                            if (e != null && e !== undefined) {
                              setFieldValue('Verso', e);
                            } else {
                              setFieldValue('Verso', null);
                            }
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="form-row form-footer v-center">
                      <Col md={6}>
                        {errorsRecto ? (
                          <div
                            className={`error-message m-0 ${errorGlobal && 'position-relative'}`}
                          >
                            {errorsRecto}
                          </div>
                        ) : null}
                        {errorsVerso ? (
                          <div
                            className={`error-message m-0 ${errorGlobal && 'position-relative'}`}
                          >
                            {errorsVerso}
                          </div>
                        ) : null}
                        <div className="error-message m-0">{errorGlobal}</div>
                      </Col>
                      <Col className="text-right">
                        <Button
                          className="button button-green m-0 w-100 w-md-auto"
                          type="submit"
                          loading={isSubmitting}
                        >
                          Enregistrer
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )
              );
            }}
          </Formik>
        )}
      </>
    )
  );
};

export default ProofIdentity;
