import React, { useEffect, useState, useMemo, createContext, useContext } from 'react';

import { Lender } from 'services/api';

const LoansContext = createContext();

const LoansProvider = ({ children, sort, page }) => {
  const [loans, setLoans] = useState(null);
  const [loansLoading, setLoansLoading] = useState(true);
  const [numberLoans, setNumberLoans] = useState(0);

  useEffect(() => {
    let isMounted = true;
    setLoansLoading(true);

    Lender.Loans(`?Sorts=${sort}&Page=${page}&PageSize=3`)
      .then((response) => {
        if (isMounted && response) {
          setLoans(response.contributions);
          setNumberLoans(response.contributionsCount);
          setLoansLoading(false);
        }
      })
      .catch(() => {
        if (isMounted) setLoans(null);
        if (isMounted) setNumberLoans(null);
        setLoansLoading(false);
      });

    return () => (isMounted = false);
  }, [sort, page]);

  const data = useMemo(
    () => ({
      loans,
      loansLoading,
      numberLoans,
    }),
    [loans, loansLoading, numberLoans],
  );

  return <LoansContext.Provider value={data}>{children}</LoansContext.Provider>;
};

const useLoans = () => {
  const context = useContext(LoansContext);
  if (context === undefined) {
    throw new Error('useLoans can only be used inside LoansProvider');
  }
  return context;
};

export { LoansProvider, useLoans };
