import React from 'react';
import Table from 'react-bootstrap/Table';
import moment from 'moment';
import { fromCents } from 'services/utils';
import Pager from 'components/Misc/Pager';

import { Flower3 } from 'react-bootstrap-icons';

const CustomTable = (props) => {
  const format = (data, rows, key) => {
    switch (rows.type) {
      case 'amount':
        return (
          <td className={props.className + '-td text-right'} key={'data_' + key}>
            {data
              ? fromCents(data).toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' })
              : '0,00 €'}
          </td>
        );

      case 'date':
        return (
          <td className={props.className + '-td'} key={'data_' + key}>
            {moment(data.substring(0, 10)).format('DD/MM/YYYY')}
          </td>
        );

      default:
        return (
          <td className={props.className + '-td'} key={'data_' + key}>
            {data}
          </td>
        );
    }
  };

  return (
    <>
      <small className="d-block d-md-none">
        <em>Sur mobile, faites défiler les colonnes de droite à gauche</em>
      </small>
      <Table striped borderless={true} responsive="lg" className="history-table">
        <thead className={props.className + '-thead'}>
          <tr>
            {props.rows.map((rows, index) => {
              return (
                <th key={'rows_' + index} className={props.className + '-th'}>
                  {rows.label}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {!props.numberItem && props.loading ? (
            <tr>
              <td colSpan={props.rows.length} className="text-center font-italic">
                <Flower3 size={24} className={props.loading && ' spin'} />
                Chargement en cours...
              </td>
            </tr>
          ) : props.numberItem && props.numberItem > 0 ? (
            <>
              {props.data.map((o, index) => {
                return (
                  <tr key={'data_' + index}>
                    {props.rows.map((rows, index) => {
                      return props.scopedSlots && props.scopedSlots[rows.name]
                        ? props.scopedSlots[rows.name](o[rows.name], props.className, index)
                        : format(o[rows.name], rows, `data_${index}_${o}`);
                    })}
                  </tr>
                );
              })}
            </>
          ) : (
            <tr>
              <td colSpan={props.rows.length} className="text-center font-italic">
                Aucun élément...
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {props.pageNumber > 1 && (
        <Pager
          page={props.page}
          pageNumber={props.pageNumber}
          onClick={props.onClickPager}
          loading={props.loading}
        />
      )}
    </>
  );
};

export default CustomTable;
