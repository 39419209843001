import Noty from 'noty';

const show = (type, text, params) => {
  new Noty({
    type,
    text,
    ...params,
  }).show();
};

export function info(text, params) {
  return show('info', text, params);
}

export function warning(text, params) {
  return show('warning', text, params);
}

export function error(text, params) {
  return show('error', text, params);
}

export function success(text, params) {
  return show('success', text, params);
}
