import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';

import CustomModal from 'components/Misc/Modal';
import Label from 'components/Misc/Label';
import Button from '../../../../components/Button/Button';
import { useUser } from 'contexts/UserContext';

import { Toast, toCents } from 'services/utils';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { format, error } from 'helpers';

import { Lender } from 'services/api';
import { useFund } from 'contexts/FundContext';
import { useWallet } from 'contexts/WalletContext';

const Fund = () => {
  const [user] = useUser();
  const [fund] = useFund();
  const { wallet, refreshWallet } = useWallet();

  const [showPayOutModal, setShowPayOutModal] = useState(false);

  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .test(
        'is-decimal',
        'Veuillez renseigner un montant valide',
        (value) => (value + '').match(/^\d+\.\d{0,2}$/) || Number.isInteger(value),
      )
      .max(wallet, 'Le montant ne peut excéder le montant total de votre compte Kiwaï')
      .moreThan(0, 'Le montant ne peut être égal ou inférieur à 0€')
      .typeError('Veuillez renseigner un montant valide')
      .required('Ce champ est requis'),
  });

  const initialValues = {
    amount: null,
  };

  const onSubmit = (values, { resetForm, setSubmitting }) => {
    setSubmitting(true);
    Toast.success('Débit de votre compte Kiwaï en cours...');
    Lender.WithdrawWallet(toCents(values.amount))
      .then((response) => {
        if (response.data) {
          refreshWallet(wallet);
          setShowPayOutModal(false);
          Toast.success('Débit effectué avec succès !');
        } else {
          Toast.error('Une erreur est survenue. Veuillez réessayer ultérieurement.');
        }
      })
      .catch((data) => {
        switch (data.response.status) {
          case 429:
            break;
          case 400:
            Toast.error(
              "Une erreur est survenue lors de l'envoi du formulaire. Veuillez vérifier votre saisie.",
            );
            var apiErrors = {};
            Object.keys(data.response.data.errors).forEach(function (e) {
              apiErrors[format.camelize(e)] = data.response.data.errors[e];
            });
            resetForm({ values, status: { apiErrors } });
            break;
          default:
            Toast.error('Une erreur est survenue. Veuillez réessayer ultérieurement.');
            break;
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <Row className="text-uppercase green-text pb-2 pt-2">
        <Col md={12}>Débiter mon compte Kiwaï</Col>
      </Row>
      <div className="bloc-kiwai">
        {user && !wallet > 0 && (
          <Alert variant="warning" className="form-alert">
            <Alert.Heading>Votre compte Kiwaï n'est pas alimenté !</Alert.Heading>
            <p>Pour débiter votre compte, le solde disponible doit être positif</p>
          </Alert>
        )}
        <Row>
          <Col lg={4} className="balance text-center text-lg-left">
            <div className="form-label">IBAN</div>
            <div>{fund.bankAccount ? fund.bankAccount.iban : 'non disponible'}</div>
          </Col>
          <Col>
            <Row>
              <Col className="balance-text">
                Vous pouvez débiter tout ou partie de votre solde. L'argent sera transféré sur le
                compte bancaire renseigné sur votre profil et repris ci-contre.
              </Col>
            </Row>
            <br />
            <Row>
              <Col className="text-center">
                <Button
                  className="button button-red w-100 m-0 w-md-auto"
                  type="button"
                  onClick={() => setShowPayOutModal(true)}
                  disabled={!user || !user.validProfile || wallet === 0}
                >
                  Débiter mon compte
                </Button>
                <CustomModal
                  show={showPayOutModal}
                  onClose={() => setShowPayOutModal(!showPayOutModal)}
                  size="md"
                  title="Débiter mon compte"
                >
                  <Row>
                    <Col>
                      <Formik
                        initialValues={initialValues}
                        initialStatus={{ apiErrors: {} }}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                      >
                        {(props) => {
                          const { touched, errors, status, isSubmitting } = props;
                          // Errors
                          const amountError = error.getError('amount', { touched, errors, status });
                          return (
                            <Form>
                              <Row>
                                <Col>
                                  <p>
                                    Lorsque vous cliquerez sur <strong>Débiter</strong>, le montant
                                    indiqué ci-dessous sera crédité sur votre compte bancaire dont
                                    vous avez renseigné les coordonnées dans votre profil.
                                  </p>
                                </Col>
                              </Row>
                              <Row className="form-row">
                                <Col>
                                  <Label htmlFor="amount" text="Montant" />
                                  <Field
                                    type="text"
                                    name="amount"
                                    id="amount"
                                    className={amountError ? 'error' : ''}
                                  />
                                  {amountError && (
                                    <div className="error-message position-relative">
                                      {amountError}
                                    </div>
                                  )}
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={12} md={6} className="text-right">
                                  <Button
                                    className="button button-green w-100 mr-0 ml-0"
                                    type="button"
                                    onClick={() => setShowPayOutModal(false)}
                                  >
                                    Annuler
                                  </Button>
                                </Col>
                                <Col xs={12} md={6} className="pt-2 pt-md-0">
                                  <Button
                                    className="button button-red w-100 mr-0 ml-0"
                                    loading={isSubmitting}
                                    type="submit"
                                  >
                                    Débiter
                                  </Button>
                                </Col>
                              </Row>
                            </Form>
                          );
                        }}
                      </Formik>
                    </Col>
                  </Row>
                </CustomModal>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Fund;
