import React, { useEffect, createContext, useState, useContext } from 'react';
import { DefaultProject } from 'services/api';

const PlatforConfigContext = createContext(undefined);

const PlatforConfigProvider = ({ children }) => {
  const [status, setStatus] = useState(null);
  useEffect(() => {
    let isMounted = true;
    DefaultProject.GetMangoPayServiceStatus()
      .then((data) => {
        if (isMounted) setStatus(data);
      })
      .catch((err) => {
        if (isMounted) setStatus(null);
      });
    return () => (isMounted = false);
  }, []);

  return <PlatforConfigContext.Provider value={status}>{children}</PlatforConfigContext.Provider>;
};

const usePlatforConfig = () => {
  const context = useContext(PlatforConfigContext);
  if (context === undefined) {
    throw new Error('usePlatforConfig can only be used inside PlatforConfigProvider');
  }
  return context;
};

export { PlatforConfigProvider, usePlatforConfig };
