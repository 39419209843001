import axios from 'axios';

export async function GetAll() {
  return await axios.get('StaticPage/Light').then((response) => {
    return response.data;
  });
}

export async function Get(adressName) {
  return await axios.get(`StaticPage/ByAdressName${adressName}`).then((response) => {
    return response.data;
  });
}

export async function GetScope(projectScopeId) {
  return await axios.get(`StaticPage/ByScope/${projectScopeId}`).then((response) => {
    return response.data;
  });
}
