import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import { Link } from 'react-router-dom';

import CustomModal from 'components/Misc/Modal';
import Label from 'components/Misc/Label';
import Button from '../../../../components/Button/Button';

import { Flower3 } from 'react-bootstrap-icons';

import { fromCents, Toast, toCents } from 'services/utils';
import { InfoCircle } from 'react-bootstrap-icons';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { format, error } from 'helpers';
import { Lender } from 'services/api';

import { useFund } from 'contexts/FundContext';
import { useWallet } from 'contexts/WalletContext';
import { useUser } from 'contexts/UserContext';

import { Wallet2 } from 'react-bootstrap-icons';
import { PlatforConfigProvider, usePlatforConfig } from 'contexts/PlatformConfigContext';

const validationSchema = Yup.object().shape({
  amount: Yup.number()
    .typeError('Veuillez renseigner un montant valide')
    .test(
      'is-decimal',
      'Veuillez renseigner un montant valide',
      (value) => (value + '').match(/^\d+\.\d{0,2}$/) || Number.isInteger(value),
    )
    .max(250000, "Le montant maximum d'alimentation par carte bancaire est de 2 500€")
    .required('Ce champ est requis'),
});

const Fund = () => {
  const [user] = useUser();
  const [fund] = useFund();
  const { wallet, walletLoading } = useWallet();
  const [showBankwireModal, setShowBankwireModal] = useState(false);
  const [showCardAvertissingModal, setShowCardAvertissingModal] = useState(false);
  const [showCardModal, setShowCardModal] = useState(false);
  const mangoPayServicestatus = usePlatforConfig();
  const initialValues = {
    amount: 1000,
  };

  const onSubmit = (values, { resetForm, setSubmitting }) => {
    setSubmitting(true);
    Lender.CardPayIn(toCents(values.amount))
      .then((response) => {
        setSubmitting(false);
        if (response !== '') {
          window.location.replace(response);
        } else {
          Toast.error('Une erreur est survenue. Veuillez réessayer ultérieurement.');
        }
      })
      .catch((data) => {
        switch (data.response.status) {
          case 429:
            break;
          case 400:
            Toast.error(
              "Une erreur est survenue lors de l'envoi du formulaire. Veuillez vérifier votre saisie.",
            );
            var apiErrors = {};
            data.response.data.errors
              ? Object.keys(data.response.data.errors).forEach(function (e) {
                  apiErrors[format.camelize(e)] = data.response.data.errors[e];
                })
              : data.response.data.attributes.forEach((e) => {
                  apiErrors[format.camelize(e)] = data.response.data.message;
                });
            resetForm({ values, status: { apiErrors } });
            break;
          default:
            Toast.error('Une erreur est survenue. Veuillez réessayer ultérieurement.');
            break;
        }
        setSubmitting(false);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <Row className="text-uppercase green-text pb-2 pt-2">
        <Col md={12}>Alimenter mon compte Kiwaï</Col>
      </Row>
      <div className="bloc-kiwai">
        {user && !user.validProfile && (
          <Alert variant="danger" className="form-alert">
            <Alert.Heading>Votre compte Kiwaï n'est pas encore validé !</Alert.Heading>
            <p>
              Pour alimenter votre compte, vous devez préalablement avoir complété entièrement votre
              profil, renseigné vos informations bancaires et chargé vos pièces justificatives
              d'identité. Rendez-vous dans le menu <Link to="/user/profile">Profil</Link> pour
              effectuer ces opérations.
            </p>
          </Alert>
        )}
        <div className="d-flex flex-column flex-md-row flex-sm-column align-items-center">
          <div className="col-md-5 col-lg-4 col-xl-5">
            <div className="balance-amount text-center-md d-flex justify-content-center w-md-50">
              <div className="d-flex justify-content-center align-items-center">
                <Wallet2 size={50} className="mx-3" />
              </div>
              {user && (
                <>
                  {!walletLoading ? (
                    <>{`${fromCents(wallet).toLocaleString('fr-FR')}€`}</>
                  ) : (
                    <Flower3 size={24} className="spin" />
                  )}
                </>
              )}
            </div>
            <Row>
              <Col className="text-center">
                <small>solde disponible</small>
              </Col>
            </Row>
          </div>
          <Col>
            <Row>
              <Col className="balance-text">
                Vous avez la possibilité d'alimenter votre compte Kiwaï par carte de crédit ou par
                virement bancaire.
              </Col>
            </Row>
            <br />
            <Row className="v-center">
              <Col xs={12} md={6} className="text-center">
                <Button
                  className={`button button-green ${
                    user && !user.validProfile ? 'disabled' : ''
                  } w-100 w-md-auto m-0 m-md-auto`}
                  type="button"
                  loading={null}
                  disabled={user && !user.validProfile}
                  onClick={() => setShowBankwireModal(true)}
                >
                  Alimenter mon compte par virement
                </Button>
                <CustomModal
                  show={showBankwireModal}
                  onClose={() => setShowBankwireModal(!showBankwireModal)}
                  size="lg"
                  title="Alimenter mon compte par virement"
                >
                  <Row className="v-center p-3">
                    <Col xs={12} md={1} className="text-center instruction-step">
                      1
                    </Col>
                    <Col xs={12} md={11}>
                      <p>Veuiller noter les références de virement indiquées ci-dessous</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={7} className="mx-auto p-3" style={{ border: '1px solid lightgray' }}>
                      <Row>
                        <Col xs={2} className="text-right">
                          <strong>BIC</strong>
                        </Col>
                        <Col className="text-left">
                          {fund.bankingAlias ? fund.bankingAlias.bic : 'non disponible'}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={2} className="text-right">
                          <strong>IBAN</strong>
                        </Col>
                        <Col className="text-left">
                          {fund.bankingAlias ? fund.bankingAlias.iban : 'non disponible'}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="v-center p-3">
                    <Col xs={12} md={1} className="text-center instruction-step">
                      2
                    </Col>
                    <Col xs={12} md={11}>
                      <p>
                        Effectuez votre virement en indiquant les références du virement depuis
                        votre banque en ligne ou votre agence bancaire
                      </p>
                    </Col>
                  </Row>
                  <Row className="v-center p-3">
                    <Col xs={12} md={1} className="text-center instruction-step">
                      3
                    </Col>
                    <Col xs={12} md={11}>
                      <p>
                        Vous serez informé par email lors de la réception de votre virement
                        <br />
                        Le délai moyen de réception des fonds est de <strong>2 jours ouvrés</strong>
                      </p>
                    </Col>
                  </Row>
                </CustomModal>
              </Col>
              <Col md={12} className="pt-2 text-danger" hidden={(mangoPayServicestatus ?? 0) === 0}>
                Le paiement par carte est actuellement indisponible. Nous vous invitons à réitérer
                l'opération ultérieurement ou à privilégier l'alimentation par virement bancaire.
              </Col>
              <Col xs={12} md={6} className="text-center pt-2 pt-md-0">
                <Button
                  className="button button-green-secondary w-100 w-md-auto m-0 m-md-auto"
                  type="button"
                  loading={null}
                  disabled={(user && !user.validProfile) || (mangoPayServicestatus ?? 0) !== 0}
                  onClick={() => setShowCardAvertissingModal(true)}
                >
                  Alimenter mon compte par carte
                </Button>
                <CustomModal
                  show={showCardAvertissingModal}
                  onClose={() => setShowCardAvertissingModal(!showCardAvertissingModal)}
                  size="lg"
                  title="Alimenter mon compte par carte"
                >
                  <Row className="v-center p-4">
                    <Col xs={12} md={1} className="text-green text-center">
                      <InfoCircle size={24} />
                    </Col>
                    <Col>
                      <p>
                        La somme créditée par ce mode impactera vos plafonds de carte bancaire.
                        <br />
                        <strong>
                          Privilégier l'alimentation de votre compte Kiwaï par virement !
                        </strong>
                      </p>
                    </Col>
                  </Row>
                  <Row className="pt-3">
                    <Col>
                      <p>
                        <em>
                          En cliquant sur "Continuer par carte", vous serez redirigé vers la page de
                          paiement de notre prestataire MangoPay. Le montant maximum de transaction
                          par carte bancaire est de 2 500€.
                        </em>
                      </p>
                    </Col>
                  </Row>
                  <Row className="pt-5">
                    <Col xs={12} md={6} className="text-right">
                      <Button
                        className="button button-green-secondary w-100 mr-0 ml-0"
                        type="button"
                        loading={null}
                        onClick={() => {
                          setShowCardAvertissingModal(false);
                          setShowCardModal(true);
                        }}
                      >
                        Continuer par carte
                      </Button>
                    </Col>
                    <Col xs={12} md={6} className="pt-2 pt-md-0">
                      <Button
                        className="button button-green w-100 mr-0 ml-0"
                        type="button"
                        loading={null}
                        disabled={user && !user.validProfile}
                        onClick={() => {
                          setShowCardAvertissingModal(false);
                          setShowBankwireModal(true);
                        }}
                      >
                        Alimenter mon compte par virement
                      </Button>
                    </Col>
                  </Row>
                </CustomModal>
                <CustomModal
                  show={showCardModal}
                  onClose={() => setShowCardModal(!showCardModal)}
                  size="xs"
                  title="Alimenter mon compte par carte"
                >
                  <Row>
                    <Col>
                      <Formik
                        initialValues={initialValues}
                        initialStatus={{ apiErrors: {} }}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                      >
                        {(props) => {
                          const { touched, errors, status, isSubmitting } = props;
                          // Errors
                          const amountError = error.getError('amount', { touched, errors, status });
                          return (
                            <Form>
                              <Row>
                                <Col>
                                  <p>
                                    Lorsque vous cliquerez sur <strong>Alimenter</strong> vous serez
                                    redirigé vers la page de paiement sécurisée
                                  </p>
                                </Col>
                              </Row>
                              <Row className="form-row">
                                <Col>
                                  <Label htmlFor="amount" text="Montant" required />
                                  <Field
                                    type="text"
                                    name="amount"
                                    id="amount"
                                    className={amountError ? 'error' : ''}
                                  />
                                  {amountError ? (
                                    <div className="error-message position-relative">
                                      {amountError}
                                    </div>
                                  ) : null}
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={12} md={6} className="text-right">
                                  <Button
                                    className="button button-red w-100 mr-0 ml-0"
                                    type="button"
                                    loading={null}
                                    onClick={() => setShowCardModal(false)}
                                  >
                                    Annuler
                                  </Button>
                                </Col>
                                <Col xs={12} md={6} className="pt-2 pt-md-0">
                                  <Button
                                    className="button button-green w-100 mr-0 ml-0"
                                    loading={isSubmitting}
                                    type="submit"
                                  >
                                    Alimenter
                                  </Button>
                                </Col>
                              </Row>
                            </Form>
                          );
                        }}
                      </Formik>
                    </Col>
                  </Row>
                </CustomModal>
              </Col>
            </Row>
          </Col>
        </div>
      </div>
    </>
  );
};

export default Fund;
