import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';

import { Lender } from 'services/api';
import { Toast } from 'services/utils';
import { fileType } from 'helpers';
import { validate, error } from 'helpers';
import { useProfile } from 'contexts/ProfileContext';

import Button from 'components/Button/Button';
import File from 'components/Input/File';
import AlertBanner from 'components/Misc/AlertBanner';
import Label from 'components/Misc/Label';
import { CheckCircleFill } from 'react-bootstrap-icons';

const BankDetails = () => {
  const [errorBankIdentityDocument, setErrorBankIdentityDocument] = useState();
  const { profile = [], profileLoading, setCurrentProfile } = useProfile();
  const [underModification, setUnderModification] = useState(false);
  //ribReaded: 0=no file, 1=rib reading success, 2=rib reading failed
  const [ribReaded, setRibReaded] = useState(0);
  const [ibanModify, setIbanModify] = useState(false);

  const initialValues = {
    IBAN: profile.iban ? profile.iban : '',
    BankIdentityDocument: null,
  };

  const validationSchema = Yup.object().shape({
    IBAN: Yup.string()
      .required('Ce champ est requis')
      .test('IBAN', 'Veuillez saisir un IBAN valide', (value) => {
        return value && validate.Iban(value);
      }),
    BankIdentityDocument: Yup.mixed()
      .test('fileRequired', 'Vous devez insérer un document', (value) => {
        return value !== null && value !== undefined;
      })
      .test(
        'fileType',
        "Ce format de fichier n'est pas autorisé (les formats autorisés sont jpg, jpeg, png, pdf)",
        (value) => {
          return value && fileType.check(value.type);
        },
      )
      .test('fileSize', 'La taille du fichier ne doit pas excéder 14mo', (value) => {
        return value && value.size <= 14000000;
      }),
  });

  async function readRibFile(e) {
    if (!e) return '';
    if (e.type !== 'application/pdf') return '';
    else {
      var formData = new FormData();
      formData.append('rib', e, e.name);
      try {
        var response = await Lender.RibReader(formData);
        if (response.data !== '') setRibReaded(1);
        else setRibReaded(2);
        return response.data;
      } catch (err) {
        setRibReaded(2);
        return '';
      }
    }
  }

  const onSubmit = (values, { resetForm, setSubmitting }) => {
    setSubmitting(true);

    var formData = new FormData();
    if (values.IBAN != null) {
      formData.append('IBAN', values.IBAN);
    }
    formData.append(
      'BankIdentityDocument',
      values.BankIdentityDocument,
      values.BankIdentityDocument.name,
    );

    Lender.BankAccount(formData)
      .then((response) => {
        setUnderModification(false);
        setIbanModify(false);
        setCurrentProfile(response.data);
        Toast.success('Enregistrement effectué avec succés !');
      })
      .catch((data) => {
        switch (data.response.status) {
          case 429:
            break;
          case 400:
            Toast.error(
              "Une erreur est survenue lors de l'envoi du formulaire. Veuillez vérifier votre saisie.",
            );
            var apiErrors = {};
            Object.keys(data.response.data.errors).forEach(function (e) {
              e === 'RIB'
                ? setErrorBankIdentityDocument(data.response.data.errors[e])
                : (apiErrors[e] = data.response.data.errors[e]);
            });
            resetForm({ values, status: { apiErrors } });
            break;
          default:
            Toast.error(
              "Une erreur est survenue lors de l'envoi du formulaire. Veuillez réessayer ultérieurement.",
            );
            break;
        }
        setSubmitting(false);
      })
      .finally(() => {
        setSubmitting(false);
      });
    setRibReaded(0);
  };

  return (
    !profileLoading && (
      <>
        <Row className="text-uppercase green-text pb-2 pt-2">
          <Col md={12}>Mes informations bancaires</Col>
        </Row>
        <Formik
          initialValues={initialValues}
          initialStatus={{ apiErrors: {} }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(props) => {
            const { errors, touched, setFieldValue, isSubmitting, status, resetForm } = props;
            const ibanError = error.getError('IBAN', { touched, errors, status });
            const bankIdentityDocumentError = error.getError('BankIdentityDocument', {
              touched,
              errors,
              status,
            });
            return (
              <Form className="bloc-kiwai">
                {!underModification && (
                  <AlertBanner
                    variant={profile.iban ? 'success' : 'warning'}
                    icon={profile.iban ? <CheckCircleFill size={18} /> : null}
                  >
                    {profile.iban
                      ? 'Vos informations bancaires ont été enregistrées avec succès !'
                      : 'Informations manquantes'}
                  </AlertBanner>
                )}
                {(!profile.iban || underModification) && (
                  <>
                    <Row className="form-row align-items-end">
                      <Col xs={12} md={8}>
                        <Label htmlFor="BankIdentityDocument" text="RIB" required />
                        <File
                          type="file"
                          name="BankIdentityDocument"
                          id="BankIdentityDocument"
                          loading={isSubmitting}
                          onChange={async (e) => {
                            if (e != null && e !== undefined) {
                              setFieldValue('BankIdentityDocument', e);
                              readRibFile(e).then((iban) => {
                                setFieldValue('IBAN', iban);
                              });
                              setIbanModify(true);
                            } else {
                              setFieldValue('BankIdentityDocument', null);
                              resetForm();
                              setIbanModify(false);
                            }
                          }}
                        />
                        {bankIdentityDocumentError && (
                          <div className="error-message position-relative">
                            {bankIdentityDocumentError}
                          </div>
                        )}
                        {errorBankIdentityDocument && (
                          <div className="error-message position-relative">
                            {errorBankIdentityDocument}
                          </div>
                        )}
                      </Col>
                      <Col xs={12} md={4} className="text-right pt-3 pt-md-0">
                        {underModification && (
                          <Button
                            className="button button-red m-0 mr-md-2 w-100 w-md-auto"
                            type="button"
                            onClick={() => {
                              setUnderModification(false);
                              resetForm();
                              setFieldValue('IBAN', profile.iban);
                              setIbanModify(false);
                              setRibReaded(0);
                            }}
                          >
                            Annuler
                          </Button>
                        )}
                        <Button
                          className="button button-green m-0 mt-3 m-md-0 w-100 w-md-auto pt-3 pt-md-0 "
                          type="submit"
                          loading={isSubmitting}
                        >
                          Enregistrer
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
                <Row className="form-row">
                  <Col lg={underModification ? 12 : 10}>
                    <Label
                      htmlFor="IBAN"
                      text="IBAN"
                      required={!profile.iban || underModification}
                    />
                    <Field
                      type="text"
                      name="IBAN"
                      id="IBAN"
                      className={ibanError ? 'error' : ''}
                      disabled={!ibanModify}
                    />
                    {ibanError ? <div className="error-message">{ibanError}</div> : null}
                  </Col>
                  {!underModification && profile.iban && (
                    <Col className="pt-4 text-right">
                      <Button
                        className="button button-green-secondary w-100 w-md-auto mt-1 mr-0 ml-0"
                        type="button"
                        onClick={() => {
                          setUnderModification(true);
                        }}
                      >
                        Modifier
                      </Button>
                    </Col>
                  )}
                </Row>
                {ribReaded === 2 && (
                  <div className="error-message position-relative">
                    "La lecture de votre document n’a pas permis la complétion automatique de vos
                    informations IBAN. Merci de renseigner ces champs avant d’enregistrer vos
                    coordonnées bancaires. "
                  </div>
                )}
                {ribReaded === 1 && (
                  <div className="error-message position-relative">
                    "Merci de vérifier les informations ci-dessous avant d’enregistrer vos
                    coordonnées bancaires."
                  </div>
                )}
              </Form>
            );
          }}
        </Formik>
      </>
    )
  );
};

export default BankDetails;
