import axios from 'axios';
import { Auth } from 'services/api';
import env from 'config';
import { Toast } from 'services/utils';

export function init() {
  const API_URL = env.apiBaseUrl;

  axios.defaults.baseURL = API_URL;
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

  axios.interceptors.request.use((request) => {
    const authToken = Auth.GetAuthToken();
    if (authToken) {
      request.headers['Authorization'] = 'Bearer ' + authToken;
    }

    request.withCredentials = true;

    return request;
  });

  axios.interceptors.response.use(null, async (error) => {
    if (error.response) {
      const originalRequest = error.config;

      if (
        (error.response.status === 400 || error.response.status === 401) &&
        originalRequest.url === API_URL + '/FrontAuth/refreshToken'
      ) {
        await Auth.Logout();
      }
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const refreshTokenResponse = await Auth.RefreshToken();

        if (refreshTokenResponse.status === 200) {
          originalRequest.headers.Authorization = 'Bearer ' + refreshTokenResponse.data.token;

          return axios(originalRequest);
        }
      }
      var attributes = error.response.data.attributes ? error.response.data.attributes[0] : '';
      if (error.response.status === 400 && attributes === '429') {
        // MangoPay Rate limiting messsge
        Toast.success(
          'Votre demande a bien été prise en compte, elle sera traitée dans les plus brefs délais.',
          { timeout: 4000 },
        );
        return Promise.reject({ response: { status: 429 } });
      }
      return Promise.reject(error);
    } else if (error.request) {
      console.error('API call failed for request', error.request);
    } else {
      console.error('An error occurred while setting up an API request');
    }
    console.error(error.config);

    return Promise.reject(error);
  });
}
