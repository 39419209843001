import React from 'react';
import moment from 'moment';

import { ProjectStateEnum } from 'services/utils';

const TimeRemaining = (props) => {
  var timeRemaining = props.campaignEndDate
    ? moment(props.campaignEndDate.substring(0, 10)).diff(moment(), 'hours')
    : 0;

  return (
    <div className={`project-card${props.isSummary ? '-summary' : ''}-days`}>
      {timeRemaining === 0 || props.projectStateID !== ProjectStateEnum.PUBLISHED ? (
        <>Campagne de collecte terminée</>
      ) : (
        <>
          {timeRemaining > 24 ? (
            <>
              {Math.floor(timeRemaining / 24)}
              {Math.floor(timeRemaining / 24) > 0 ? ' jours restants' : ' jour restant'}{' '}
            </>
          ) : (
            <>
              {timeRemaining}
              {timeRemaining > 0 ? ' heures restantes' : ' heure restante'}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default TimeRemaining;
