const ADDRESS_API = process.env.REACT_APP_ADDRESS_API;
async function verifyAddress(value) {
  var xhttp = new XMLHttpRequest();
  const pr = new Promise((resolve, reject) => {
    xhttp.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        var data = JSON.parse(this.response);
        return resolve(data);
      }
    };
  });
  xhttp.open('GET', ADDRESS_API + '/search/?q=' + value + '&limit=5&type=housenumber', true);
  xhttp.send();
  return pr;
}

export { verifyAddress };
