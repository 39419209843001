import React from 'react';

import { useConstributionsHistory } from 'contexts/ContributionsHistoryContext';

import CustomTable from 'components/Misc/CustomTable';

const ContributionsHistoryTable = (props) => {
  const { numberContributionsHistory, contributionsHistoryLoading, contributionsHistory } =
    useConstributionsHistory();

  const rows = [
    { label: 'Prêteur', name: 'contributorIdentity' },
    { label: 'Campagne', name: 'projectName' },
    { label: 'Date', name: 'contributionCreateDate', type: 'date' },
    { label: 'Montant', name: 'contributionAmount', type: 'amount' },
  ];

  return (
    <>
      <CustomTable
        page={props.page}
        pageNumber={Math.ceil(numberContributionsHistory / 10)}
        onClickPager={props.onClickPager}
        rows={rows}
        data={contributionsHistory}
        numberItem={numberContributionsHistory}
        loading={contributionsHistoryLoading}
        className="history"
      />
    </>
  );
};

export default ContributionsHistoryTable;
