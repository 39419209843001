import React, { useEffect, useState, useMemo, createContext, useContext } from 'react';

import { Lender } from 'services/api';

import Loading from 'components/Misc/Loading';

const OptInContext = createContext();

const OptInProvider = ({ children }) => {
  const [optIn, setoptIn] = useState({ optInNewsletter: false, optInProject: false });
  const [optInLoading, setOptInLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;
    setOptInLoading(true);

    Lender.GetOptIn()
      .then((response) => {
        if (isMounted) setoptIn(response);
        setOptInLoading(false);
      })
      .catch(() => {
        if (isMounted) setoptIn({ optInNewsletter: false, optInProject: false });
        setOptInLoading(false);
      });

    return () => (isMounted = false);
  }, []);

  const data = useMemo(
    () => ({
      optIn,
      optInLoading,
    }),
    [optIn, optInLoading],
  );

  return optInLoading ? (
    <Loading />
  ) : (
    <OptInContext.Provider value={data}>{children}</OptInContext.Provider>
  );
};

const useOptIn = () => {
  const context = useContext(OptInContext);
  if (context === undefined) {
    throw new Error('useSettings can only be used inside OptInProvider');
  }
  return context;
};

export { OptInProvider, useOptIn };
