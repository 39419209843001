import React from 'react';
import { InfoCircle } from 'react-bootstrap-icons';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useMediaQuery } from 'react-responsive';

const AlertBanner = (props) => {
  const isMobile = useMediaQuery({ query: `(max-width: 560px)` });

  return (
    <Alert variant={props.variant} className={`form-alert-profile ${props.className ?? ''}`}>
      <Row>
        <Col xs={12} className="d-flex align-items-center">
          {!isMobile && (
            <>
              {props.icon === undefined || props.icon === null || props.icon === '' ? (
                <>
                  <InfoCircle size={18} />
                  &nbsp;&nbsp;
                </>
              ) : (
                <>{props.icon}&nbsp;&nbsp;</>
              )}
            </>
          )}
          {props.children}
        </Col>
      </Row>
    </Alert>
  );
};

export default AlertBanner;
