import Noty from 'noty';

export function setup() {
  Noty.overrideDefaults({
    layout: 'topRight',
    timeout: 5000,
    closeWith: ['click', 'button'],
    theme: 'kiwai',
  });
}
