import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import ContributionsHistoryTable from './ContributionsHistoryTable';

import { ContributionsHistoryProvider } from 'contexts/ContributionsHistoryContext';

const ContributionsHistory = () => {
  const [page, setPage] = useState(1);

  const onPaging = (page) => {
    setPage(page);
  };

  useEffect(() => {}, [page]);

  return (
    <>
      <Row className="text-uppercase green-text pb-2 pt-2">
        <Col md={12}>Historique des contributions</Col>
      </Row>
      <Row className="form-row">
        <Col>
          <ContributionsHistoryProvider page={page}>
            <ContributionsHistoryTable page={page} onClickPager={onPaging} />
          </ContributionsHistoryProvider>
        </Col>
      </Row>
    </>
  );
};

export default ContributionsHistory;
