import React from 'react';
import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';

const HeaderLeft = () => {
  return (
    <Nav className="mr-auto d-none d-lg-flex">
      <Nav.Link as={Link} to="/projects" className="header-left-link">
        Prêter
      </Nav.Link>
      <Nav.Link as={Link} to="/pre_project" className="header-left-link">
        Emprunter
      </Nav.Link>
    </Nav>
  );
};

export default HeaderLeft;
