import React, { useEffect, useState, useMemo, createContext } from 'react';
import useGenericContext from './useGenericContext';
import { Lender, ProjectOwner } from 'services/api';
import Loading from 'components/Misc/Loading';
import { RoleEnum } from 'services/utils';

const DashboardContext = createContext();

function DashboardProvider({ children, type }) {
  const [dashboard, setDashboard] = useState(null);
  const [dashboardLoading, setDashboardLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;
    setDashboardLoading(true);
    if (type === RoleEnum.PROJECTOWNER) {
      ProjectOwner.Dashboard().then((response) => {
        if (isMounted) setDashboard(response);
        setDashboardLoading(false);
      });
    }

    if (type === RoleEnum.LENDER) {
      Lender.Dashboard().then((response) => {
        if (isMounted) setDashboard(response);
        setDashboardLoading(false);
      });
    }

    return () => (isMounted = false);
  }, [type]);

  const values = useMemo(
    () => ({
      dashboard,
    }),
    [dashboard],
  );

  return dashboardLoading ? (
    <Loading />
  ) : (
    <DashboardContext.Provider value={values}>{children}</DashboardContext.Provider>
  );
}

function useDashboard() {
  return useGenericContext(
    DashboardContext,
    'useDashboard cannot be used outside of a DashboardProvider component.',
  );
}

export { DashboardProvider, useDashboard };
