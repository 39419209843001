import React, { useEffect, useState, useMemo, createContext, useContext, useCallback } from 'react';

import { Lender } from 'services/api';
import { RoleEnum } from 'services/utils';

const WalletContext = createContext();

const WalletProvider = ({ children, user }) => {
  const [wallet, setWallet] = useState(0);
  const [walletLoading, setWalletLoading] = useState(true);

  const refreshWallet = useCallback((currentBalance) => {
    setWalletLoading(true);
    var timeOutPromise = new Promise(function (resolve, reject) {
      // 2 Second delay
      setTimeout(resolve, 2000, 'Timeout Done');
    });

    Promise.all([Lender.getWalletBalance(), timeOutPromise]).then(function (values) {
      //Repeat
      if (currentBalance === values[0]) {
        refreshWallet();
      } else {
        setWallet(values[0]);
        setWalletLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    let isMounted = true;
    setWalletLoading(true);
    if (user != null && user.role === RoleEnum.LENDER) {
      Lender.getWalletBalance()
        .then((balance) => {
          setWallet(balance);
          setWalletLoading(false);
        })
        .catch(() => {
          if (isMounted) setWallet(0);
          setWalletLoading(false);
        });
    } else {
      if (isMounted) setWallet(0);
      setWalletLoading(false);
    }
    return () => (isMounted = false);
  }, [user]);

  const data = useMemo(
    () => ({
      wallet,
      walletLoading,
      refreshWallet,
    }),
    [wallet, walletLoading, refreshWallet],
  );

  return <WalletContext.Provider value={data}>{children}</WalletContext.Provider>;
};

const useWallet = () => {
  const context = useContext(WalletContext);
  if (context === undefined) {
    throw new Error('useWallet can only be used inside WalletProvider');
  }
  return context;
};

export { WalletProvider, useWallet };
