import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Partner } from 'services/api';

const FloatingFooter = () => {
  const [globalsPartners, setGlobalsPartners] = useState([]);
  const [colWidth, setColWidth] = useState(1);

  useEffect(() => {
    Partner.GetGlobalsPartners().then((partners) => {
      setGlobalsPartners(partners);
      setColWidth(partners.length > 12 ? 1 : parseInt(12 / partners.length));
    });
  }, []);

  return (
    globalsPartners.length > 0 && (
      <div className="floatingFooter">
        <Container>
          <Row className="justify-content-md p-0 m-0">
            {globalsPartners.map((partner, index) => {
              return (
                <Col
                  key={`partner_${index}`}
                  md={colWidth}
                  xs={colWidth}
                  className="p-0 m-0 text-center"
                >
                  <a target="_blank" rel="noopener noreferrer" href={partner.webSiteURL}>
                    <img
                      src={`data:${partner.logo.documentType};base64,${partner.logo.documentData}`}
                      className="logoFooter"
                      alt={partner.logo.documentURL}
                    />
                  </a>
                </Col>
              );
            })}
          </Row>
        </Container>
      </div>
    )
  );
};

export default React.memo(FloatingFooter);
