import React from 'react';
import Switch from 'react-switch';
import * as Constant from 'config/constants';

const CustomSwitch = (props) => {
  return (
    <label className={props.className}>
      <Switch
        onChange={props.onChange}
        checked={props.value}
        offColor="#ec1f24"
        onColor={Constant.PrimaryColor}
      />
      <span>{props.label}</span>
    </label>
  );
};

export default CustomSwitch;
