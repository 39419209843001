import axios from 'axios';
import * as Constants from 'config/constants';

export async function ValidateAccount(data) {
  return await axios.post(`ProjectOwner/ValidateAccount`, data).then((response) => {
    return response;
  });
}

export async function KYC(values) {
  const response = await axios.put('ProjectOwner/KYC', values);
  return response;
}

export async function Identity(values) {
  const response = await axios.put('ProjectOwner/Identity', values);
  return response;
}

export async function Address(values) {
  const response = await axios.put('ProjectOwner/Address', values);
  return response;
}

export async function Profile() {
  return axios.get('ProjectOwner/ProjectOwnerProfile').then((response) => {
    return response.data;
  });
}

export async function ContributionsHistory(filters) {
  return axios.get(`ProjectOwner/Contributions${filters}`).then((response) => {
    return response;
  });
}

export async function Companies(filters) {
  return axios.get(`ProjectOwner/Companies${filters}`).then((response) => {
    return response;
  });
}

export async function Projects(filters) {
  return axios.get(`ProjectOwner/Projects${filters}`).then((response) => {
    return response;
  });
}

export async function PublishedProjects(filters) {
  return axios.get(`ProjectOwner/Projects/Published${filters}`).then((response) => {
    return response;
  });
}

export async function GetMandate(id) {
  var isEnr = Constants.isENR();
  return axios.get(`ProjectOwner/Projects/${id}/mandate/${isEnr}`).then((response) => {
    return response;
  });
}

export async function GetContract(id) {
  return axios.get(`ProjectOwner/Projects/${id}/GetLendersContractsArchive`).then((response) => {
    return response;
  });
}

export async function GetLoanTimeTable(id, filters) {
  return axios.get(`ProjectOwner/Projects/${id}/loanTimeTable${filters}`).then((response) => {
    return response;
  });
}

export async function Dashboard() {
  return await axios.get('ProjectOwner/Dashboard').then((response) => {
    return response.data;
  });
}

export async function getProfileIsValid() {
  return await axios.get('ProjectOwner/IsProfileValid').then((response) => {
    return response.data;
  });
}

export async function getCompanyProfileIsComplete() {
  return await axios.get('ProjectOwner/IsCompanyProfileComplete').then((response) => {
    return response.data;
  });
}
export async function ResetEmail(values) {
  return axios.put('ProjectOwner/SendModificationMail', values).then((response) => {
    return response;
  });
}
export async function verifyReset(value) {
  return await axios.put('ProjectOwner/Email', value).then((response) => {
    return response.data;
  });
}
