import React from 'react';
import { Flower3 } from 'react-bootstrap-icons';

const button = (props) => {
  return (
    <button
      className={props.className}
      onClick={props.loading ? null : props.onClick}
      style={props.style}
      type={props.type}
      disabled={props.disabled}
    >
      {props.loading ? <Flower3 size={24} className={props.loading && ' spin'} /> : props.children}
    </button>
  );
};

export default button;
