import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { TransactionsProvider } from 'contexts/TransactionsContext';

import Select from 'components/Input/Select';
import TransactionsTable from './TransactionsTable';
import Button from 'components/Button/Button';
import Label from 'components/Misc/Label';

const rows = [
  { label: 'Exécuteur', name: 'executantIdentity' },
  { label: 'Nature de la transaction', name: 'transactionType' },
  { label: 'Moyen de paiement', name: 'meanOfPayment' },
  { label: 'Montant', name: 'amount', type: 'amount' },
  { label: 'Etat', name: 'transactionState' },
  { label: "Date d'exécution", name: 'createDate', type: 'date' },
];

const transactionTypeList = [
  { name: 'Crédit du compte' },
  { name: 'Retrait de l’épargne' },
  { name: 'Contribution' },
  { name: 'Remboursement d’une contribution' },
  { name: 'Transfert d’une contribution' },
  { name: 'Déblocage des fonds' },
  { name: 'Prélèvement des frais de collecte' },
  { name: 'Prélèvement d’échéance' },
  { name: 'Remboursement d’échéance' },
  { name: 'Prélèvements sociaux' },
  { name: 'Prélèvement IR' },
  { name: 'Prélèvement des frais de gestion' },
  { name: 'Prélèvement des frais de garantie' },
  { name: 'Garantie Bancaire' },
];
const transactionStateList = [
  { name: 'En attente' },
  { name: 'Réussi' },
  { name: 'Echec' },
  { name: 'Remboursée' },
];

const TransactionsItem = () => {
  const [transactionType, setTransactionType] = useState('');
  const [transactionState, setTransactionState] = useState('');
  const [page, setPage] = useState(1);

  useEffect(() => {}, [page]);

  const onPaging = (page) => {
    setPage(page);
  };

  return (
    <>
      <div className="bloc-kiwai">
        <Row className="form-row">
          <Col md={6}>
            <Label htmlFor="transactionType" text="Type" />
            <Select
              name="transactionType"
              onChange={(e) => {
                setTransactionType(e.target.value);
              }}
              values={transactionTypeList}
              firstOption="Tous"
              value={transactionType}
            />
          </Col>
          <Col md={6} className="pt-4 pt-md-0">
            <Label htmlFor="transactionState" text="Statut" />
            <Select
              name="transactionState"
              onChange={(e) => {
                setTransactionState(e.target.value);
              }}
              values={transactionStateList}
              firstOption="Tous"
              value={transactionState}
            />
          </Col>
        </Row>
        <Row className="form-row form-footer">
          <Col className="text-right">
            <Button
              className="button button-red mr-0"
              onClick={() => {
                setTransactionState('');
                setTransactionType('');
              }}
            >
              Réinitialiser
            </Button>
          </Col>
        </Row>
      </div>
      <Row className="form-row">
        <Col>
          <TransactionsProvider
            transactionState={transactionState}
            transactionType={transactionType}
            page={page}
          >
            <TransactionsTable rows={rows} page={page} onClickPager={onPaging} />
          </TransactionsProvider>
        </Col>
      </Row>
    </>
  );
};

export default TransactionsItem;
